import styled from 'styled-components';
import Title from 'components/old/Title';
import media from 'styles/styled/media';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  padding: ${({ padding }) => padding};
  ${media.tablet`
    flex-direction: column
    align-items: flex-start;
    >* {
      margin-bottom: 5px;
    }
    >input,
    >button {
      width: 100%;
    }
    >${Title} {
      order: -1;
    }
  `}
`;

Header.defaultProps = {
  padding: '0px',
};

export default Header;
