import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

type ActionsProps = {
  onEdit?: () => void;
  onDelete?: () => void;
};

const Style = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Actions = (props: ActionsProps) => {
  return (
    <Style>
      {props.onEdit && (
        <IconButton
          size="small"
          onClick={props.onEdit}
          color="primary"
          aria-label="edit"
        >
          <EditIcon fontSize="small" />
        </IconButton>
      )}
      <IconButton
        disabled={!props.onDelete}
        size="small"
        onClick={props.onDelete}
        color="secondary"
        aria-label="delete"
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Style>
  );
};

export default Actions;
