import React, { useState, useEffect } from 'react';
import useWords from 'hooks/words';
import { useStore } from 'store/store';
import { SET_RESULT, SET_SENTENCE } from 'store/testpanel';
import Typo from 'components/old/Typo';
import Input from 'components/old/Input';
import Grid from '@material-ui/core/Grid';

import ErrorModal from './utils/ErrorModal';
import MonolithInfoCard from './utils/MonolithInfoCard';
import ResultCard from './utils/ResultCard';
import ComingSoonCard from './utils/ComingSoonCard';

import {
  Body,
  Result,
  Card,
  CardTitle,
  Data,
  StyledButton,
  ChartContainer,
  Icon,
} from './layout/Layout';

import Loading from 'components/old/Loading';
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';

import ErrorIcon from 'icons/ErrorIcon';
import SuccessIcon from 'icons/SuccessIcon';

import useReadingTest from 'api/rest/hooks/useReadingTest';

const Content = (props) => {
  const { data } = useReadingTest();
  const [tr] = useWords();
  const [
    {
      testPanel: { result, sentence },
    },
    dispatch,
  ] = useStore();
  const [error, setError] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [charData, setCharData] = useState([]);

  const {
    g: { w, api },
    model,
  } = props;

  const openModal = async (error) => {
    await setError(error);
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const getTestResult = async () => {
    setLoading(true);
    const res = await data.readingTest_api.getReadingTest(
      model.id,
      sentence,
      model.language.code
    );
    if (Object.keys(res.readingTest).length > 0) {
      const result = {...res.readingTest};
      if(result["leo-rasa"]?.content?.data?.entities) {
        const topics = await Promise.all((result["leo-rasa"]?.content?.data?.entities || []).map(async (entity) => (
            await api.Topics._getById(entity.id)
        )));
        result["leo-rasa"].content.data.entities = result["leo-rasa"].content.data.entities?.map((entity, index)=> ({
          ...entity,
          title: topics[index].title
        }))
      }
      dispatch({ type: SET_RESULT, payload: result });
      if (fetchError) setFetchError(null);
    }
    if (res.error) setFetchError(res.error);
  };

  useEffect(() => {
    const setData = async () => {
      setCharData([]);
      if (result['leo-feel'] && result['leo-feel']['content']) {
        for (let [key, value] of Object.entries(
          result['leo-feel']['content']['data']
        )) {
          await setCharData((prevState) => [
            ...prevState,
            {
              type: key.charAt(0).toUpperCase() + key.slice(1),
              value: value,
            },
          ]);
        }
      }
    };
    setData();
  }, [result]);

  useEffect(() => {
    if (charData.length > 0 || Object.keys(result).length > 0 || fetchError)
      setLoading(false);
  }, [result, charData, fetchError]);

  return (
    <Body>
      {!model.referenceBotId ? (
        <MonolithInfoCard tr={tr} />
      ) : loading ? (
        <Loading />
      ) : (
        <>
          <ErrorModal
            error={error}
            tr={tr}
            open={open}
            close={close}
            {...props}
          />
          <Typo margin="5px 0px">{w.testSentence}</Typo>
          <Input
            value={sentence}
            onChange={(e) => {
              e.preventDefault();
              dispatch({ type: SET_SENTENCE, payload: e.target.value });
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') getTestResult();
            }}
          />
          <Typo margin="5px 0px">{w.testResult}</Typo>
          <Result>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <ResultCard
                  tr={tr}
                  color="#ff5757"
                  title={tr.testRasa}
                  type="rasa"
                  result={result}
                  openModal={openModal}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ResultCard
                  tr={tr}
                  color="#5271ff"
                  title={tr.testMar}
                  type="mar"
                  result={result}
                  openModal={openModal}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ResultCard
                  tr={tr}
                  color="#3f7927"
                  title={tr.testLieux}
                  type="lieux"
                  result={result}
                  openModal={openModal}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ResultCard
                  tr={tr}
                  color="#f58220"
                  title={tr.testDate}
                  type="date"
                  result={result}
                  openModal={openModal}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Card color="#ff66c4">
                  <CardTitle>{tr.testFeel}</CardTitle>
                  {charData.length > 0 && (
                    <>
                      <Data>
                        <span>
                          <Icon>
                            <SuccessIcon width="17" />
                          </Icon>
                          {tr.testResult}
                        </span>
                      </Data>

                      <ChartContainer>
                        <Chart data={charData}>
                          <ArgumentAxis />
                          <ValueAxis max={4} />

                          <BarSeries
                            color="#c92e93"
                            valueField="value"
                            argumentField="type"
                          />
                        </Chart>
                      </ChartContainer>
                    </>
                  )}
                  {result['leo-feel'] && result['leo-feel']['error'] && (
                    <Data>
                      <span>
                        <Icon>
                          <ErrorIcon width="17" />
                        </Icon>
                        {tr.errorInResult}
                        <StyledButton
                          onClick={() =>
                            openModal(result['leo-feel']['error']).summary
                          }
                        >
                          {tr.details}
                        </StyledButton>
                      </span>
                    </Data>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ComingSoonCard
                  tr={tr}
                  color="#bd9c17"
                  title={tr.testOrthographe}
                  result={result}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ResultCard
                  tr={tr}
                  color="#0ea5ac"
                  title={tr.testBabel}
                  type="babel"
                  result={result}
                  openModal={openModal}
                />
              </Grid>
            </Grid>
          </Result>
        </>
      )}
    </Body>
  );
};

export default Content;
