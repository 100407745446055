import React from 'react';
import styled from 'styled-components';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import useWords from 'hooks/words';
import Header from 'components/old/Header';
import Title from 'components/old/Title';
import { ClickIcon } from 'components/old/Icon';
import Search from '@material-ui/icons/Search';
import UnarchiveRoundedIcon from '@material-ui/icons/UnarchiveRounded';
import Modal from 'components/old/Modal';
import Table, {
  TWrap,
  TBody,
  TRow,
  TCell,
  Board,
  THEAD,
  THead,
} from 'components/old/Table';

const ArchiveModal = (props) => {
  const {
    g: { w },
    isOpen,
    close,
    appellations,
    setSearchValue,
    searchValue,
    handlePaginate,
    paginate,
  } = props;

  const [tr] = useWords();

  return (
    <Modal isOpen={isOpen} close={close} minHeight={'650px'} selector="#root">
      <Header>
        <Title underlined>{tr.archivedAppellations}</Title>
        <WrapperInput>
          <input
            type="text"
            value={searchValue}
            placeholder="Chercher par nom"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Search size={15} color={'primary'} />
        </WrapperInput>
      </Header>
      <Body>
        <TWrap fullHeight>
          <Table>
            <THEAD>
              <TRow>
                <THead>{w.name}</THead>
                <THead>{w.nickname}</THead>
                <THead align="center">{w.category}</THead>
                <THead>{w.synonyms}</THead>
                <THead align="end"> {w.options} </THead>
              </TRow>
            </THEAD>

            <TBody scrollable>
              {appellations.length > 0 &&
                (appellations[paginate] || appellations[0]).map((item) => {
                  return (
                    <Appellation {...props} key={item.id} item={item} tr={tr} />
                  );
                })}
            </TBody>
          </Table>
        </TWrap>
        {appellations.length === 0 && (
          <NoArchivedAppelations>
            {tr.noArchivedAppellations}
          </NoArchivedAppelations>
        )}
      </Body>
      <StyledPagination>
        {paginate !== 0 && (
          <div onClick={() => handlePaginate('decrease')}>&#60;</div>
        )}
        <div>{paginate + 1}</div>
        {paginate !== appellations.length - 1 && (
          <div onClick={() => handlePaginate('increase')}>&#62;</div>
        )}
      </StyledPagination>
    </Modal>
  );
};

const Appellation = (props) => {
  const { g, reload, setReload, tr } = props;
  const {
    api: { Appellations },
  } = g;
  const { name, nickname, category, id, variations } = props.item;
  const notification = useGlobalMessage();

  const UpdateIsArchived = async (id, bool) => {
    await Appellations._put(id, { isArchived: bool });
    notification.success(tr.saved);
    setReload(!reload);
  };

  return (
    <TRow>
      <TCell>{name}</TCell>
      <TCell>{nickname}</TCell>
      <TCell align="center">{category}</TCell>
      <TCell>{variations.map((v) => v.name).join(', ')}</TCell>
      <TCell>
        <Board>
          <ClickIcon material={true}>
            <UnarchiveRoundedIcon
              fontSize="small"
              label="edit"
              onClick={() => UpdateIsArchived(id, false)}
            />
          </ClickIcon>
        </Board>
      </TCell>
    </TRow>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  > input {
    width: 100%;
  }
`;

const NoArchivedAppelations = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1rem;
`;

const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.4rem;
  outline: none;
  border: 1px solid #eee;
  color: #fff;
  border-radius: 5px;
  padding: 0.6rem 1rem;
  > input {
    background-color: #fff;
    color: #2d2f7b;
    border: none;

    ::placeholder {
      color: #2d2f7b;
    }
    :focus {
      outline: none;
    }
  }
`;

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;

  > div {
    cursor: pointer;
    background: #2d2f7b;
    color: #fff;
    border-radius: 4px;
    margin: 0 0.4rem;
    padding: 0 0.4rem;
  }
`;

export default ArchiveModal;
