import gql from 'graphql-tag';
import { _query } from 'api/graphql/old/client';

class Tag {
  getPlaces = async (args) => {
    return await _query(GET_PLACES, args);
  };
}

export default Tag;

const GET_PLACES = gql`
  query getPlaces($bot_id: Int) {
    getPlaces(bot_id: $bot_id) {
      places {
        id
        name
      }
    }
  }
`;
