import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import { Route, Switch } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import OfficeBar from 'app/office/OfficeBar';
import styled from 'styled-components';
import Home from 'app/office/Home';
import Models from 'app/office/Models';
import Model from 'app/office/Model';
import Training from 'app/office/Training';
import { GlobalsHOC } from 'context/';

type StyleType = {
  height?: any;
};

const Style = styled.div<StyleType>`
  height: 100%;
  > .MuiContainer-root {
    height: inherit;
    > .MuiGrid-container {
      background: white;
      height: ${(p) => (p.height ? `${p.height - 100}px` : '80%')};
      padding: ${({ theme }) => theme.spacing}px;
      border-radius: ${({ theme }) => theme.radius}px;
    }
  }
  .MuiTextField-root {
    margin: 0px;
  }
  .MuiOutlinedInput-input {
    padding: ${({ theme }) => theme.spacing}px;
  }
`;

const Office = () => {
  const { height } = useWindowSize();

  return (
    <Style height={height}>
      <Container>
        <OfficeBar />
        <Switch>
          <Route exact path="/office" component={Home} />
          <Route exact path="/office/models" component={Models} />
          <Route
            path="/office/training"
            render={() => {
              return (
                <GlobalsHOC>
                  <Training />
                </GlobalsHOC>
              );
            }}
          />
          <Route
            path="/office/model/:id"
            render={() => {
              return (
                <GlobalsHOC>
                  <Model height={height} />
                </GlobalsHOC>
              );
            }}
          />
        </Switch>
      </Container>
    </Style>
  );
};

export default Office;
