const actions = {
  fr_FR: {
    create: 'Créer',
    save: 'Sauvegarder',
    cancel: 'Annuler',
    submit: 'Valider',
    add: 'Ajouter',
    delete: 'Supprimer',
    edit: 'Éditer',
    modify: 'Modifier',
    close: 'Fermer',
    details: 'Détails',
    proceed: 'Poursuivre',
    yes: 'Oui',
    no: 'Non',
  },
  en_EN: {
    create: 'Create',
    save: 'Save',
    cancel: 'Annuler',
    submit: 'Submit',
    add: 'Add',
    delete: 'Delete',
    edit: 'Edit',
    modify: 'Modify',
    close: 'Close',
    details: 'Details',
    proceed: 'Proceed',
    yes: 'Yes',
    no: 'No',
  },
};

export default actions;
