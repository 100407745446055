import React from 'react';
import styled, { css } from 'styled-components';
import Pagination from 'react-js-pagination';

const StylePagination = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 0px 10px;
  margin: auto;
  > .pagination {
    margin: 10px 0px;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    align-items: center;

    ${({ theme }) => css`
      > li .link {
        font-weight: 600;
        color: ${theme['colors']['primary']};
        text-decoration: none;
        &.active {
          font-size: 1.2rem;
          color: ${theme['colors']['secondary']};
        }
      }
    `}
  }
`;

type PaginationProps = {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  pageRangeDisplayed: number;
  onChange: (page_number: number) => void;
};

export default (props: PaginationProps) => {
  if (props.totalItemsCount <= props.itemsCountPerPage) return null;
  return (
    <StylePagination>
      <Pagination linkClass="link" activeLinkClass="active" {...props} />
    </StylePagination>
  );
};
