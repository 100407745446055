import { useState, useEffect } from 'react';

function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

const useTextHighlight = (ref) => {
  const [text, setTxt] = useState('');
  const [pos, setPos] = useState([]);
  const [inside, setInside] = useState(false);

  useEffect(() => {
    const onMouseDown = (e) => {
      if (ref.current && ref.current.contains(e.target)) setInside(true);
      else setInside(false);
    };
    document.addEventListener('mousedown', onMouseDown);
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
    };
  });

  useEffect(() => {
    const onMouseUp = (e) => {
      if (ref.current && ref.current.contains(e.target) && inside) {
        let select = document.getSelection();
        let range = select.getRangeAt(0);
        let inRange = range.startContainer === range.endContainer;
        let highlight = range.toString();
        if (highlight && !isBlank(highlight) && inRange) {
          setTxt(highlight);
          if (!text) setPos([e.pageX, e.pageY]);
        }
      }
    };
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  });

  return [text, pos, setTxt, setPos];
};

export { useTextHighlight };
