import React, { useState, useEffect } from 'react';
import useWords from 'hooks/words';
import styled from 'styled-components';
import Table, {
  TWrap,
  THEAD,
  TBody,
  TRow,
  THead,
  TCell,
} from 'components/old/Table';
import Checkbox from 'components/old/Checkbox';
import Icon, { ClickIcon } from 'components/old/Icon';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import Flex from 'components/old/Flex';
import Pagination from 'components/old/Pagination';

const NoResults = styled.p`
  padding-top: 0.7rem;
`;

export default (props) => {
  const {
    g: {
      w,
      api: { Intents },
    },
    items,
    search,
    setSearch,
    intents: { total, skip, take, selected, filteredArray },
    dispatch,
    selectedmodels,
    models,
  } = props;

  const [tr] = useWords();

  const [filteredIntents, setFilteredIntents] = useState(items);
  const [localTotal, setLocalTotal] = useState(total);
  const [toggleCheckbox, setToggleCheckbox] = useState(false);

  const getModelIntents = async (input, takeinput = take) => {
    return Intents._get(null, {
      title: input,
      models:
        selectedmodels.length > 0
          ? selectedmodels.map((el) => el.id)
          : models.array.map((el) => el.id),
      take: takeinput,
      skip: skip,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'setIntents',
      payload: { skip: 0 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedmodels, toggleCheckbox]);

  useEffect(() => {
    const filterFunction = async () => {
      if (search.length === 0 && selectedmodels.length === 0) {
        setLocalTotal(total);
        setFilteredIntents([]);
      }
      if (selectedmodels.length > 0 || search.length > 0) {
        const res = await getModelIntents(search);
        setLocalTotal(res.total);
        await setFilteredIntents(res.data);
      }
    };
    filterFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedmodels, skip, search]);

  useEffect(() => {
    const _getIntents = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await Intents._get(null, {
        skip: skip,
        take: total,
      });
      if (res) {
        dispatch({
          type: 'setIntents',
          payload: {
            totalArray: res.data || [],
            total: res.total,
            message: res.message,
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    _getIntents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected && selected.length > 0) {
      dispatch({
        type: 'setIntents',
        payload: {
          selected: [],
        },
      });
    }
    const _getFilteredIntents = async () => {
      dispatch({ type: 'setLoading', payload: true });

      const res = await getModelIntents(search, '');
      if (res) {
        await dispatch({
          type: 'setIntents',
          payload: {
            filteredArray: res.data || [],
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    if (search.length > 0 || selectedmodels.length > 0) {
      _getFilteredIntents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedmodels]);

  useEffect(() => {
    return () => {
      setSearch('');
      dispatch({ type: 'onDeselectAllModels' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flex={7} flexDirection="column" alignItems="center">
      <TWrap>
        <Table layout="auto">
          <THEAD>
            <TRow>
              <THead>
                {((search.length === 0 &&
                  selectedmodels.length === 0 &&
                  items.length !== 0) ||
                  ((search.length > 0 || selectedmodels.length > 0) &&
                    filteredIntents.length !== 0)) && (
                  <Checkbox
                    outlined
                    checked={
                      search.length > 0 || selectedmodels.length > 0
                        ? selected && selected.length === localTotal
                        : selected && selected.length === total
                    }
                    onChange={() => {
                      setToggleCheckbox(!toggleCheckbox);
                      selected &&
                      (search.length > 0 || selectedmodels.length > 0)
                        ? selected && selected.length === localTotal
                          ? dispatch({ type: 'deselectAllIntents' })
                          : dispatch({
                              type: 'setIntents',
                              payload: { selected: filteredArray },
                            })
                        : selected && selected.length === total
                        ? dispatch({ type: 'deselectAllIntents' })
                        : dispatch({ type: 'selectAllIntents' });
                    }}
                  />
                )}
              </THead>
              <THead>{w.intentSelectSeveral}</THead>
              <THead align="-webkit-right">
                <Icon icon="fas fa-eye" />
              </THead>
            </TRow>
          </THEAD>
          <TBody>
            {filteredIntents.length === 0 &&
            search.length === 0 &&
            selectedmodels.length === 0
              ? items.map((i) => {
                  return <Intent key={i.id} {...props} intent={i} />;
                })
              : filteredIntents.sort().map((i) => {
                  return <Intent key={i.id} {...props} intent={i} />;
                })}
          </TBody>
        </Table>
      </TWrap>
      {filteredIntents.length === 0 &&
        (search.length > 0 || selectedmodels.length > 0) && (
          <NoResults>{tr.noIntentResult}</NoResults>
        )}

      <Pagination
        activePage={(skip + 10) / take}
        itemsCountPerPage={take}
        totalItemsCount={search.length > 0 ? localTotal : total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          dispatch({
            type: 'setIntents',
            payload: { skip: (page - 1) * take },
          });
        }}
      />
    </Flex>
  );
};

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const StarIcon = styled.span`
  margin-top: 0px;
  margin-left: 3px;
`;

const Intent = (props) => {
  const {
    intent,
    intents: { selected },
    setPreview,
    dispatch,
  } = props;

  const isIntentFromMonolith = intent.models.some(
    (model) => !model.referenceBotId || model.referenceBotId === '0'
  );

  return (
    <TRow>
      <TCell>
        <Checkbox
          checked={selected && selected.some((el) => el.id === intent.id)}
          onChange={() => {
            selected && !selected.some((el) => el.id === intent.id)
              ? dispatch({ type: 'onSelectIntents', payload: intent })
              : dispatch({ type: 'onDeselectIntents', payload: intent });
          }}
        />
      </TCell>
      <TCell>
        <Title>
          {intent.title}
          {isIntentFromMonolith && (
            <StarIcon>
              <GradeRoundedIcon color="secondary" style={{ fontSize: 14 }} />
            </StarIcon>
          )}
        </Title>
      </TCell>
      <TCell align="-webkit-right">
        <ClickIcon
          icon="fas fa-eye"
          onClick={async () => await setPreview(intent)}
        />
      </TCell>
    </TRow>
  );
};
