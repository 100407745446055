import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Source Sans pro',
      'Roboto',
      'BlinkMacSystemFont',
      'Arial',
      'sans-serif',
      '"Segoe UI"',
      '"Helvetica Neue"',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    text: {
      primary: '#2d2f7b',
      secondary: 'eb6666',
    },
    primary: {
      light: '#7a7ab8',
      main: '#2d2f7b',
    },
    secondary: {
      main: '#eb6666',
    },
    background: {
      default: '#2D2F7B',
    },
  },
  overrides: {
    MuiGrid: {
      root: {
        position: 'relative',
      },
    },
    MuiSwitch: {
      thumb: {
        color: 'white',
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
  },
});

export default theme;
