import React, { useEffect } from 'react';
import { useTTTPPE } from 'hooks/old/';
import Loading from 'components/old/Loading';
import styled from 'styled-components';
import NewAppellation from './NewAppellation';
import EditAppellation from './EditAppellation';
import Appellation from './Appellation';
import useWords from '../../../../hooks/words';
import Table, { TWrap, TBody, TRow, THEAD, THead } from 'components/old/Table';

const Content = (props) => {
  const {
    g,
    match: {
      params: { id },
    },
    search,
    reload,
    setReload,
    state,
    dispatch,
    onOpen,
    isOpen,
    close,
    appellations,
    updateIsArchived,
  } = props;
  const {
    api: { Models, Appellations },
    w,
  } = g;
  let referenceBotId = state.model ? state.model.referenceBotId : null;
  const [loading, cases] = useTTTPPE(referenceBotId);
  const [tr] = useWords();
  const onCreate = async (args) => {
    try {
      dispatch({ type: 'setLoading', payload: true });
      let variables = { ...args, model: Number(id) };
      const res = await Appellations._post(variables);
      if (res) {
        g.dispatch({
          type: 'setStatus',
          payload: {
            col: 'success',
            msg: w.creationSuccess,
          },
        });
        dispatch({
          type: 'setAppellations',
          payload: state.appellations.concat(res),
        });
        props.setModal(false);
      } else
        g.dispatch({
          type: 'setStatus',
          payload: {
            col: 'secondary',
            msg: w.error,
          },
        });
    } catch (err) {
    } finally {
      dispatch({ type: 'setLoading', payload: false });
    }
  };

  const onDelete = (id) => {
    dispatch({
      type: 'setAppellations',
      payload: state.appellations.filter((apl) => apl.id !== id),
    });
  };

  const onEdit = (item) => {
    dispatch({ type: 'setEdit', payload: item });
  };

  const onSave = async (args) => {
    try {
      dispatch({ type: 'setLoading', payload: true });
      const res = await Appellations._put(state.edit.id, args);
      if (res) {
        g.dispatch({
          type: 'setStatus',
          payload: {
            col: 'success',
            msg: w.saveSuccess,
          },
        });
        dispatch({
          type: 'setAppellations',
          payload: state.appellations.map((appellation) => {
            if (appellation.id === state.edit.id) return res;
            else return appellation;
          }),
        });
      } else
        g.dispatch({
          type: 'setStatus',
          payload: {
            col: 'secondary',
            msg: w.error,
          },
        });
    } catch (err) {
    } finally {
      dispatch({ type: 'setLoading', payload: false });
      dispatch({ type: 'setEdit', payload: null });
      props.setModal(false);
    }
  };

  useEffect(() => {
    const getModel = async () => {
      try {
        dispatch({ type: 'setLoading', payload: true });
        const res = await Models._getById(id);
        dispatch({ type: 'setModel', payload: res });
      } catch (err) {
        console.warn(err);
      } finally {
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    getModel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, Models]);

  if (state.loading) return <Loading />;
  return (
    <Body>
      <TWrap fullHeight>
        <Table>
          <THEAD>
            <TRow>
              <THead>{w.name}</THead>
              <THead>{w.nickname}</THead>
              <THead align="center">
                {w.category}
                <StyledSelect
                  value={state.category}
                  onChange={(e) => {
                    dispatch({ type: 'setCategory', payload: e.target.value });
                    dispatch({ type: 'setSearch', payload: '' });
                  }}
                >
                  <option value="">Toutes</option>
                  <option value="tag">tag</option>
                  <option value="theme">theme</option>
                  <option value="public">public</option>
                  <option value="place">place</option>
                  <option value="event">event</option>
                </StyledSelect>
              </THead>
              <THead>{w.synonyms}</THead>
              <THead align="end"> {w.options} </THead>
            </TRow>
          </THEAD>
          {state.appellations?.length !== 0 && (
            <TBody scrollable>
              {(appellations || []).map((item) => {
                return (
                  <Appellation
                    {...props}
                    key={item.id}
                    item={item}
                    cases={cases}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    reload={reload}
                    setReload={setReload}
                    onOpen={onOpen}
                    updateIsArchived={updateIsArchived}
                  />
                );
              })}
            </TBody>
          )}
        </Table>
      </TWrap>
      {state.appellations?.length === 0 && (
        <NoAppellations>{tr.noAppellations}</NoAppellations>
      )}
      {!search && appellations?.length !== 0 && (
        <StyledPagination>
          {state.skip !== 0 && (
            <div
              onClick={() =>
                dispatch({
                  type: 'setSkip',
                  payload: 0,
                })
              }
            >
              début
            </div>
          )}
          {state.skip !== 0 && (
            <div
              onClick={() =>
                dispatch({
                  type: 'setSkip',
                  payload: state.skip - state.take,
                })
              }
            >
              &#60;
            </div>
          )}
          <div>{Math.round(state.skip / state.take) + 1}</div>
          {state.skip < state.total && appellations?.length >= 25 && (
            <div
              onClick={() =>
                dispatch({
                  type: 'setSkip',
                  payload: Math.round(state.skip + state.take),
                })
              }
            >
              &#62;
            </div>
          )}
          {state.skip < state.total && appellations?.length >= 25 && (
            <div
              onClick={() =>
                dispatch({
                  type: 'setSkip',
                  payload:
                    Math.ceil(state.total / state.take) * state.take - 25,
                })
              }
            >
              fin
            </div>
          )}
        </StyledPagination>
      )}
      <EditAppellation
        {...props}
        edit={state.edit}
        botId={referenceBotId}
        isOpen={isOpen}
        close={close}
        loading={loading || state.loading}
        cases={cases}
        onSave={onSave}
      />
      <NewAppellation
        {...props}
        edit={state.edit}
        botId={referenceBotId}
        loading={loading || state.loading}
        cases={cases}
        onCreate={onCreate}
      />
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  position: relative;
  overflow: auto;
  > input {
    width: 100%;
  }
`;

const StyledSelect = styled.select`
  place-self: center;
  width: 35%;
  padding: 0.2rem 0.3rem;
  border: 1px solid #2d2f7b;
  border-radius: 5px;
  color: ${(props) => props.theme.colors.primary};
  margin-left: 0.5rem;
  &:focus {
    outline: none;
  }
`;

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  margin-top: 0.5rem;
  }

  > div {
    cursor: pointer;
    background: #2d2f7b;
    color: #fff;
    border-radius: 4px;
    margin: 0 0.4rem;
    padding: 0 0.4rem;
  }
`;

const NoAppellations = styled.div`
  margin-top: 1rem;
  font-size: 1em;
  text-align: center;
`;

export default Content;
