export default (state, action) => {
  switch (action.type) {
    case 'setModels':
      return { ...state, models: { ...state.models, ...action.payload } };
    case 'setIntents':
      return { ...state, intents: { ...state.intents, ...action.payload } };
    case 'setEntities':
      return { ...state, entities: { ...state.entities, ...action.payload } };
    case 'setState':
      return { ...state, ...action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setSearch':
      return { ...state, search: action.payload };
    case 'setAdd':
      return { ...state, add: action.payload };
    case 'selectAllIntents':
      return {
        ...state,
        intents: {
          ...state.intents,
          selected: state.intents.totalArray,
        },
      };
    case 'deselectAllIntents':
      return {
        ...state,
        intents: {
          ...state.intents,
          selected: [],
        },
      };
    case 'onSelectModels':
      return {
        ...state,
        models: {
          ...state.models,
          selected: state.models.selected.concat(action.payload),
        },
      };
    case 'onDeselectModels':
      return {
        ...state,
        models: {
          ...state.models,
          selected: state.models.selected.filter(
            (el) => el.id !== action.payload.id
          ),
        },
      };
    case 'onDeselectAllModels':
      return {
        ...state,
        models: {
          ...state.models,
          selected: [],
        },
      };
    case 'onSelectIntents':
      return {
        ...state,
        intents: {
          ...state.intents,
          selected: state.intents.selected.concat(action.payload),
        },
      };
    case 'onDeselectIntents':
      return {
        ...state,
        intents: {
          ...state.intents,
          selected: state.intents.selected.filter(
            (el) => el.id !== action.payload.id
          ),
        },
      };
    default:
      return state;
  }
};
