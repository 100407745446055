import React, { useState, useReducer } from 'react';
import { withGlobal } from 'context/';
import Modal from 'components/old/Modal';
import Title from 'components/old/Title';
import Subtitle from 'components/old/Subtitle';
import Header from 'components/old/Header';
import Loading from 'components/old/Loading';
import Input from 'components/old/Input';
import Select from 'components/old/Select';
import Switch from 'components/old/Switch';
import Flex from 'components/old/Flex';
import Button from 'components/old/Button';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setState':
      return { ...state, ...action.payload };
    case 'setValue':
      const { name, value } = action.payload;
      return { ...state, [name]: value };
    default:
      return state;
  }
};

const _buildInitialState = (elements) => {
  let state = {};
  elements.forEach((el) => {
    switch (el.type) {
      case 'array':
        state = { ...state, [el.name]: el.value || [] };
        break;
      case 'boolean':
        state = { ...state, [el.name]: el.value || false };
        break;
      case 'select':
        state = { ...state, [el.name]: el.value || '' };
        break;
      default:
        //input
        state = { ...state, [el.name]: el.value || '' };
        break;
    }
  });
  return state;
};

export default withGlobal((props) => {
  const {
    g: { w },
    label,
    elements,
    isOpen,
    loading,
    close,
    onCreate,
  } = props;
  const [state, dispatch] = useReducer(_reducer, _buildInitialState(elements));
  const [name, setName] = useState('');

  const closeModal = async () => {
    const value = '';
    dispatch({ type: 'setValue', payload: { name, value } });
    close();
  };

  return (
    <Modal small isOpen={isOpen} close={closeModal} selector="#root">
      {loading && <Loading />}
      <Header underlined>
        <Title underlined>{label}:</Title>
      </Header>
      {elements.map((el) => (
        <Element
          key={el.name}
          el={el}
          state={state}
          dispatch={dispatch}
          setName={setName}
        />
      ))}
      <Flex justifyContent="space-between" margin="10px 0px 0px 0px">
        <Button onClick={close}>{w.cancel}</Button>
        <Button
          color="secondary"
          onClick={async () => {
            let reset = await onCreate(state);
            reset && dispatch({ type: 'setState', payload: reset });
            close();
          }}
        >
          {w.confirm}
        </Button>
      </Flex>
    </Modal>
  );
});

const Element = (props) => {
  const {
    state,
    dispatch,
    el: { name, label, opts, code, type },
    setName,
  } = props;
  let input = null;

  const _setValue = (name, value) => {
    dispatch({ type: 'setValue', payload: { name, value } });
  };

  switch (type) {
    case 'boolean':
      input = (
        <Switch
          checked={state[name]}
          onChange={() => {
            _setValue(name, !state[name]);
          }}
        />
      );
      break;
    case 'select':
      input = (
        <Select
          label={label}
          options={opts || []}
          onChange={(e) => {
            const item = JSON.parse(e.target.value);
            _setValue(name, item[code]);
          }}
        />
      );
      break;
    default:
      input = (
        <Input
          value={state[name]}
          onChange={(e) => {
            setName(name);
            _setValue(name, e.target.value);
          }}
        />
      );
      break;
  }

  return (
    <React.Fragment>
      <Subtitle>{label}</Subtitle>
      {input}
    </React.Fragment>
  );
};
