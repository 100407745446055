import gql from 'graphql-tag';
import { _query } from 'api/graphql/old/client';

class Tag {
  getTags = async (args) => {
    return await _query(GET_TAGS, args);
  };
}

export default Tag;

const GET_TAGS = gql`
  query getTags($bot_id: Int) {
    getTags(bot_id: $bot_id) {
      tags {
        id
        slug
        translations {
          language {
            code
            name
          }
          name
        }
      }
    }
  }
`;
