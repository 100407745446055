import React, { useEffect, useState } from 'react';
import Entitie from './Entitie';
import Header from 'components/old/Header';
import Row from 'components/old/Row';
import { ClickIcon } from 'components/old/Icon';
import TextField from '@material-ui/core/TextField';
import Title from 'components/old/Title';
import Table, { TWrap, THEAD, TBody, TRow, THead } from 'components/old/Table';

export const Head = (props) => {
  const {
    g: { w },
    history,
    setSearch,
  } = props;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  return (
    <Header>
      <Row>
        <ClickIcon
          className="home"
          label="home"
          icon="fas fa-arrow-left"
          onClick={() => history.goBack()}
        />
        <Title>{w.entities}</Title>
      </Row>
      <TextField
        variant="outlined"
        type="search"
        placeholder={`${w.Search}`}
        onChange={(e) => handleSearch(e)}
      />
    </Header>
  );
};

export const Content = (props) => {
  const {
    g: {
      w,
      api: { Topics },
    },
    state,
    search,
    setSearch,
    setLocalTotal,
    dispatch,
  } = props;

  const [entities, setEntities] = useState(state.entities.array);

  const getSearchEntities = async (input) => {
    return Topics._get(null, {
      title: input,
      skip: state.entities.skip,
      take: state.entities.take,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'setEntities',
      payload: { skip: 0 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const searchFunction = async () => {
      if (search.length === 0) {
        setLocalTotal(state.entities.total);
        setEntities([]);
      } else {
        const res = await getSearchEntities(search);
        setLocalTotal(res.total);
        setEntities(res.data);
      }
    };
    searchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, state.entities.skip]);

  useEffect(() => {
    return () => {
      setSearch('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TWrap>
      <Table>
        <THEAD>
          <TRow>
            <THead> {w.groupName} </THead>
            <THead align="center"> {w.groupPreview} </THead>
            <THead align="end"> {w.options} </THead>
          </TRow>
        </THEAD>
        <TBody>
          {entities.length === 0 && search.length === 0
            ? state.entities.array.sort().map((m) => {
                return <Entitie key={m.id} entitie={m} {...props} />;
              })
            : entities.sort().map((m) => {
                return <Entitie key={m.id} entitie={m} {...props} />;
              })}
        </TBody>
      </Table>
    </TWrap>
  );
};
