import styled, { css } from 'styled-components';
import { _modCol } from 'tools/color';
import Button from 'components/old/Button';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: inherit;
  position: relative;
  > input {
    width: 100%;
    background: ${({ theme }) => _modCol(theme['colors']['primary'], 190)};
  }
  overflow: auto;
`;

export const Result = styled.div`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  white-space: pre-line;
  word-break: break-word;
  ${({ theme }) => css`
    border 1px solid ${theme['colors']['primary']}42;
    background: ${_modCol(theme['colors']['primary'], 190)};
  `}
`;

export const Card = styled.div`
  background-color: ${(p) => p.color && p.color};
  border-radius: 5px;
  height: 100%;
  min-height: 5rem;
`;

export const CardTitle = styled.div`
  padding-top: 0.5rem;
  margin-left: 1rem;
  color: white;
  text-decoration: underline;
  font-size: 120%;
`;

export const Data = styled.div`
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  text-decoration: none;
  width: 100%;
  color: white;
`;

export const StyledButton = styled(Button)`
  margin-left: 0.5rem;
  font-size: 0.7rem;
  background-color: cornflowerblue;
  color: white;
  border: none;
  padding: 0.2rem 1rem 0.2rem;
  &:focus {
    color: white;
  }
`;

export const ChartContainer = styled.div`
  width: 90%;
  max-height: 500px;
  padding-left: 10px;
`;

export const Icon = styled.span`
  position: relative;
  top: 3px;
  margin-right: 0.5rem;
`;

export const List = styled.ul`
  margin-top: 7px;
`;

export const FirstText = styled.li`
  margin-top: 0.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
  list-style-type: circle;
`;

export const SecondText = styled.div`
  margin-left: 0.4rem;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const Padding = styled.div`
  padding: ${(p) => p.padding};
`;
