import React from "react";
import { NavLink } from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import Select from "react-select";
import styled, { css } from "styled-components";
import FlagIcon from "components/FlagIcon";
import { _modCol } from "tools/color";
import useModel from "api/rest/hooks/useModel";
import GradeRoundedIcon from "@material-ui/icons/GradeRounded";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: white;
  flex: 4;
  box-shadow: ${({ theme }) => theme["shadows"][3]};
  border-radius: 5px;
  padding: 10px;
  position: relative;
  overflow: hidden;
`;

const features = [
  {
    id: 7,
    name: "leoRasa",
    path: null,
    type: "title",
    monolithAccessible: false,
  },
  {
    id: 0,
    name: "intents",
    path: "/office/model/:id/intents",
    type: "tab",
    monolithAccessible: true,
  },
  {
    id: 1,
    name: "entities",
    path: "/office/model/:id/entities",
    type: "tab",
    monolithAccessible: true,
  },
  {
    id: 5,
    name: "trainingRasa",
    path: "/office/model/:id/rasa-training",
    type: "tab",
    monolithAccessible: false,
  },
  {
    id: 8,
    name: "leoMar",
    path: null,
    type: "title",
    monolithAccessible: false,
  },
  {
    id: 4,
    name: "appellations",
    path: "/office/model/:id/appellations",
    type: "tab",
    monolithAccessible: false,
  },
  { id: 9, name: "test", path: null, type: "title" },
  {
    id: 3,
    name: "test",
    path: "/office/model/:id/test",
    type: "tab",
    monolithAccessible: false,
  },
  {
    id: 2,
    name: "parameters",
    path: "/office/model/:id/parameters",
    type: "tab",
    monolithAccessible: true,
  },
];

const Sidebar = styled.div`
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  flex: 1;
  border-radius: 5px 0px 0px 5px;
  box-shadow: ${({ theme }) => theme["shadows"][3]};
  margin-top: 1.5rem;
  > :first-child {
    border-radius: 0px 0px 0px 0px;
  }
`;

const Scroll = styled.div`
  height: 100%;
  overflow: auto;
  flex: 1;
`;

const Tab = styled(NavLink)`
  display: flex;
  font-weight: 600;
  min-height: 38px;
  padding: 0.7rem;
  text-decoration: none;
  color: ${({ theme }) => theme["colors"]["primary"]};
  border-bottom: 1px solid ${({ theme }) => theme["colors"]["primary"]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleTab = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 115%;
  min-height: 38px;
  padding: 0.7rem;
  text-decoration: none;
  color: white;
  background-color: ${({ theme }) => theme["colors"]["primary"]};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonFlag = styled(ButtonBase)`
  > .flag-icon {
    margin-right: 5px;
    margin-bottom: 2px;
    @media only screen and (max-width: 850px) {
      margin-right: 0;
    }
    /* width: 25px;
    height: 18px; */
    width: 18px;
    height: 18px;
    position: relative;
  }

  ${({ focused }) => {
    if (focused === "true") {
      return css`
        border-bottom: 2px solid black !important;
      `;
    }
  }}
`;

const Flags = styled.div`
  margin: 0.7rem;
  align-self: flex-end;
`;

const SelectItems = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  margin-right: 0;
`;

const ItemLabel = styled.span`
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin-top: 2rem;
`;

const ModelName = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  color: white;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
`;

const ModelNameText = styled.div`
  display: inline-block;
  padding: 0.3rem;
`;

const Line = styled.div`
  width: 1.4rem;
  height: 0.1rem;
  background-color: ${(p) => p.theme.colors.secondary};
  border-radius: 10px;
  margin: 0.3rem 0 0rem;
`;

const StarIcon = styled.span`
  margin-left: 2px;
`;

const customSingleValue = ({ data }) => (
  <div>
    <SelectItems>
      {data.icon && <span>{data.icon}</span>}
      <ItemLabel> {data.label}</ItemLabel>
    </SelectItems>
  </div>
);

const Languages = (props) => {
  const handleChange = async (event) => {
    await props.switchLanguage(event.value);
  };

  const statusOptions = [];

  props.data.languages.map((lang) => {
    let code = lang.split("_")[0];
    code = code === "en" ? "gb" : code;
    let text = code === "gb" ? "English" : code === "fr" ? "Français" : code;
    return statusOptions.push({
      value: lang,
      label: text,
      icon: (
        <ButtonFlag>
          <FlagIcon code={code} />
        </ButtonFlag>
      ),
    });
  });

  if (!props.data) return null;
  return (
    <Flags>
      <Select
        defaultValue={statusOptions[0]}
        options={statusOptions}
        onChange={handleChange}
        components={{ SingleValue: customSingleValue }}
      />
    </Flags>
  );
};

export default (props) => {
  const {
    g: { w },
    match: {
      params: { id },
    },
    model,
  } = props;
  const { data } = useModel(model.name);

  const isMonolith = !model.referenceBotId || model.referenceBotId === "0";

  return (
    <SidebarContainer>
      <ModelName>
        <ModelNameText>
          {model.name}{" "}
          {isMonolith && (
            <StarIcon>
              <GradeRoundedIcon color="secondary" style={{ fontSize: 18 }} />
            </StarIcon>
          )}
        </ModelNameText>

        <Line />
      </ModelName>

      <Sidebar>
        <Scroll>
          {features
            .filter((el) =>
              !isMonolith
                ? true
                : isMonolith && el.monolithAccessible
                ? true
                : false
            )
            .map((feat) => {
              if (feat.type === "title") {
                return <TitleTab key={feat.id}>{w[feat.name]}</TitleTab>;
              } else {
                return (
                  <Tab
                    key={feat.id}
                    to={feat.path.replace(/:id/, id)}
                    activeClassName="lqKla"
                    activeStyle={{
                      color: "white",
                      background: _modCol("#2C2E7B", 50),
                    }}
                  >
                    {w[feat.name]}
                  </Tab>
                );
              }
            })}

          {data.languages && (
            <Languages
              {...props}
              data={data}
              focus={model.language.code}
              switchLanguage={(lang) => {
                let model = data.model[lang];
                props.history.push(`/office/model/${model.id}/intents`);
              }}
            />
          )}
        </Scroll>
      </Sidebar>
    </SidebarContainer>
  );
};
