import styled, { css } from 'styled-components';

const Flex = styled.div`
  display: flex;
  ${({ flexDirection, justifyContent, alignItems, margin, flex }) => css`
    flex-direction: ${flexDirection};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    margin: ${margin};
    flex: ${flex};
  `}
`;

Flex.defaultProps = {
  flexDirection: 'initial',
  justifyContent: 'initial',
  alignItems: 'initial',
  margin: '0px',
  width: '100%',
  flex: 'auto',
};

export default Flex;
