const info = {
  fr_FR: {
    loadingStart: 'Chargement en cours...',
    loadingEnd: 'Chargement terminé',
  },
  en_EN: {
    loadingStart: 'Loading...',
    loadingEnd: 'Loading ended',
  },
};

export default info;
