import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import WarningIcon from 'icons/WarningIcon';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import useWords from 'hooks/words';

const Icon = styled.span`
  position: relative;
  top: 0.1rem;
  margin-right: 0.5rem;
`;

const ButtonGroup = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ConfirmationModal = ({ isOpen, close, data, entitie, intent }) => {
  const [tr] = useWords();

  const preserveEntitie = () => {
    data.entities_api.removeTopicFromIntent(
      parseInt(entitie.id),
      parseInt(intent.id),
      true
    );
    close();
  };

  const confirmDelete = () => {
    data.entities_api.deleteEntitie(entitie.id);
    close();
  };

  return (
    <Dialog open={isOpen} close={() => close()} fullWidth={true}>
      {' '}
      <DialogTitle>
        <Icon>
          <WarningIcon color="#2d2f7b" width="18" />
        </Icon>
        {tr.warning}
      </DialogTitle>
      <DialogContent>
        <Typography>{tr.deletionConfirmationText}</Typography>
        <ButtonGroup>
          <Button color="primary" onClick={close}>
            {tr.cancel}
          </Button>
          <ActionButtons>
            <Button color="primary" onClick={preserveEntitie}>
              {tr.keepWithoutIntent}
            </Button>
            <Button color="secondary" onClick={confirmDelete}>
              {tr.delete}
            </Button>
          </ActionButtons>
        </ButtonGroup>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
