import React from 'react';
import Input from 'components/old/Input';
import Select from 'react-select';
import { ClickIcon } from 'components/old/Icon';
import Title from 'components/old/Title';
import Header from 'components/old/Header';
import Row from 'components/old/Row';

const TrainingHeader = (props) => {
  const {
    g: { w },
    setSearch,
    history,
    value,
    handleSubmit,
    searchLogs,
    searchBy,
    models,
    setValueModels,
  } = props;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const handleChange = (e) => {
    searchLogs(e.value);
  };

  const handleChangeModels = (value, { action, removedValue }) => {
    console.log(action);
    switch (action) {
      case 'select-option':
        setValueModels(value.value);
        break;
      case 'clear':
        setValueModels('');
        break;
      default:
        break;
    }
  };

  const options = [
    { value: 'sentence', label: 'Phrases' },
    { value: 'model', label: 'Modèle' },
  ];

  const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', width: 150 }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? 'white'
          : isFocused
          ? '#E66E6E'
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? '#E66E6E'
          : isFocused
          ? 'white'
          : null,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: '#E66E6E',
        },
      };
    },
    input: (styles) => ({ ...styles }),
  };

  const stylesCustomSelect = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#E66E6E',
      color: '#FFF',
      width: 150,
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? 'white'
          : isFocused
          ? '#E66E6E'
          : null,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? '#E66E6E'
          : isFocused
          ? 'white'
          : null,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: '#E66E6E',
        },
      };
    },
    input: (styles) => ({ ...styles }),
    placeholder: (styles) => ({ ...styles, color: '#FFF' }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#FFF',
      '&:hover': { color: '#FFF' },
    }),
    singleValue: (styles) => ({ ...styles, color: '#FFF' }),
  };

  const optionModels = models.map((model) => {
    return {
      value: model.id,
      label: model.name,
    };
  });

  return (
    <Header>
      <Row>
        <ClickIcon
          label="home"
          icon="fas fa-home"
          onClick={() => history.push('/office')}
        />
        {searchBy === 'sentence' && (
          <Input
            type="search"
            placeholder={`${w.Search}`}
            value={value}
            onChange={(e) => handleSearch(e)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          />
        )}
        {searchBy === 'model' && (
          <Select
            options={optionModels}
            placeholder={`${w.Search}`}
            onChange={handleChangeModels}
            isClearable={true}
            styles={customStyles}
          />
        )}
      </Row>
      <Title>{`${w.train}`}</Title>
      <Select
        placeholder={`${w.search.charAt(0).toUpperCase() + w.search.slice(1)} ${
          w.by
        }`}
        options={options}
        onChange={handleChange}
        styles={stylesCustomSelect}
        isSearchable={false}
      />
    </Header>
  );
};

export default TrainingHeader;
