import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import useWords from 'hooks/words';
import Header from 'components/old/Header';
import Title from 'components/old/Title';
import Flex from 'components/old/Flex';
import { ClickIcon } from 'components/old/Icon';
import Preview from './Preview';
import Edit from './Edit';
import Modal from 'components/old/Modal';
import TextField from '@material-ui/core/TextField';

import WarningIcon from 'icons/WarningIcon';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
`;

const MonolithWarning = styled.div`
  font-size: 0.9rem;
  color: white;
  max-width: 60%;
  background-color: #e66e6e;
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Icon = styled.span`
  margin-top: 0.3rem;
  margin-right: 0.5rem;
`;

export default (props) => {
  const {
    g,
    isOpen,
    close,
    entitie,
    edition,
    state,
    view,
    match: {
      params: { id },
    },
    handleValidateGrpe,
  } = props;
  const {
    w,
    api: { Values, Models },
  } = g;
  const [edit, setEdit] = useState(edition);
  const [val, setValue] = useState('');
  const [model, setModel] = useState({});
  const [newValues, setNewValues] = useState([]);
  const [, setModification] = useState(false);
  const [editGrpe, setEditGrpe] = useState(false);
  const [valueGrpe, setValueGrpe] = useState(entitie.title);

  const [tr] = useWords();
  const notification = useGlobalMessage();

  useEffect(() => {
    setEdit(edition);
  }, [edition]);

  useEffect(() => {
    const fetchModel = async () => {
      const res = await Models._getById(id);
      setModel(res);
    };
    fetchModel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGrpe = (val) => {
    setValueGrpe(val);
  };

  return (
    <Modal isOpen={isOpen} close={close} selector="#root">
      <Header padding={'1rem 1rem 0 0'}>
        <WrapperGrpe>
          {view && editGrpe ? (
            <StyledUpdateGrpe>
              <StyledInput
                defaultValue={valueGrpe}
                value={valueGrpe}
                onChange={(e) => handleGrpe(e.target.value)}
              />
              <ClickIcon
                label="plus"
                icon="fas fa-check"
                onClick={() => {
                  setEditGrpe(!editGrpe);
                  handleValidateGrpe(entitie.id, valueGrpe);
                }}
              />
              <ClickIcon
                label="plus"
                icon="fas fa-times"
                onClick={() => {
                  setEditGrpe(!editGrpe);
                  handleGrpe(entitie.title);
                }}
              />
            </StyledUpdateGrpe>
          ) : (
            <>
              <Title underlined textTransform="none">
                {entitie.title}:
              </Title>
              {view && (
                <ClickIcon
                  label="plus"
                  icon="fas fa-pencil-alt"
                  onClick={() => setEditGrpe(!editGrpe)}
                />
              )}
            </>
          )}
        </WrapperGrpe>
        {edit && (
          <TextField
            size="small"
            variant="outlined"
            value={val}
            placeholder={w.addValue}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={async (e) => {
              if (
                e.key === 'Enter' &&
                state.values.some((el) => el.expression === val)
              ) {
                notification.warning(tr.valueWithSameNameExists);
              } else if (e.key === 'Enter' && val) {
                const res = await Values._post({
                  expression: val,
                  topic: entitie.id,
                });
                if (res.id) {
                  setNewValues(newValues.concat(res));
                  setValue('');
                  await props.callback();
                  g.dispatch({
                    type: 'setSuccess',
                    payload: w.creationSuccess,
                  });
                  setModification(true);
                  notification.success(tr.saved);
                } else if (res.code === 'ERR_WRONG_ENTITY_DATA') {
                  notification.error(tr.errWrongEntityData);
                } else {
                  g.dispatch({
                    type: 'setError',
                    payload: w.error,
                  });
                  notification.error(w.error);
                }
              }
            }}
          />
        )}
      </Header>
      {edit ? (
        <>
          {model && (!model.referenceBotId || model.referenceBotId === '0') && (
            <Container>
              <MonolithWarning>
                <Icon>
                  <WarningIcon width={18} />
                </Icon>
                {tr.monolithModelWarning}
              </MonolithWarning>
            </Container>
          )}

          <Edit
            {...props}
            setModification={setModification}
            newValues={newValues}
          />
        </>
      ) : (
        <Preview {...props} />
      )}
      <Flex justifyContent="center" margin="10px">
        <ClickIcon
          label="plus"
          icon={edit ? 'fas fa-eye' : 'fas fa-pencil-alt'}
          bordered
          squared
          onClick={() => setEdit(!edit)}
        />
      </Flex>
    </Modal>
  );
};

const StyledInput = styled.input`
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 0;
  height: 35px;
  background: none;
  border-radius: 0;
  color: #2d2f7b;
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;
  transition: all 0.15s ease;
  &:focus {
    outline: none;
    stroke: #111;
    border-bottom: 1px solid #2d2f7b;
  }
  &::placeholder {
    color: #2d2f7b;
  }
`;

const WrapperGrpe = styled.div`
  display: flex;
`;

const StyledUpdateGrpe = styled.div`
  display: flex;
  align-items: center;
`;
