import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import useWords from 'hooks/words';
import { Props } from '../Models';
import { Models, Methods as Models_api } from 'api/rest/hooks/useModels';
import { Pagination, Methods as Pagination_api } from 'hooks/pagination';
import Head from './body/Head';
import Content from './body/Content';
import Footer from './body/Footer';

export type BodyProps = Props & {
  error: Error | null;
  data: {
    models: Models;
    models_api: Models_api;
    pagination: Pagination;
    pagination_api: Pagination_api;
  };
};

type StyledGridType = {
  height?: any;
};

const StyledGrid = styled(Grid)<StyledGridType>`
  margin: ${({ theme }) => theme.spacing}px 0px;
  height: ${(p) => (p.height ? `${p.height - 250}px` : '90%')};
`;

export const Legend = styled.div`
  display: flex;
  margin: 1rem 0.5rem 1rem;
  padding-bottom: 0.1rem;
  justify-content: flex-start;
  border-bottom: 1px solid #eee;
`;

export const StarIcon = styled.div`
  margin-top: 1px;
`;

const Body = (props: BodyProps) => {
  const [tr] = useWords();

  const { height } = useWindowSize();

  // if (props.error) throw props.error;
  return (
    <StyledGrid
      container
      wrap="nowrap"
      direction="column"
      spacing={0}
      height={height}
    >
      <Legend>
        {' '}
        <StarIcon>
          <GradeRoundedIcon color="secondary" style={{ fontSize: 15 }} />
        </StarIcon>
        {` : ${tr.monolithModel}`}
      </Legend>
      <Head />

      <Content {...props} />
      <Footer {...props} />
    </StyledGrid>
  );
};

export default Body;
