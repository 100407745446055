const legend = {
  fr_FR: {
    monolithModel: 'Modèle Monolithe',
    monolithIntent: "Intent provenant d'un modèle monolithe",
  },
  en_EN: {
    monolithModel: 'Monolith Model',
    monolithIntent: 'Intent coming from a monolithe',
  },
};

export default legend;
