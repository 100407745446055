import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Page } from '../Ui';
import Header from 'components/old/Header';
import Typo from 'components/old/Typo';
import Button from 'components/old/Button';
import Entitie from '../entities/Entitie';
import Import from './Import';
import Icon from 'components/old/Icon';
import Create from 'components/old/CreateFactory';
import Loading from 'components/old/Loading';
import Table, { TWrap, THEAD, TBody, TRow, THead } from 'components/old/Table';
import { useGlobalMessage } from '../../../../hooks/useGlobalMessage';
import useWords from '../../../../hooks/words';

const Entities = styled(Page)`
  max-height: 60vh;
  border: 1px solid ${({ theme }) => theme['colors']['primary']};
  > input {
    width: 100%;
  }
  box-shadow: none;
`;

const Content = (props) => {
  const {
    g: { w, api },
    data,
    data: { entities_api },
    state: {
      intent: { id: intentID },
    },
    search,
    items,
  } = props;

  const [entities, setEntities] = useState(items);
  const notification = useGlobalMessage();
  const [tr] = useWords();

  useEffect(() => {
    if (search.length === 0) {
      setEntities(items);
    } else {
      setEntities(
        items.filter((el) =>
          el.title.toLowerCase().includes(search.toLowerCase())
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleValidateGrpe = async (id, valueGrpe) => {
    const res = await api.Topics._put(id, {
      title: valueGrpe,
    });
    if (res) {
      const newArray = [...data.entities];
      const entitieFocused = data.entities.findIndex((el) => el.id === id);
      newArray[entitieFocused] = res;
      setEntities(newArray);
      notification.success(tr.saved);
    } else {
      notification.error(w.error);
    }
  };

  if (!items) return <Loading />;
  return (
    <TWrap>
      <Table>
        <THEAD>
          <TRow>
            <THead> {w.group} </THead>
            <THead align="center"> {w.groupValue} </THead>
            <THead align="end">
              {' '}
              <Icon icon="fas fa-eye" />{' '}
            </THead>
          </TRow>
        </THEAD>
        <TBody>
          {entities.length === 0 && search.length === 0
            ? items.map((m) => {
                return (
                  <Entitie
                    key={m.id}
                    entitie={m}
                    view="true"
                    removeFromIntent={() => {
                      entities_api.removeTopicFromIntent(m.id, intentID, true);
                    }}
                    handleValidateGrpe={handleValidateGrpe}
                    {...props}
                  />
                );
              })
            : entities.map((m) => {
                return (
                  <Entitie
                    key={m.id}
                    entitie={m}
                    view="true"
                    handleValidateGrpe={handleValidateGrpe}
                    removeFromIntent={() => {
                      entities_api.removeTopicFromIntent(m.id, intentID, true);
                    }}
                    {...props}
                  />
                );
              })}
        </TBody>
      </Table>
    </TWrap>
  );
};

export default (props) => {
  const {
    g,
    data: { entities, entities_api },
    loading,
    match: {
      params: { code },
    },
  } = props;
  const { w } = g;
  const [create, setCreate] = useState(false);
  const [add, setAdd] = useState(false);

  return (
    <Entities>
      {loading && <Loading />}
      {!loading && (
        <Import
          isOpen={add}
          close={() => setAdd(false)}
          {...props}
          entities={entities}
        />
      )}
      <Create
        {...props}
        label={w.createEntities}
        isOpen={create}
        close={() => setCreate(false)}
        elements={[{ name: 'title', label: 'Nom de groupe', type: 'input' }]}
        reset={{ title: '' }}
        onCreate={(state) =>
          entities_api.createTopic({
            ...state,
            intents: [Number(code)],
          })
        }
      />
      <Header>
        <Typo fontWeight={600} textTransform="capitalize">
          {w.intentEntities}
        </Typo>
        <div>
          <Button onClick={() => setAdd(true)} margin="0px 5px">
            {w.import}
          </Button>
          <Button onClick={() => setCreate(true)}>{w.create}</Button>
        </div>
      </Header>
      {!loading && <Content {...props} items={entities} />}
    </Entities>
  );
};
