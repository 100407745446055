import styled from 'styled-components';
type TStyledDiv = {
  left: string;
  right: string;
  top: string;
  bottom: string;
};
export const Spacing = styled.div<TStyledDiv>`
  ${(p) => p.top && `margin-top: ${p.top}`};
  ${(p) => p.right && `margin-right: ${p.right}`};
  ${(p) => p.bottom && `margin-bottom: ${p.bottom}`};
  ${(p) => p.left && `margin-left: ${p.left}`};
`;
