import React from 'react';
import styled from 'styled-components';
import icon from 'assets/diagram.svg';

const IconLayout = styled.svg`
  z-index: 100;
  width: 4rem;
  height: 4rem;
  fill: white;
`;

const DiagramIcon = () => (
  <IconLayout>
    <use xlinkHref={`${icon}#Capa_1`} />
  </IconLayout>
);

export default DiagramIcon;
