import styled, { keyframes } from 'styled-components';
import BaseAnimation from './Animation';

const SmoothAnimation = keyframes`
  0% { max-height: 0vh;}
  100% { max-height: 60vh; }
`;

const Smooth = styled(BaseAnimation)`
  z-index: ${({ zIndex }) => zIndex};
  animation-name: ${SmoothAnimation};
  overflow: hidden;
`;

Smooth.defaultProps = {
  zIndex: 'initial',
};

export default Smooth;
