import React from 'react';
import Grow from '@material-ui/core/Grow';
import { AppContext } from 'app/App';
import { Style } from './';

const Error = () => {
  const { app } = React.useContext(AppContext);
  const [error, setError] = React.useState('');
  const tr = app.wording.translation;

  React.useEffect(() => {
    const error = () => setError(tr.error);
    window.addEventListener('error', error);
    return () => {
      window.removeEventListener('error', error);
    };
  });

  React.useEffect(() => {
    let timeId = null;
    if (error) {
      if (timeId) clearTimeout(timeId);
      else
        timeId = setTimeout(() => {
          setError('');
        }, 3000);
    }
  }, [error]);

  if (!error) return null;

  return (
    <Grow in={true}>
      <Style>{error}</Style>
    </Grow>
  );
};

export default Error;
