import React, { useEffect } from 'react';
import { useStore } from 'store/store';
import { CLEAR_TESTPANEL } from 'store/testpanel';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/Loader';
import Header from './models/Header';
import Body from './models/Body';
import Create from './models/Create';
import Delete from './models/Delete';
import useModels from 'api/rest/hooks/useModels';

export interface Props extends RouteComponentProps<any> {
  search: string;
  create: boolean;
  setSearch: (str: string) => void;
  setCreate: (bool: boolean) => void;
  setDelete: (model: any) => void;
}

const Models = (props: Props) => {
  const [search, setSearch] = React.useState<string>('');
  const [create, setCreate] = React.useState<boolean>(false);
  const [deleteItem, setDelete] = React.useState<any>();
  const [, dispatch] = useStore();
  const { loading, error, data } = useModels(search);

  const childProps: Props = {
    ...props,
    search,
    create,
    setSearch,
    setCreate,
    setDelete,
  };

  let dataObject: any = data;
  let errorObject: Error | null = null;

  if (!data.models) {
    dataObject.models = [];
  }

  if (error !== undefined) {
    errorObject = error;
  }

  useEffect(
    () => {
      dispatch({ type: CLEAR_TESTPANEL });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Grid
      container
      wrap="nowrap"
      alignContent="center"
      alignItems="center"
      direction="column"
    >
      {loading && <Loader absolute />}
      <Header {...childProps} />
      <ErrorBoundary>
        {!loading && (
          <Body {...childProps} error={errorObject} data={dataObject} />
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        {!loading && create && <Create {...childProps} data={dataObject} />}
      </ErrorBoundary>
      <ErrorBoundary>
        {!loading && deleteItem !== null && deleteItem !== undefined && (
          <Delete {...childProps} data={dataObject} deleteItem={deleteItem} />
        )}
      </ErrorBoundary>
    </Grid>
  );
};

export default Models;
