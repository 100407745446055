import React from 'react';
import Routes from 'routes/Routes';
import Box from '@material-ui/core/Box';
import Wording from 'wording/';
import Indicators from 'components/indicators/';
import Client from 'api/graphql/Client';
import { StoreProvider } from 'store/store';
type State = {
  client: Client;
  apiUrl: string;
  mlApiUrl: string;
  lang: string;
  wording: Wording;
  token?: string;
};
type Action =
  | { type: 'setToken'; payload: string }
  | { type: 'setServer'; payload: boolean }
  | { type: 'setClient'; payload: Client };
type Context = {
  app: State;
  dispatch: (action: Action) => void;
};
const AppContext = React.createContext({} as Context);
const AppReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setClient':
      return { ...state, client: action.payload };
    case 'setToken':
      return { ...state, token: action.payload };
    case 'setServer':
      return { ...state, server: action.payload };
    default:
      return state;
  }
};
const App = () => {
  const [app, dispatch] = React.useReducer(AppReducer, {
    client: new Client(
      `https://${process.env.REACT_APP_API}`
    ),
    apiUrl: `https://${process.env.REACT_APP_API}`,
    mlApiUrl: `https://${process.env.REACT_APP_MLAPI}`,
    lang: localStorage.getItem('locale') || 'fr_FR',
    wording: new Wording(localStorage.getItem('locale') || 'fr_FR'),
    token: localStorage.getItem('token') || undefined,
    server: undefined,
  });
  return (
    <AppContext.Provider value={{ app, dispatch }}>
      <StoreProvider>
        <Indicators>
          <Box component="main" maxWidth="lg" height="100%">
            <Routes />
          </Box>
        </Indicators>
      </StoreProvider>
    </AppContext.Provider>
  );
};
export default App;
export { AppContext };
