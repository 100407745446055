import React from 'react';
import translation from 'wording/old/';
import User from 'api/graphql/old/user';
import Type from 'api/graphql/old/Type';
import Theme from 'api/graphql/old/Theme';
import Tag from 'api/graphql/old/Tag';
import Public from 'api/graphql/old/Public';
import Place from 'api/graphql/old/Place';
import Intents from 'api/rest/old/Intents';
import Models from 'api/rest/old/Models';
import Languages from 'api/rest/old/Languages';
import Topics from 'api/rest/old/Topics';
import Sentences from 'api/rest/old/Sentences';
import Synonyms from 'api/rest/old/Synonyms.js';
import Values from 'api/rest/old/Values';
import Training from 'api/rest/old/Training';
import Appellations from 'api/rest/old/Appellations';
import Variations from 'api/rest/old/Variations';
import { AppContext } from 'app/App';

const Globals = React.createContext();

export const GlobalsHOC = (props) => {
  const { app } = React.useContext(AppContext);
  const [state, dispatch] = React.useReducer(_reducer, initialState(app.token));

  React.useEffect(() => {
    if (state.status.msg)
      setTimeout(() => {
        dispatch({ type: 'setStatus', payload: { msg: '' } });
      }, 3000);
  }, [state.status]);

  return (
    <Globals.Provider value={{ ...state, dispatch }}>
      {props.children}
    </Globals.Provider>
  );
};

export const withGlobal = (Component) => {
  return (props) => {
    return (
      <Globals.Consumer>
        {(context) => <Component g={context} {...props} />}
      </Globals.Consumer>
    );
  };
};

export default Globals;

export const _reducer = (state, action) => {
  switch (action.type) {
    case 'setLang':
      return { ...state, lang: action.payload, w: translation[action.payload] };
    case 'setBots':
      return { ...state, bots: action.payload };
    case 'setSuccess':
      return { ...state, status: { col: 'success', msg: action.payload } };
    case 'setError':
      return { ...state, status: { col: 'secondary', msg: action.payload } };
    case 'setStatus':
      return { ...state, status: action.payload };
    case 'setLanguages':
      return { ...state, languages: action.payload };
    default:
      return state;
  }
};

export const initialState = (token) => ({
  w: translation[localStorage.getItem('locale') || 'fr'],
  status: {
    col: '',
    msg: '',
  },
  apiUrl: `https://${process.env.REACT_APP_API}`,
  languages: [],
  bots: [],
  api: {
    Appellations: new Appellations(token),
    Intents: new Intents(token),
    Languages: new Languages(token),
    Models: new Models(token),
    Sentences: new Sentences(token),
    Synonyms: new Synonyms(token),
    Topics: new Topics(token),
    Training: new Training(token),
    User: new User(token),
    Values: new Values(token),
    Type: new Type(token),
    Tag: new Tag(token),
    Theme: new Theme(token),
    Public: new Public(token),
    Place: new Place(token),
    Variations: new Variations(token),
  },
});
