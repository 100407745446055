import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Card, CardTitle, Data, Icon } from '../layout/Layout';
import { Spacing } from 'components/Layout';
import InfoIcon from 'icons/InfoIcon';

const MonolithInfoCard = ({ tr }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12}>
        <Card color="mediumseagreen">
          <CardTitle>
            {' '}
            <Icon>
              <InfoIcon width="20" />
            </Icon>
            {tr.testMonolith}
          </CardTitle>
          <Data>
            <Spacing left="1.7rem" top="0.5rem">
              {tr.testMonolithText}
            </Spacing>
            <Spacing left="1.7rem" top="1rem" bottom="1rem">
              {tr.pleaseChooseAnother}
            </Spacing>
          </Data>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MonolithInfoCard;
