import gql from 'graphql-tag';
import { _query } from 'api/graphql/old/client';

class Type {
  getTypes = async (args) => {
    return await _query(GET_TYPES, args);
  };
}

export default Type;

const GET_TYPES = gql`
  query getTypes($bot_id: Int) {
    getTypes(bot_id: $bot_id) {
      types {
        id
        slug
        translations {
          language {
            code
            name
          }
          name
        }
      }
    }
  }
`;
