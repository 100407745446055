import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

type LoaderProps = {
  locker?: boolean;
  absolute?: boolean;
};

interface Props {
  readonly absolute?: boolean;
}

const Style = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  top: 0;
  right: 0;
  color: white;
  background: rgba(42, 42, 42, 0.7);
  position: ${({ absolute }) => (absolute ? 'absolute' : 'fixed')};
  height: ${({ absolute }) => (absolute ? '100%' : 'auto')};
  width: ${({ absolute }) => (absolute ? '100%' : 'auto')};
`;

const Loader = (props: LoaderProps) => {
  const { locker, ...other } = props;

  return (
    <Style {...other}>
      <CircularProgress color="inherit" />
    </Style>
  );
};

export default Loader;
