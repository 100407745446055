import React from 'react';
import styled, { css } from 'styled-components';

const Switch = styled.label`
  position: relative;
  display: block;
  width: 30px;
  height: 17px;

  > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  ${({ checked, theme, color }) => {
    if (checked)
      return css`
        background-color: ${theme['colors'][color]};
        box-shadow: ${theme['shadows'][1]};
        &:before {
          transform: translateX(13px);
        }
      `;
    else return null;
  }}
`;

Slider.defaultProps = {
  color: 'primary',
};

const Comp = ({ checked, color, shadow, onChange, disabled }) => {
  return (
    <Switch>
      <input disabled={disabled} type="checkbox" checked={checked} onChange={onChange} />
      <Slider checked={checked} color={color} shadow={shadow}></Slider>
    </Switch>
  );
};

Comp.defaultProps = {
  onChange: (e) => console.log(e),
  color: 'primary',
  shadow: 1,
};

export default Comp;
