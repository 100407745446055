import React from 'react';
import Header from 'components/old/Header';
import { ClickIcon } from 'components/old/Icon';
import TextField from '@material-ui/core/TextField';
import Row from 'components/old/Row';
import Typo from 'components/old/Typo';
const Head = (props) => {
  const {
    g: {
      w,
      api: { Intents },
    },
    state,
    setSearch,
    history,
    dispatch,
    match: {
      params: { code },
    },
  } = props;
  const [edit, setEdit] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  return (
    <Header>
      <Row>
        <ClickIcon
          className="home"
          label="home"
          icon="fas fa-arrow-left"
          onClick={() => history.goBack()}
        />
        <TextField
          variant="outlined"
          type="search"
          placeholder={`${w.Search}`}
          onChange={(e) => handleSearch(e)}
        />
      </Row>
      <Row>
        {edit && (
          <React.Fragment>
            <TextField
              variant="outlined"
              defaultValue={state.intent.title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <ClickIcon
              label="check"
              icon="fas fa-check"
              onClick={async () => {
                if (title) {
                  let params = {
                    ...state.intent,
                    models: state.intent.models.map((o) => o.id),
                    title: title,
                  };
                  if (state.intent.language)
                    params = { ...params, language: state.intent.language.id };
                  const res = await Intents._put(code, params);
                  if (res) {
                    dispatch({
                      type: 'setIntent',
                      payload: { ...state.intent, title: title },
                    });
                    setTitle('');
                    setEdit(false);
                  }
                }
              }}
            />
          </React.Fragment>
        )}
        {!edit && (
          <React.Fragment>
            <Typo textTransform="uppercase" fontWeight="700" color="grey">
              {state.intent.title}
            </Typo>
            <ClickIcon
              label="edit"
              icon="fas fa-pencil-alt"
              onClick={() => setEdit(true)}
            />
          </React.Fragment>
        )}
      </Row>
      <Row></Row>
    </Header>
  );
};
export default Head;
