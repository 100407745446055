import React, { useReducer, useEffect } from 'react';
import Pagination from 'components/old/Pagination';
import Loading from 'components/old/Loading';
import styled from 'styled-components';
import Vals from './Values';
import Smooth from 'components/old/animations/Smooth';

const Content = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme['colors']['primary']};
  border-top: 1px solid ${({ theme }) => theme['colors']['primary']};
  max-height: 60vh;
  padding: 0.3rem;
  overflow: scroll;
  transition: max-height;
  > :not(:last-child) {
    margin-bottom: 0.3rem;
  }
`;

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setArray':
      return { ...state, array: action.payload };
    case 'setValue': {
      return {
        ...state,
        array: state.array.map((el) => {
          if (el.id === action.payload.id) return action.payload;
          return el;
        }),
      };
    }
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setSkip':
      return { ...state, skip: action.payload };
    default:
      return state;
  }
};

export default (props) => {
  const {
    g: {
      api: { Values },
    },
    entitie,
    newValues,
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    array: [],
    skip: 0,
    total: 0,
    take: 10,
    loading: false,
  });

  useEffect(() => {
    const _getValues = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await Values._get(null, {
        skip: state.skip,
        take: state.take,
        topics: entitie.id,
      });
      if (res) {
        dispatch({ type: 'setArray', payload: res.data });
        dispatch({ type: 'setTotal', payload: res.total });
      }
      dispatch({ type: 'setLoading', payload: false });
    };
    _getValues();
  }, [state.skip, state.take, entitie.id, Values, newValues]);

  return (
    <React.Fragment>
      <Smooth duration="500ms">
        <Content>
          {state.loading && <Loading />}
          {state.array.map((el) => {
            return (
              <Vals
                {...props}
                key={el.id}
                value={el}
                p={{
                  state: state,
                  dispatch: dispatch,
                }}
              />
            );
          })}
        </Content>
      </Smooth>
      <Pagination
        activePage={(state.skip + state.take) / state.take}
        itemsCountPerPage={state.take}
        totalItemsCount={state.total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          dispatch({ type: 'setSkip', payload: (page - 1) * state.take });
        }}
      />
    </React.Fragment>
  );
};
