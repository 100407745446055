import React from 'react';
import { AppContext } from 'app/App';
import Api from 'api/rest';
import useWords from 'hooks/words';
import { useGlobalMessage } from 'hooks/useGlobalMessage';

const useReadingTest = () => {
  const { app } = React.useContext(AppContext);
  const notification = useGlobalMessage();
  const [tr] = useWords();

  const getReadingTest = async (
    model: any,
    sentence: string,
    language: string
  ) => {
    let api = new Api(`${app.mlApiUrl}/training/reading`, app.token);
    let body = {
      model: model,
      sentence: sentence,
      language: language,
    };
    let readingTest: any = {};
    let loading: boolean = true;
    let error: any = undefined;

    try {
      let res = await api.post(body);
      readingTest = res;
    } catch (err) {
      error = err;
      notification.error(tr.failedToFetch);
    } finally {
      loading = false;
    }
    return { readingTest, error, loading };
  };

  const fileImport = async (data: any) => {
    console.log(data);
  };

  const readingTest_api = {
    fileImport,
    getReadingTest,
  };

  const data = {
    readingTest_api,
  };

  return { data };
};

export default useReadingTest;
