import React from 'react';
import MUITextField, { TextFieldProps } from '@material-ui/core/TextField';

const TextField = (props: TextFieldProps) => {
  return (
    <MUITextField {...props} margin="normal" variant="outlined" fullWidth />
  );
};

export default TextField;
