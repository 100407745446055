import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useWords from 'hooks/words';
import { useStore } from 'store/store';
import { SET_SELECTION_RELOAD } from 'store/reload';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import Modal from 'components/old/Modal';
import Title from 'components/old/Title';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

const Container = styled.div`
  padding: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const Element = styled.div`
  margin-bottom: 1rem;
`;

const Selection = styled.div`
  display: flex;
  align-items: center;
`;

const Item = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
`;

const Icon = styled.span`
  margin-left: 0.5rem;
  margin-top: 0.2rem;
`;

const Selector = styled.div`
  margin-top: 0.5rem;
  width: 40%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const SelectModal = ({
  g: {
    api: { Values, Synonyms },
  },
  isOpen,
  close,
  entities_data,
  selection,
  setSelection,
}) => {
  const [selectedEntitie, setSelectedEntitie] = useState('');
  const [values, setValues] = useState([]);
  const [synonyms, setSynonyms] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');

  const [synonym, setSynonym] = useState(false);
  const [edit, setEdit] = useState(false);

  const [
    {
      reload: { selectionReload },
    },
    dispatch,
  ] = useStore();

  const [tr] = useWords();
  const notification = useGlobalMessage();

  useEffect(() => {
    const getAsyncValues = async () => {
      const params = {
        topics: selectedEntitie.toString(),
      };
      const res = await Values._get(null, params);
      setValues(res.data);
    };
    selectedEntitie !== '' && getAsyncValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntitie]);

  useEffect(() => {
    const getAsyncSynonyms = async () => {
      const params = {
        values: selectedValue.toString(),
      };
      const res = await Synonyms._get(null, params);
      setSynonyms(res.data);
    };
    selectedValue !== '' && getAsyncSynonyms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    return () => {
      setSynonym(false);
      setSelectedEntitie('');
      setSelectedValue('');
    };
  }, []);

  const handleEntitieSelect = (e) => {
    setSelectedEntitie(e.target.value);
  };

  const handleValueSelect = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleCheck = (e) => {
    setSynonym(e.target.checked);
  };

  const addValue = async () => {
    if (values.some((el) => el.expression === selection)) {
      notification.warning(tr.valueWithSameNameExists);
    } else {
      try {
        const res = await Values._post({
          expression: selection,
          topic: selectedEntitie,
        });
        console.log(res);
        if (res) {
          notification.success(tr.saved);
          setSynonym(false);
          setSelectedEntitie('');
          setSelectedValue('');
          close();
          dispatch({ type: SET_SELECTION_RELOAD, payload: !selectionReload });
        }
      } catch (err) {
        notification.error(tr.addingValueError);
      }
    }
  };

  const addSynonym = async () => {
    if (synonyms.some((el) => el.expression === selection)) {
      notification.warning(tr.synonymWithSameNameExists);
    } else if (values.some((el) => el.expression === selection)) {
      notification.warning(tr.valueWithSameNameExists);
    } else {
      try {
        const res = await Synonyms._post({
          expression: selection,
          value: selectedValue,
        });
        if (res) {
          notification.success(tr.saved);
          setSynonym(false);
          setSelectedEntitie('');
          setSelectedValue('');
          close();
          dispatch({ type: SET_SELECTION_RELOAD, payload: !selectionReload });
        }
      } catch (err) {
        notification.error(tr.addingSynonymError);
      }
    }
  };

  const closeModal = () => {
    setSynonym(false);
    setSelectedEntitie('');
    setSelectedValue('');
    close();
  };

  const disabled = selectedEntitie === '' || (synonym && selectedValue === '');

  return (
    <Modal isOpen={isOpen} close={closeModal} selector="#root">
      <Container>
        <Title underlined>{tr.addSelectionTitle}</Title>
        <Content>
          <Element>
            <Selection>
              <strong>{tr.selection}</strong>{' '}
              {edit ? (
                <Item>
                  <TextField
                    size="small"
                    value={selection}
                    onChange={(e) => setSelection(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && setEdit(false)}
                  />
                </Item>
              ) : (
                <Item>"{selection}"</Item>
              )}
              {edit ? (
                <Icon>
                  <CheckRoundedIcon
                    fontSize="small"
                    onClick={() => setEdit(false)}
                  />
                </Icon>
              ) : (
                <Icon>
                  <EditRoundedIcon
                    fontSize="small"
                    onClick={() => setEdit(true)}
                  />
                </Icon>
              )}
            </Selection>
          </Element>
          <Element>
            <strong>{tr.chooseEntitie}</strong>

            <Selector>
              <FormControl>
                <InputLabel>{tr.entitiePlaceholder}</InputLabel>
                <NativeSelect
                  value={selectedEntitie}
                  onChange={handleEntitieSelect}
                >
                  <option aria-label="None" value="" />
                  {entities_data.entities &&
                    entities_data.entities.map((el) => (
                      <option value={el.id}>{el.title}</option>
                    ))}
                </NativeSelect>
              </FormControl>
            </Selector>
          </Element>
          {selectedEntitie !== '' && (
            <Element>
              {' '}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={synonym}
                    onChange={handleCheck}
                    name="synonym"
                  />
                }
                label="Ajouter en tant que synonyme"
              />
            </Element>
          )}
          {synonym && values.length > 0 && (
            <Element>
              <strong>{tr.chooseValue}</strong>
              <Selector>
                <FormControl>
                  <InputLabel>{tr.valuePlaceholder}</InputLabel>
                  <NativeSelect
                    value={selectedValue}
                    onChange={handleValueSelect}
                  >
                    <option aria-label="None" value="" />
                    {values &&
                      values.map((el) => (
                        <option value={el.id}>{el.expression}</option>
                      ))}
                  </NativeSelect>
                </FormControl>
              </Selector>
            </Element>
          )}

          <ButtonsContainer>
            <Button variant="contained" color="secondary" onClick={closeModal}>
              {tr.cancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={() => {
                synonym ? addSynonym() : addValue();
              }}
            >
              {tr.add}
            </Button>
          </ButtonsContainer>
        </Content>
      </Container>
    </Modal>
  );
};

export default SelectModal;
