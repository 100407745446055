import React from 'react';
import { AppContext } from 'app/App';
import { RouteComponentProps } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grow from '@material-ui/core/Grow';
import TextField from 'components/TextField';
import { isEmail } from 'tools/utils';
import User from 'api/graphql/User';

import { useGlobalMessage } from 'hooks/useGlobalMessage';

type Keys = 'email' | 'password' | 'Error';
type State = { [K in Keys]: string };

interface RouteComponentPropsClient extends RouteComponentProps {
  client: any;
}

interface INotification {
  success: any;
  error: any;
}

const Signin = (props: RouteComponentPropsClient) => {
  const { app, dispatch } = React.useContext(AppContext);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [state, setState] = React.useState({} as State);
  const [error, setError] = React.useState({} as State);
  const tr = app.wording.translation;

  const notification: INotification = useGlobalMessage();

  const toggleRememberMe = () => setRememberMe(!rememberMe);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    setError({ ...error, [name]: '' });
  };

  const submit = () => {
    try {
      checkState();
      signin();
    } catch (err) {
      catchError(err);
    }
  };

  const signin = async () => {
    let api = new User(props.client);
    try {
      let res = await api.login(state);
      if (res.data.login.token) {
        localStorage.removeItem('token');
        dispatch({ type: 'setToken', payload: res.data.login.token });
        notification.success(tr.logged);
        if (rememberMe) localStorage.setItem('token', res.data.login.token);
        props.history.push('/office');
      }
    } catch (err) {
      catchError(err);
    }
  };

  const checkState = () => {
    let error;
    if (!state.email) {
      error = new Error(tr.missingEmail);
      error.name = 'email';
      throw error;
    } else if (!isEmail(state.email)) {
      error = new Error(tr.badFormatEmail);
      error.name = 'email';
      throw error;
    } else if (!state.password) {
      error = new Error(tr.missingPassword);
      error.name = 'password';
      throw error;
    }
  };

  const catchError = (err: Error) => {
    const { name, message } = err;
    if (name === 'Error') {
      if (message === 'GraphQL error: Email and password do not match') {
        setError({ ...error, [name]: tr.passwordAndEmailNotMatch });
      } else if (message === 'Network error: Failed to fetch') {
        setError({ ...error, [name]: tr.connexionLost });
        notification.error(tr.connexionLost);
      }
    } else {
      setError({ ...error, [name]: message });
    }
  };

  const form = {
    email: {
      name: 'email',
      label: tr.email,
      onChange: onChange,
      error: !!error.email,
      helperText: error.email,
    },
    password: {
      name: 'password',
      label: tr.password,
      onChange: onChange,
      error: !!error.password,
      helperText: error.password,
    },
  };

  return (
    <Grow in={true}>
      <Box>
        <Card>
          <CardContent>
            <Typography variant="body1">{tr.signin}</Typography>
            <TextField {...form.email} />
            <TextField {...form.password} type="password" />
            <FormControlLabel
              label={tr.rememberMe}
              control={
                <Checkbox
                  checked={rememberMe}
                  value="remember"
                  color="primary"
                  onChange={toggleRememberMe}
                />
              }
            />
            <Button
              onClick={submit}
              variant="contained"
              color="primary"
              fullWidth={true}
            >
              {tr.signin}
            </Button>
            <Typography variant="subtitle2" color="secondary">
              {error.Error}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Grow>
  );
};

export default Signin;
