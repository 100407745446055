import Crud from './';

class Topics extends Crud {
  constructor(token) {
    super('/topics', token);
  }

  _getValues = async (id, params = null) => {
    return await this._fetch(`${this.path}/${id}/values`, 'GET', null, params);
  };
}

export default Topics;
