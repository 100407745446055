import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { _modCol } from 'tools/color';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;

  ${({ color, shadow, checked, theme, outlined }) => {
    if (outlined)
      return css`
        background: ${checked ? _modCol(theme['colors'][color], 70) : 'white'};
        ${HiddenCheckbox}:focus + & {
          box-shadow: 0 0 0 3px ${theme['colors'][color]}40;
        }
      `;
    return css`
      background: ${checked
        ? theme['colors'][color]
        : `${theme['colors'][color]}20`};
      ${HiddenCheckbox}:focus + & {
        box-shadow: 0 0 0 3px ${theme['colors'][color]}40;
      }
    `;
  }}

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const Checkbox = ({ checked, color, shadow, outlined, onChange }) => {
  const [ch] = useState(React.createRef());
  return (
    <CheckboxContainer>
      <HiddenCheckbox ref={ch} onChange={onChange} />
      <StyledCheckbox
        checked={checked}
        color={color}
        shadow={shadow}
        outlined={outlined}
        onClick={(e) => {
          ch.current.click();
          ch.current.focus();
        }}
      >
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

Checkbox.defaultProps = {
  onChange: (e) => console.log(e),
  color: 'primary',
  shadow: 1,
};

export default Checkbox;
