import React, { useState, useReducer, useEffect } from 'react';
import useWords from 'hooks/words';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Pagination from 'components/old/Pagination';
import Flex from 'components/old/Flex';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Subtitle from 'components/old/Subtitle';
import Typo from 'components/old/Typo';
import Loading from 'components/old/Loading';
import styled from 'styled-components';
import Row from 'components/old/Row';

const Values = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  flex-wrap: wrap;
  margin: 0px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme['colors']['primary']};
  box-shadow: ${({ theme }) => theme['shadows'][2]};
  background: ${({ background }) => background};
  align-items: initial;
  padding: 0.3rem;

  > :nth-child(1):after {
    position: absolute;
    top: 0;
    right: 50%;
    bottom: 0;
    content: '';
    height: 100%;
    width: 1px;
    background-color: ${({ theme }) => theme['colors']['primary']};
  }
  > :nth-child(2) {
    padding: 0.3rem;
  }
`;

const Text = styled.div`
  overflow: hidden;
  white-space: wrap;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const IconLayout = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: flex-end;
  justify-content: flex-end;
  /* flex: 1; */
  cursor: pointer;
  margin-left: 1rem;
`;

const IconMargin = styled.span`
  display: flex;
  margin-left: 5px;
  align-self: center;
`;

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setArray':
      return { ...state, array: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setSkip':
      return { ...state, skip: action.payload };
    case 'setInput':
      return { ...state, input: action.payload };
    default:
      return state;
  }
};

export default (props) => {
  const { g, p, value, setModification } = props;
  const { w, api } = g;
  const [tr] = useWords();
  const notification = useGlobalMessage();
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const [expr, setTitle] = useState(value.expression);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(_reducer, {
    array: [],
    skip: 0,
    take: 5,
    total: 0,
    input: '',
  });

  const deleteValue = async () => {
    const res = await api.Values._delete(value.id);
    if (res.message === `Deletion of 'value' with id '${value.id}' succeed !`) {
      g.dispatch({
        type: 'setSuccess',
        payload: w.deletionSuccess,
      });
      p.dispatch({
        type: 'setArray',
        payload: p.state.array.filter((o) => o.id !== value.id),
      });
      setModification(true);
      await props.callback();
    } else
      g.dispatch({
        type: 'setError',
        payload: w.error,
      });
  };

  useEffect(() => {
    const _getValues = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await api.Synonyms._get(null, {
        skip: state.skip,
        take: state.take,
        values: [value.id],
      });
      if (res) {
        dispatch({ type: 'setArray', payload: res.data });
        dispatch({ type: 'setTotal', payload: res.total });
      }
      dispatch({ type: 'setLoading', payload: false });
    };
    _getValues();
  }, [state.skip, state.take, api, value.id, reload]);

  const validateChange = async () => {
    if (expr === value.expression) {
      setEdit(false);
    } else if (
      p.state.array.some(
        (el) => el.expression === expr && expr !== value.expression
      )
    ) {
      notification.warning(tr.valueWithSameNameExists);
      setTitle(value.expression);
    } else {
      setLoading(true);
      const res = await api.Values._put(value.id, {
        topic: value.topic.id,
        expression: expr,
      });
      if (res) {
        p.dispatch({
          type: 'setValue',
          payload: { ...value, expression: expr },
        });
        setTitle('');
        setModification(true);
        setEdit(false);
      }
      setLoading(false);
      notification.success(tr.saved);
    }
  };

  return (
    <Values background={hover ? 'rgba(255,0,0,0.2)' : 'none'}>
      {loading && <Loading />}
      <Flex flexDirection="column" flex={1}>
        <Subtitle>{w.value}:</Subtitle>
        <Row justifyContent="space-between">
          {edit && (
            <Text>
              <Input
                defaultValue={value.expression}
                value={expr}
                onChange={(e) => setTitle(e.target.value)}
                onKeyPress={async (e) => {
                  if (e.key === 'Enter' && expr) {
                    validateChange();
                  } else if (e.key === 'Enter' && !expr) {
                    setEdit(false);
                  }
                }}
              />
            </Text>
          )}
          {!edit && (
            <Typo>
              {' '}
              <Text>{value.expression} </Text>
            </Typo>
          )}
          <IconLayout>
            {!edit ? (
              <EditRoundedIcon fontSize="small" onClick={() => setEdit(true)} />
            ) : (
              <CheckRoundedIcon
                fontSize="small"
                onClick={async (e) => {
                  if (expr) {
                    validateChange();
                  } else if (!expr) setEdit(false);
                }}
              />
            )}
            <IconMargin>
              <DeleteRoundedIcon
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                fontSize="small"
                color="secondary"
                onClick={async () => {
                  window.confirm(
                    'Are you sure you wish to delete this item?'
                  ) && deleteValue();
                }}
              />
            </IconMargin>
          </IconLayout>
        </Row>
      </Flex>
      <Flex flexDirection="column">
        <Row justifyContent="space-between">
          <Subtitle>{w.synonyms}:</Subtitle>
          <TextField
            size="small"
            variant="outlined"
            placeholder={w.addSynonym}
            value={state.input}
            onChange={(e) =>
              dispatch({ type: 'setInput', payload: e.target.value })
            }
            onKeyPress={async (e) => {
              if (
                e.key === 'Enter' &&
                state.array.some((el) => el.expression === state.input)
              ) {
                notification.warning(tr.synonymDuplicata);
              } else if (e.key === 'Enter' && state.input) {
                const res = await api.Synonyms._post({
                  expression: state.input,
                  value: value.id,
                });
                if (res.id) {
                  g.dispatch({
                    type: 'setSuccess',
                    payload: w.creationSuccess,
                  });
                  dispatch({
                    type: 'setArray',
                    payload: state.array.concat(res),
                  });
                  setModification(true);
                  await props.callback();
                  notification.success(tr.saved);
                } else if (res.code === 'ERR_WRONG_ENTITY_DATA') {
                  notification.error(tr.errWrongEntityData);
                } else {
                  g.dispatch({
                    type: 'setError',
                    payload: w.error,
                  });
                  notification.error(w.error);
                }
                dispatch({ type: 'setInput', payload: '' });
              }
            }}
          />
        </Row>
        {state.array.map((syn) => (
          <Text>
            <Expression
              {...props}
              key={syn.id}
              state={state}
              dispatch={dispatch}
              reload={reload}
              setReload={setReload}
              setLoading={setLoading}
              notification={notification}
              tr={tr}
              el={syn}
            />
          </Text>
        ))}
        <Pagination
          activePage={(state.skip + state.take) / state.take}
          itemsCountPerPage={state.take}
          totalItemsCount={state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            dispatch({ type: 'setSkip', payload: (page - 1) * state.take });
          }}
        />
      </Flex>
    </Values>
  );
};

const Expression = (props) => {
  const {
    g,
    el,
    state,
    dispatch,
    reload,
    setReload,
    setLoading,
    notification,
    tr,
    setModification,
  } = props;
  const {
    w,
    api: { Synonyms },
  } = g;
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const [expr, setTitle] = useState(el.expression);

  const validateChange = async () => {
    if (expr === el.expression) {
      setEdit(false);
    } else if (
      state.array.some(
        (syn) => syn.expression === expr && expr !== el.expression
      )
    ) {
      notification.warning(tr.synonymWithSameNameExists);
      setTitle(el.expression);
    } else {
      setLoading(true);
      const res = await Synonyms._put(el.id, {
        parent: el.parent.id,
        expression: expr,
      });
      if (res) {
        dispatch({ type: 'setSynonym', payload: expr });
        setReload(!reload);
        setTitle('');
        setModification(true);
        setEdit(false);
      }
      setLoading(false);
      notification.success(tr.saved);
    }
  };

  return (
    <Row
      justifyContent="space-between"
      background={hover ? 'rgba(255,0,0,0.2)' : 'none'}
    >
      {!edit ? (
        <Typo width="100%">- {el.expression}</Typo>
      ) : (
        <Input
          defaultValue={el.expression}
          value={expr}
          onChange={(e) => setTitle(e.target.value)}
          onKeyPress={async (e) => {
            if (e.key === 'Enter' && expr) {
              validateChange();
            } else if (e.key === 'Enter' && !expr) {
              setEdit(false);
            }
          }}
        />
      )}
      <IconLayout>
        {!edit ? (
          <EditRoundedIcon fontSize="small" onClick={() => setEdit(true)} />
        ) : (
          <CheckRoundedIcon
            onClick={async (e) => {
              if (expr) {
                validateChange();
              } else if (!expr) {
                setEdit(false);
              }
            }}
          />
        )}
        <IconMargin>
          <ClearRoundedIcon
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            color="secondary"
            fontSize="small"
            onClick={async () => {
              const res = await Synonyms._delete(el.id);
              if (
                res.message ===
                `Deletion of 'synonym' with id '${el.id}' succeed !`
              ) {
                g.dispatch({
                  type: 'setSuccess',
                  payload: w.deletionSuccess,
                });
                dispatch({
                  type: 'setArray',
                  payload: state.array.filter((o) => o.id !== el.id),
                });
                setModification(true);
              } else
                g.dispatch({
                  type: 'setError',
                  payload: w.error,
                });
            }}
          />
        </IconMargin>
      </IconLayout>
    </Row>
  );
};
