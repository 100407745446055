import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';

interface TypoProps {
  readonly transform?: 'capitalize' | 'uppercase' | 'lowercase';
  readonly fontWeight?: 'lighter' | 'normal' | 'bold';
}

const StyledTypo = styled(Typography)<TypoProps>`
  ${({ transform, fontWeight }) => {
    return css`
      text-transform: ${transform};
      font-weight: ${fontWeight};
    `;
  }};
`;

export default StyledTypo;
