import React from 'react';
import styled from 'styled-components';
import { withGlobal } from 'context/';
import media from 'styles/styled/media';
import Sidebar from './model/Ui';
import { Switch, Route, withRouter } from 'react-router-dom';

import Intents from './model/Intents';
import Intent from './model/Intent';
import Entities from './model/Entities';
import Parameters from './model/Parameters';
import Appellations from './model/Appellations';
import Test from './model/Test';
import TrainingRasa from './model/TrainingRasa';

const Model = styled.div`
  display: flex;
  flex-direction: row;
  height: ${(p) => (p.height ? `${p.height - 100}px` : '80%')};
  ${media.tablet`
    margin: 10px;
  `}
  ${media.phone`
    margin: 5px;
  `}
`;

export default withGlobal(
  withRouter((props) => {
    const {
      g: {
        api: { Models },
      },
      match: {
        params: { id },
      },
      height,
    } = props;

    const [model, setModel] = React.useState({
      id: null,
      name: '',
      language: {},
      isSharable: false,
    });

    React.useEffect(() => {
      const getModel = async () => {
        const res = await Models._getById(id);
        if (res && !res.error) {
          setModel(res);
        }
      };
      getModel();
    }, [id, Models]);

    return (
      <Model height={height}>
        <Sidebar {...props} model={model} />
        <Switch>
          <Route path="/office/model/:id/intents/:code" component={Intent} />
          <Route
            path="/office/model/:id/intents"
            render={() => <Intents {...props} model={model} />}
          />
          <Route path="/office/model/:id/entities" component={Entities} />
          <Route
            path="/office/model/:id/appellations"
            component={Appellations}
          />
          <Route
            path="/office/model/:id/parameters"
            render={() => <Parameters {...props} model={model} />}
          />
          <Route
            path="/office/model/:id/rasa-training"
            render={() => (
              <TrainingRasa {...props} model={model} setModel={setModel} />
            )}
          />
          <Route
            path="/office/model/:id/test"
            render={() => <Test {...props} model={model} />}
          />
        </Switch>
      </Model>
    );
  })
);
