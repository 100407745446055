import desk from './desk';
import office from './office';
import errors from './errors';
import success from './success';
import navigation from './navigation';
import intents from './intents';
import intent from './intent';
import entities from './entities';
import info from './info';
import testpanel from './testpanel';
import warning from './warning';
import legend from './legend';
import appellations from './appellations';
import training from './training';

type words = {
  [key: string]: string;
};

type Translations = {
  [key: string]: words;
};

const translations: Translations = {
  fr_FR: {
    ...desk['fr_FR'],
    ...errors['fr_FR'],
    ...success['fr_FR'],
    ...navigation['fr_FR'],
    ...office['fr_FR'],
    ...intents['fr_FR'],
    ...intent['fr_FR'],
    ...entities['fr_FR'],
    ...appellations['fr_FR'],
    ...info['fr_FR'],
    ...testpanel['fr_FR'],
    ...warning['fr_FR'],
    ...legend['fr_FR'],
    ...training['fr_FR'],
  },
  en_EN: {
    ...desk['en_EN'],
    ...errors['en_EN'],
    ...success['en_EN'],
    ...navigation['en_EN'],
    ...office['en_EN'],
    ...intents['en_EN'],
    ...intent['en_EN'],
    ...entities['en_EN'],
    ...appellations['en_EN'],
    ...info['en_EN'],
    ...testpanel['en_EN'],
    ...warning['en_EN'],
    ...legend['en_EN'],
    ...training['en_EN'],
  },
};

class Wording {
  private lang: string;
  constructor(lang: string) {
    this.lang = lang;
  }

  get translation() {
    return translations[this.lang];
  }

  get language() {
    return this.lang;
  }

  set language(lang) {
    this.lang = lang;
  }
}

export default Wording;
