import React from 'react';
import { AppContext } from 'app/App';
import Api from 'api/rest/';
import usePagination from 'hooks/pagination';
import { groupModelsByName } from 'tools/utils';

export type Model = {
  name: string;
  [key: string]: any;
};

type Data = {
  name: string;
  isSharable: boolean;
  referenceBotId: number | null;
  language: number;
};

export type Models = Array<Model>;

export type Methods = {
  createModel: (data: Data) => Promise<any>;
  deleteModel: (id: number) => Promise<any>;
  addModel: (model: any) => void;
  removeModel: (model: any) => void;
};

const useModels = (search: string) => {
  const { app } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error>();
  const [api, setApi] = React.useState<any>();
  const [models, setModels] = React.useState<Models>();
  const [total, setTotal] = React.useState<number>();
  const { pagination, pagination_api } = usePagination(20, total);

  React.useEffect(() => {
    const getModels = async () => {
      let api = new Api(`${app.mlApiUrl}/models`, app.token);
      let params = {
        skip: pagination.skip.toString(),
        take: pagination.take.toString(),
        name: search,
      };
      try {
        let res = await api.get(null, params);
        let models = groupModelsByName(res.data);
        setApi(api);
        setModels(models);
        setTotal(res.total);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    getModels();
  }, [
    pagination.skip,
    pagination.take,
    search,
    setTotal,
    app.mlApiUrl,
    app.token,
  ]);

  const createModel = async (data: Data) => {
    return await api.post(data);
  };

  const deleteModel = async (id: number) => {
    return await api.delete(id);
  };

  let modelsArray: Model[] = [];

  if (models) {
    modelsArray = models;
  }

  const addModel = (model: Model) => {
    let newModels = modelsArray.concat(model);
    setModels(newModels);
  };

  const removeModel = (model: Model) => {
    let newModels = modelsArray.map((mod: any) => {
      if (mod.name === model.name) {
        let newMod = { ...mod };
        delete newMod[model.lang];
        return newMod;
      } else return mod;
    });
    setModels(newModels);
  };

  const models_api: Methods = {
    createModel,
    deleteModel,
    addModel,
    removeModel,
  };

  const data = {
    models,
    models_api,
    pagination,
    pagination_api,
  };

  return { loading, error, data };
};

export default useModels;
