import React from 'react';

import {
  Icon,
  List,
  FirstText,
  SecondText,
  Underline,
  Padding,
} from '../layout/Layout';

import SuccessIcon from 'icons/SuccessIcon';

const LieuxResult = ({ data, type = 'lieux', tr }) => {
  const dataArray = Object.values(data(type));

  return (
    <span>
      <Icon>
        <SuccessIcon width="17" />
      </Icon>
      {tr.testResult}
      <List>
        <FirstText>
          {dataArray.length > 0 ? (
            <Underline>{tr.addressDetected}</Underline>
          ) : (
            <Underline>{tr.addressesDetected}</Underline>
          )}
          :{' '}
          <SecondText>
            <Padding padding="0.5rem 0rem 0.3rem">
              {dataArray.map((el, i) =>
                el[0] ? (
                  `- "${el[0]}"`
                ) : (
                  <div key={i}>{tr.resultNotYetTreatable} </div>
                )
              )}
            </Padding>
          </SecondText>
        </FirstText>
      </List>
    </span>
  );
};

export default LieuxResult;
