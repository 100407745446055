const intent = {
  fr_FR: {
    deletedFromModel: "L'intent a été supprimé du modèle",
    intentDeleted: "L'intent a été définitivement supprimé",
    deletingIntentFromModel:
      "L'intent sera conservé dans le monolith, voulez-vous le supprimer du modèle actuel ? ",
    deletingPermanently:
      "L'intent sera définitivement supprimé, voulez-vous poursuivre ? ",
    addingSelectionConfirmation:
      'Voulez-vous ajouter la sélection en tant que valeur ou synonyme ?',
    addingValueError: "La valeur n'a pas pu être ajoutée",
    addingSynonymError: "Le synonyme n'a pas pu être ajouté",
    addSelectionTitle: 'Ajouter une Valeur ou Synonyme :',
    selection: 'Séléction :',
    chooseEntitie: 'Choisissez une Entitie : ',
    entitiePlaceholder: 'Entitie',
    chooseValue: 'Choisissez une Valeur : ',
    valuePlaceholder: 'Valeur',
  },
  en_EN: {
    deletedFromModel: 'Intent was removed from model',
    intentDeleted: 'Intent was definitively removed',
    deletingIntentFromModel:
      'Intent will remain in monolith, do you want to remove it from actual model ? ',
    deletingPermanently:
      'Intent will be definitively removed, do you want to continue ?',
    addingSelectionConfirmation:
      'Do you want to add the selection as a value or synonym ?',
    addingValueError: 'The value could not be added',
    addingSynonymError: 'The synonym coule not be added',
    addSelectionTitle: 'Add a new Value or Synonym :',
    selection: 'Selection :',
    chooseEntitie: 'Choose an Entitie : ',
    entitiePlaceholder: 'Entitie',
    chooseValue: 'Choose a Value : ',
    valuePlaceholder: 'Value',
  },
};

export default intent;
