import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { AppContext } from 'app/App';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { Spacing } from 'components/Layout';

const NavigationDesktop = () => {
  const { app } = React.useContext(AppContext);
  let tr = app.wording.translation;
  const features = [
    { path: '/office/models', title: 'models' },
    { path: '/office/training', title: 'training' },
  ];

  return (
    <React.Fragment>
      {features.map((nav, i) => {
        return (
          <Spacing
            key={i}
            left={i === 0 ? '40px' : '15px'}
            right="0"
            top="0"
            bottom="0"
          >
            <Slide
              in={true}
              direction="down"
              mountOnEnter
              unmountOnExit
              style={{ transitionDelay: `${i * 100}ms` }}
            >
              <Button component={Link} to={nav['path']}>
                {tr[nav['title']]}
              </Button>
            </Slide>
          </Spacing>
        );
      })}
    </React.Fragment>
  );
};

export default NavigationDesktop;

const StyledNavLink = styled(NavLink)`
  &.MuiButton-root {
    margin: ${({ theme }) => `0px ${theme.spacing}px`};
    border-radius: 0px;
  }
  > .MuiButton-label {
    color: white;
    text-transform: capitalize;
  }
  &.active {
    border-bottom: 1px solid white;
  }
`;

const Link = React.forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  // @ts-ignore
  <StyledNavLink innerRef={ref} {...props} />
));
