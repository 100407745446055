import React, { useState, useEffect } from "react";
import useWords from "hooks/words";
import { useGlobalMessage } from "hooks/useGlobalMessage";
import useIntents from "api/rest/hooks/useIntents";
import useTrainingPhrases from "api/rest/hooks/useTrainingPhrases";

import styled from "styled-components";
import Typo from "components/old/Typo";
import Modal from "components/old/Modal";
import Loading from "components/old/Loading";
import Button from "components/old/Button";
import Flex from "components/old/Flex";
import Header from "components/old/Header";
import Title from "components/old/Title";
import { ClickIcon } from "components/old/Icon";
import { Spacing } from "components/Layout";
import Table, { TBody, TRow, THead, TCell, Board } from "components/old/Table";
import TextField from "@material-ui/core/TextField";
import NativeSelect from "@material-ui/core/NativeSelect";
import Api from "api/rest";
import { AppContext } from "app/App";

const Scrollable = styled.div`
  display: inline-block;
  height: ${(p) => (p.height ? `${p.height - 250}px` : "500px")};
  overflow: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const EmptyData = styled.div`
  text-align: center;
  font-size: 1.8em;
  margin: 5rem;
`;

const StyledScore = styled.div`
  margin-bottom: 1rem;
  font-size: 14px;
  > span {
    font-weight: 800;
    font-size: 16px;
  }
`;

const Content = (props) => {
  const { loading, error, data } = useIntents();
  const {
    loading: phrasesLoading,
    error: phrasesError,
    data: phrasesData,
  } = useTrainingPhrases();

  const notification = useGlobalMessage();
  const [tr] = useWords();

  const {
    g: { w },
    readingLogs,
    detectBottom,
    scrollLoad,
    height,
  } = props;
  // const [logs, setLogs] = useState(readingLogs.readingLogs);

  useEffect(() => {
    if (props.error || error || phrasesError)
      notification.error(
        `${props.error ? "Logs" : error ? "Intents" : "Training Phrases"} : ${
          tr.failedToFetch
        }`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error, error]);

  return (
    <>
      {props.loading || loading || phrasesLoading ? (
        <Loading />
      ) : (
        <Table layout="auto" margin="0px 0px 50px">
          <TBody>
            <Scrollable onScroll={detectBottom} height={height}>
              <TRow>
                <THead width> {w.sentence} </THead>
                <THead align="center"> {w.model} </THead>
                <THead align="center"> {w.match} </THead>
                <THead align="end"> {w.options} </THead>
              </TRow>

              {readingLogs && readingLogs.length !== 0 ? (
                readingLogs.map((m) => {
                  return (
                    <Sentence
                      intents={data}
                      key={m.keys}
                      sentence={m}
                      {...props}
                      phrasesData={phrasesData}
                      notification={notification}
                      tr={tr}
                      readingLogs={readingLogs}
                    />
                  );
                })
              ) : (
                <EmptyData>{tr.noSearchResult}</EmptyData>
              )}
              {scrollLoad && <Loading color />}
            </Scrollable>
          </TBody>
        </Table>
      )}
    </>
  );
};

const deleteReadingLog = async (
  api,
  keys,
  update,
  notification,
  tr,
  setUpdate
) => {
  const res = await api.delete(keys);
  if (res) {
    setUpdate(!update);
  } else {
    notification.error(tr.err);
  }
};

const deleteLog = async (
  fromValidate,
  logToSend,
  notification,
  tr,
  api,
  update,
  setUpdate
) => {
  try {
    deleteReadingLog(api, logToSend.keys, update, notification, tr, setUpdate);
    if (!fromValidate) notification.success(tr.deleted);
  } catch (err) {
    notification.error(tr.error);
  }
};

const createTrainingPhrases = async (
  apiTraining,
  value,
  intent,
  notification,
  tr
) => {
  const res = await apiTraining.post({
    sentence: value.replace(/[{}]/gi, ""),
    intent: Number(intent),
  });
  if (res.id) {
    notification.success(tr.trainingPhraseAdded);
    return true;
  }
  return false;
};

const validateLog = async (
  logToSend,
  apiTraining,
  notification,
  tr,
  api,
  update,
  setUpdate
) => {
  try {
    const res = await createTrainingPhrases(
      apiTraining,
      logToSend.sentence,
      logToSend.intent.id,
      notification,
      tr
    );
    if (!res) notification.warning(tr.couldNotAddTrainingPhraseDouble);
    await deleteLog(true, logToSend, notification, tr, api, update, setUpdate);
  } catch (err) {
    notification.error(tr.couldNotAddTrainingPhrase);
  }
};

const Sentence = (props) => {
  const { sentence, notification, tr, setUpdate, update } = props;

  const [logToSend, setLogToSend] = useState(sentence);
  const [preview, setPreview] = useState(false);
  const close = () => {
    setPreview(false);
  };

  const { app } = React.useContext(AppContext);
  let api = new Api(`${app.mlApiUrl}/reading_logs`, app.token);
  let apiTraining = new Api(`${app.mlApiUrl}/training_phrases`, app.token);

  return (
    <TRow style={{ color: logToSend.score < 0.7 ? "#E66E6E" : "#2D2F7B" }}>
      <Logs
        isOpen={preview}
        close={close}
        log={logToSend}
        setLog={setLogToSend}
        {...props}
      />
      <TCell onClick={() => setPreview(!preview)}>
        {logToSend && logToSend.sentence}
      </TCell>
      <TCell align="center" onClick={() => setPreview(!preview)}>
        <Typo fontWeight="600">
          {logToSend && logToSend.model && logToSend.model.name}
        </Typo>
      </TCell>
      <TCell align="center" onClick={() => setPreview(!preview)}>
        <Typo fontWeight="600">
          {logToSend && logToSend.intent && logToSend.intent.title}
        </Typo>
      </TCell>
      <TCell>
        <Board>
          <ClickIcon
            label="edit"
            icon="fas fa-check"
            color="success"
            onClick={() =>
              validateLog(
                logToSend,
                apiTraining,
                notification,
                tr,
                api,
                update,
                setUpdate
              )
            }
          />
          <ClickIcon
            label="edit"
            icon="fas fa-pencil-alt"
            onClick={() => setPreview(!preview)}
          />
          <ClickIcon
            label="delete"
            color="secondary"
            icon="fas fa-trash-alt"
            onClick={() =>
              deleteLog(
                false,
                logToSend,
                notification,
                tr,
                api,
                update,
                setUpdate
              )
            }
          />
        </Board>
      </TCell>
    </TRow>
  );
};

const Head = styled.div`
  background-color: ${(p) => p.theme["colors"]["primary"]};
  position: sticky;
  top: 0;
  width: 100%;
  color: white;
  padding: 10px;
  padding-left: 25px;
  font-weight: 700;
  text-transform: capitalize;
`;

const Body = styled.div`
  padding: 25px;
`;

const TextArea = styled.div`
  width: 100%;
`;

const Logs = (props) => {
  const {
    isOpen,
    close,
    g: { w },
    log,
    setLog,
    intents,
  } = props;
  const [tr] = useWords();
  const [text, setText] = useState(log.sentence);
  const [intent, setIntent] = useState(log.intent);

  const { app } = React.useContext(AppContext);
  let api = new Api(`${app.mlApiUrl}/reading_logs`, app.token);
  let apiTraining = new Api(`${app.mlApiUrl}/training_phrases`, app.token);

  const confirm = async () => {
    await setLog((prevState) => ({
      ...prevState,
      sentence: text,
      intent: intent,
    }));

    validateLog(
      log,
      apiTraining,
      props.notification,
      tr,
      api,
      props.update,
      props.setUpdate
    );
    close();
  };

  const idModel = log.model.id;
  let arr = [];

  intents.intents.forEach((el) =>
    // eslint-disable-next-line
    el.models.find((e) => {
      if (e.id === idModel) {
        return arr.push(el);
      }
    })
  );
  const newListsUnique = [...new Set([...arr, ...intents.intents])];

  return (
    <Modal
      padding="1px"
      borderRadius="0px"
      isOpen={isOpen}
      close={close}
      selector="#root"
    >
      <Head>{tr.modify}</Head>
      <Body>
        <Header>
          <Title textTransform="capitalize">{w.userMsg}</Title>
        </Header>
        <TextArea>
          <TextField
            id="sentence"
            value={text}
            fullWidth
            multiline
            onChange={(e) => setText(e.target.value)}
            inputProps={{
              "aria-label": "sentence",
            }}
            rows={2}
            variant="outlined"
          />
        </TextArea>
        <Spacing top="10px">
          <Table noBorder={true}>
            <TBody>
              <TRow noBorder={true}>
                <TCell padding="10px 10px 10px 0px">
                  {`${w.detectModel}: `}
                  <Typo margin="0px 0px 0px 10px" fontWeight="600">
                    {log && log.model && log.model.name}
                  </Typo>
                </TCell>
                <TCell>
                  {`${w.detectIntent}: `}
                  <Typo margin="0px 0px 0px 10px" fontWeight="600">
                    {log && log.intent && log.intent.title}
                  </Typo>
                </TCell>
                <TCell>
                  {`${w.trust}: `}
                  <Typo margin="0px 0px 0px 10px" fontWeight="600">{`${
                    log && log.trust ? log.trust : ""
                  }%`}</Typo>
                </TCell>
              </TRow>
            </TBody>
          </Table>
        </Spacing>
        <StyledScore>
          Score: <span>{log.score.toFixed(2)}</span>
        </StyledScore>
        <Header>
          <Title textTransform="capitalize">{w.addIntent}</Title>
        </Header>
        <Spacing bottom="40px">
          <NativeSelect
            value={intent ? intent.id : ""}
            onChange={async (e) => {
              const filteredIntent = intents.intents.filter(
                (el) => el.id === parseInt(e.target.value)
              );
              setIntent(filteredIntent[0]);
            }}
          >
            <option value="" disabled>
              {w.select}
            </option>
            {newListsUnique &&
              newListsUnique.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.title}
                </option>
              ))}
          </NativeSelect>
        </Spacing>
        <Flex justifyContent="space-between" margin="10px 0px;">
          <Button color="secondary" onClick={close}>
            {w.cancel}
          </Button>
          <Button onClick={confirm}>{w.validate}</Button>
        </Flex>
      </Body>
    </Modal>
  );
};

export default Content;
