import React from 'react';
import styled from 'styled-components';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import useWords from 'hooks/words';
import Header from 'components/old/Header';
import TextField from '@material-ui/core/TextField';
import { Spacing } from 'components/Layout';
import Button from 'components/old/Button';
import Title from 'components/old/Title';
import Typo from 'components/old/Typo';
import Loading from 'components/old/Loading';
import Modal from 'components/old/Modal';
import Flex from 'components/old/Flex';

const Legend = styled.div`
  display: flex;
  margin: 1rem 0.5rem 1rem;
  padding-bottom: 0.1rem;
  justify-content: flex-start;
  border-bottom: 1px solid #eee;
`;

const StarIcon = styled.div`
  margin-top: 1px;
`;

const SearchAndImport = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex: 1;
`;

const Scrollable = styled.div`
  max-height: 90vh;
  overflow: scroll;
`;

export const Head = (props) => {
  const {
    g: { w },
    state: {
      intents: { selected },
    },
    dispatch,
    setSearch,
  } = props;

  const [tr] = useWords();

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <>
      <Header padding={'1rem 1rem 0 0'}>
        <Title>{w.intentSelect}</Title>
        <SearchAndImport>
          <Spacing right="5px">
            <TextField
              variant="outlined"
              size="small"
              type="search"
              placeholder={`${w.Search}`}
              onChange={(e) => handleSearch(e)}
            />
          </Spacing>
          <Button
            color="secondary"
            onClick={() => {
              if (selected.length) dispatch({ type: 'setAdd', payload: true });
            }}
          >
            <Typo textTransform="uppercase">{w.add}</Typo>
          </Button>
        </SearchAndImport>
      </Header>
      <Legend>
        {' '}
        <StarIcon>
          <GradeRoundedIcon color="secondary" style={{ fontSize: 15 }} />
        </StarIcon>
        {` : ${tr.monolithModelOrIntent}`}
      </Legend>
    </>
  );
};
export const OnAdd = (props) => {
  const {
    g: { w },
    items,
    isOpen,
    loading,
    close,
    onAdd,
  } = props;
  return (
    <Modal small isOpen={isOpen} close={close} selector="#root">
      {loading && <Loading />}
      <Scrollable>
        <Header>
          <Title>{w.modelsAddIntents}</Title>
        </Header>
        <Flex flexDirection="column">
          {items &&
            items.map((el) => (
              <Typo color="secondary" key={el.id}>
                - {el.title}
              </Typo>
            ))}
        </Flex>
        <Flex justifyContent="space-between" margin="10px 0px 0px 0px">
          <Button onClick={close}>{w.cancel}</Button>
          <Button color="secondary" onClick={onAdd}>
            {w.confirm}
          </Button>
        </Flex>
      </Scrollable>
    </Modal>
  );
};
