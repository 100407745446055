import ApolloClient, {
  HttpLink,
  InMemoryCache,
  ApolloClient as CustomClient,
} from 'apollo-boost';
import { ApolloLink } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import User from './User';
import Bot from './Bot';

interface IdGetterObjExtended extends Object {
  __typename?: string;
  id?: string;
  key?: string;
}

class Client {
  apiUrl: string;
  token?: string;

  constructor(apiUrl: string, token?: string) {
    this.apiUrl = `${apiUrl}/graphql`;
    this.token = token;
  }

  links = () => {
    let retryOptions = {
      delay: {
        initial: 500,
        max: 2000,
        jitter: true,
      },
    };
    let retryLink = new RetryLink(retryOptions);
    let httpLink = new HttpLink({ uri: this.apiUrl });
    let middleLink = new ApolloLink((req, next) => {
      req.setContext({
        headers: {
          authorization: `Bearer ${this.token}`,
        },
      });
      return next(req);
    });
    let links = ApolloLink.from([retryLink, middleLink.concat(httpLink)]);
    return links;
  };

  cache = () => {
    let cache = new InMemoryCache({
      dataIdFromObject: (object: IdGetterObjExtended) => object.key,
    });
    return cache;
  };

  options = () => {
    const options = {};
    return options;
  };

  get client() {
    if (!this.token)
      return new ApolloClient({
        uri: this.apiUrl,
      });
    else
      return new CustomClient({
        link: this.links(),
        cache: this.cache(),
        defaultOptions: this.options(),
      });
  }

  get User() {
    return new User(this.client);
  }

  get Bot() {
    return new Bot(this.client);
  }
}

export default Client;
