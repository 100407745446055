import React from 'react';
import { Card, CardTitle, Data, Icon } from '../layout/Layout';

import InfoIcon from 'icons/InfoIcon';

const ComingSoonCard = ({ tr, color, title, result }) => {
  return (
    <Card color={color}>
      <CardTitle>{title}</CardTitle>
      <Data>
        {Object.keys(result).length > 0 && (
          <span>
            <Icon>
              <InfoIcon width="17" />
            </Icon>
            {tr.comingSoon}
          </span>
        )}
      </Data>
    </Card>
  );
};

export default ComingSoonCard;
