import React from 'react';
import { _modCol } from 'tools/color';
import styled, { css } from 'styled-components';

const Click = styled.button`
  display: flex;
  cursor: pointer;
  position: ${({ inBox }) => inBox && 'absolute'};
  top: ${({ inBox }) => inBox && '0'};
  right: ${({ inBox }) => inBox && '0'};
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: 0.2s;
  outline: none;
  border: none;
  width: ${({ size }) => size + 12}px;
  height: ${({ size }) => size + 12}px;
  color: ${({ theme, color }) => theme['colors'][color]};
  &:active {
    background-color: #ccc;
    box-shadow: ${({ theme }) => theme['shadows'][1]};
    color: ${({ theme, color }) => _modCol(theme['colors'][color], -10)};
  }
  &:focus {
    border: 2px solid ${({ theme, color }) => theme['colors'][color]};
  }

  ${({ bordered, squared, theme, color }) => css`
    border: ${bordered ? `2px solid ${theme['colors'][color]}` : 'none'};
    border-radius: ${squared ? '5px' : '50%'};
  `}
`;

Click.defaultProps = {
  color: 'primary',
  size: 18,
};

const Icon = styled.span`
  margin: 0 5px;
`;

export default ({ icon }) => {
  return (
    <Icon>
      <i className={icon}></i>
    </Icon>
  );
};

export const ClickIcon = ({
  onClick,
  label,
  icon,
  color,
  size,
  material = false,
  children,
  inBox,
  ...other
}) => {
  return (
    <Click
      onClick={onClick}
      color={color}
      size={size}
      aria-label={label}
      inBox={inBox}
      {...other}
    >
      {material ? children : <i className={icon}></i>}
    </Click>
  );
};
