import React, { createContext, useContext, useReducer } from 'react';
import { messageReducer, messageInitialState } from './message';
import { testPanelReducer, testPanelInitialState } from './testpanel';
import {
  redirectLottieReducer,
  redirectLottieInitialState,
} from './redirectlottie';
import { paginationReducer, paginationInitialState } from './pagination';
import { searchReducer, searchInitialState } from './search';
import { reloadReducer, reloadInitialState } from './reload';

// React context for store

const StoreContext = createContext();

// Combine initial states

const store = {
  message: messageInitialState,
  testPanel: testPanelInitialState,
  redirectLottie: redirectLottieInitialState,
  pagination: paginationInitialState,
  search: searchInitialState,
  reload: reloadInitialState,
};

// Combine reducers

const reducers = (store, action) => ({
  message: messageReducer(store.message, action),
  testPanel: testPanelReducer(store.testPanel, action),
  redirectLottie: redirectLottieReducer(store.redirectLottie, action),
  pagination: paginationReducer(store.pagination, action),
  search: searchReducer(store.search, action),
  reload: reloadReducer(store.reload, action),
});

// Store context provider

export const StoreProvider = ({ children }) => (
  <StoreContext.Provider value={useReducer(reducers, store)}>
    {children}
  </StoreContext.Provider>
);

// React hook for consuming store

export const useStore = () => useContext(StoreContext);
