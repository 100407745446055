import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import SuccessIcon from 'icons/SuccessIcon';
import InfoIcon from 'icons/InfoIcon';
import WarningIcon from 'icons/WarningIcon';
import ErrorIcon from 'icons/ErrorIcon';
import CloseIcon from 'icons/CloseIcon';

import { useStore } from 'store/store';
import { CLEAR_MESSAGE, allMessageTypes } from 'store/message';

const fade = keyframes`
  from {
    top: 15px;
    right: -60px;
    opacity: 0;
  }
  to {
    top: 15px;
    right: 15px;
    opacity: 1;
  }
`;

// Default styles for message

const Root = styled.div`
  position: fixed;
  min-width: 20rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 8px 0px;
  padding: 20px;
  z-index: 10000000000000000000000000000000000000000000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.color && p.color};
  animation: ${fade} 0.3s ease-out forwards;
  color: white;
`;

const Spacing = styled.div`
  ${(p) => p.top && `margin-top: ${p.top}`};
  ${(p) => p.right && `margin-right: ${p.right}`};
  ${(p) => p.bottom && `margin-bottom: ${p.bottom}`};
  ${(p) => p.left && `margin-left: ${p.left}`};
`;

const Button = styled.button`
  letter-spacing: 0.5px;
  outline: 0;
  transition: opacity 0.1s;
  border: 0;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  white-space: nowrap;
  align-self: flex-start;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  text-align: left;
`;

const Close = styled(Button)`
  position: absolute;
  right: 20px;
  top: 24px;
`;

// Displays global message as feedback in response to user operations

const Message = ({ children, type, autoClose }) => {
  const [, dispatch] = useStore();

  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(() => {
        dispatch({ type: CLEAR_MESSAGE });
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, autoClose]);

  const Colors = {
    SUCCESS: '#34a853',
    INFO: '#4169E1',
    WARNING: '#FFB818',
    ERROR: '#f44336',
  };

  const MessageType = (type) => {
    const icons = {
      SUCCESS: <SuccessIcon />,
      INFO: <InfoIcon />,
      WARNING: <WarningIcon />,
      ERROR: <ErrorIcon />,
    };

    return icons[type];
  };

  return (
    <Root color={Colors[type]}>
      {MessageType(type)}
      <Spacing left={'0.3rem'} right={'2rem'}>
        {children}
      </Spacing>
      <Close onClick={() => dispatch({ type: CLEAR_MESSAGE })}>
        <CloseIcon color="white" />
      </Close>
    </Root>
  );
};

Message.defaultProps = {
  autoClose: true,
};

Message.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(allMessageTypes),
  autoClose: PropTypes.bool,
};

export default Message;
