import styled, { css } from 'styled-components';

const Typo = styled.span`
  ${({
    fontSize,
    width,
    fontWeight,
    color,
    textTransform,
    theme,
    background,
    margin,
    padding,
  }) => css`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    text-transform: ${textTransform};
    color: ${color ? theme['colors'][color] : 'inherit'};
    background-color: ${background ? background : 'none'};
    padding: ${padding};
    margin: ${margin};
    width: ${width};
  `}
`;

Typo.defaultProps = {
  fontSize: '1rem',
  fontWeight: 'normal',
  textTransform: 'none',
  padding: '0px',
  margin: '0px',
  width: 'auto',
};

export default Typo;
