// Actions types

export const SET_RESULT = 'SET_RESULT';
export const SET_SENTENCE = 'SET_SENTENCE';

export const CLEAR_TESTPANEL = 'CLEAR_TESTPANEL';

// Initial State

export const testPanelInitialState = {
  result: [],
  sentence: '',
};

// Message Reducer

export const testPanelReducer = (state = testPanelInitialState, action) => {
  switch (action.type) {
    case SET_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    case SET_SENTENCE:
      return {
        ...state,
        sentence: action.payload,
      };
    case CLEAR_TESTPANEL:
      return {
        ...state,
        ...testPanelInitialState,
      };
    default:
      return state;
  }
};
