import React from 'react';
import Loader from 'components/Loader';

const Loading = () => {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const loading_start = () => setLoading(true);
    const loading_stop = () => setLoading(false);
    window.addEventListener('loading_start', loading_start);
    window.addEventListener('loading_stop', loading_stop);
    return () => {
      window.removeEventListener('loading_start', loading_start);
      window.removeEventListener('loading_stop', loading_stop);
    };
  });

  if (!loading) return null;
  return <Loader absolute />;
};

export default Loading;
