import React from 'react';
import { AppContext } from 'app/App';
import Api from 'api/rest';
import usePagination from 'hooks/pagination';

import useWords from 'hooks/words';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import { useStore } from 'store/store';

type T_TrainingPhrase = {};

type State = {
  loading: boolean;
  error: any;
  api: any;
  total: number;
  trainingPhrases: any;
};

type Data = {
  sentence: string;
  intents?: string;
};

type Action =
  | { type: 'setLoading'; payload: boolean }
  | { type: 'setError'; payload: string }
  | { type: 'setTotal'; payload: number }
  | { type: 'setApi'; payload: any }
  | { type: 'setTrainingPhrases'; payload: any }
  | { type: 'setState'; payload: any };

const TrainingPhraseReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setError':
      return { ...state, error: action.payload };
    case 'setApi':
      return { ...state, api: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setTrainingPhrases':
      return { ...state, trainingPhrases: action.payload };
    case 'setState':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const useTrainingPhrases = (intentId?: number, entities?: any) => {
  const { app } = React.useContext(AppContext);
  const [state, dispatch] = React.useReducer(TrainingPhraseReducer, {
    loading: true,
    error: undefined,
    total: 0,
    api: undefined,
    trainingPhrases: [],
  });
  const { pagination, pagination_api } = usePagination(10, state.total);

  const [
    {
      reload: { selectionReload },
    },
  ] = useStore();

  const [tr] = useWords();
  const notification = useGlobalMessage();

  React.useEffect(() => {
    const formatValue = async () => {
      let tr = state.trainingPhrases;
      for (let i = 0, len = tr.length; i < len; i++) {
        let str = tr[i].sentence;
        try {
          let { values } = await state.api.getById(tr[i].id);

          for (let i = 0, len = values.length; i < len; i++) {
            if (!entities) break;

            let target = entities.find(
              (el: any) => el.id === values[i].topic.id
            );
            if (target === undefined) break;
            let reg = (e: number, array: any[]) =>
              new RegExp(`(\\{${array[e].expression}\\})`, 'ig');

            for (let j = 0; j < values.length; j++) {
              if (
                values[i].expression.includes(values[j].expression) &&
                values[j].expression !== values[i].expression
              ) {
                let str1 = str.replace(
                  reg(j, values),
                  `${values[j].expression}`
                );
                str = str1.replace(
                  reg(i, values),
                  `<span style="background-color:${target.color}">${values[i].expression}</span>`
                );
              } else {
                str = str.replace(
                  reg(i, values),
                  `<span style="background-color:${target.color}">${values[i].expression}</span>`
                );
              }
            }
          }
        } catch (err) {
          console.log(err);
        } finally {
          tr[i]['innerHtml'] = str;
        }
      }
      dispatch({ type: 'setTrainingPhrases', payload: tr });
    };
    formatValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.trainingPhrases]);

  React.useEffect(() => {
    const getTrainingPhrases = async () => {
      let api = new Api(`${app.mlApiUrl}/training_phrases`, app.token);
      let params = {
        skip: pagination.skip.toString(),
        take: pagination.take.toString(),
        intents: intentId || '',
      };
      try {
        let res = await api.get(null, params);
        let trainingPhrases = res.data.sort(
          (a: { id: number }, b: { id: number }) => b.id - a.id
        );
        dispatch({
          type: 'setState',
          payload: {
            trainingPhrases,
            api,
            total: res.total,
          },
        });
      } catch (err) {
        dispatch({ type: 'setError', payload: err });
      } finally {
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    getTrainingPhrases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.skip,
    pagination.take,
    app.mlApiUrl,
    app.token,
    intentId,
    selectionReload,
  ]);

  const createTrainingPhrases = async (value: string, intent?: any) => {
    const res = await state.api.post({
      sentence: value.replace(/[{}]/gi, ''),
      intent: Number(intent || intentId),
    });
    if (res.id) {
      notification.success(tr.trainingPhraseAdded);
      dispatch({
        type: 'setTrainingPhrases',
        payload: state.trainingPhrases
          .concat(res)
          .sort((a: { id: number }, b: { id: number }) => b.id - a.id),
      });
      return true;
    }
    return false;
  };

  const updateTrainingPhrases = async (id: number, value: string) => {
    const res = await state.api.put(id, {
      sentence: value.replace(/[{}]/gi, ''),
    });
    if (res.id) {
      notification.success(tr.saved);
      dispatch({
        type: 'setTrainingPhrases',
        payload: state.trainingPhrases.map((el: any) =>
          el.id === id ? { ...el, sentence: res.sentence } : el
        ),
      });
      return true;
    }
    return false;
  };

  const deleteTrainingPhrases = async (id: number) => {
    const res = await state.api.delete(id);
    if (res) {
      notification.success(tr.deleted);
      dispatch({
        type: 'setTrainingPhrases',
        payload: state.trainingPhrases.filter((el: any) => el.id !== id),
      });
    }
  };

  const fileImport = async (data: any) => {
    console.log(data);
  };

  const { loading, error, total, ...other } = state;

  const trainingPhrases_api = {
    createTrainingPhrases,
    updateTrainingPhrases,
    deleteTrainingPhrases,
    fileImport,
  };

  const data = {
    trainingPhrases: other.trainingPhrases,
    trainingPhrases_api,
    pagination,
    pagination_api,
  };

  return { loading, error, data, total };
};

export default useTrainingPhrases;
