import Schema from './Schema';
import gql from 'graphql-tag';

class Bot extends Schema {
  getBots = async () => await this.query(GET_BOTS);
}

const GET_BOTS = gql`
  query getBots {
    getBots {
      bots {
        id
        name
        features {
          id
          slug
        }
      }
    }
  }
`;
export default Bot;
