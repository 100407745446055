import Crud from './';

class Appellations extends Crud {
  constructor(token) {
    super('/appellations', token);
  }

  synchronize = async (id) => {
    return await this._fetch(`${this.path}/synchronize`, 'POST', { model: id });
  };
}

export default Appellations;
