const desk = {
  fr_FR: {
    signin: 'se connecter',
    signup: "s'enregistrer",
    rememberMe: 'se souvenir de moi',
    email: 'Adresse email',
    password: 'Mot de passe',
    forgetPassword: 'Mot de passe oublié',
  },
  en_EN: {
    signin: 'signin',
    signup: 'signup',
    rememberMe: 'remember me',
    email: 'Email address',
    password: 'Password',
    forgetPassword: 'Password forgotten',
  },
};

export default desk;
