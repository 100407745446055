const actions = {
  fr_FR: {
    archivedAppellations: 'Appellations archivées',
    noArchivedAppellations: 'Aucune appellation archivée pour le moment',
    noAppellations: 'Aucune appellations à afficher pour ce modèle',
  },
  en_EN: {
    archivedAppellations: 'Archived Appellations',
    noArchivedAppellations: 'No archived appellations for the moment',
    noAppellations: 'No appellations to be displayed for this model',
  },
};
export default actions;
