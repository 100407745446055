const training = {
  fr_FR: {
    titleTraining: 'Date du dernier entrainement complété',
    titleNoTraining: 'Aucun entrainement réalisé',
    trainingDate: 'L\'entrainement du modèle a été réalisé avec succès',
    starting: 'Démarrage de l\'entrainement du modèle',
    launch: 'Lancement du processus chatito',
    error: 'L\'entrainement à déjà été lancer',
    chatitoEnded: 'L\'entrainement chatito est terminé',
    ecsLaunch: 'Lancement de la tâche ECS',
    ecsCreated: 'Tache ECS créer',
    startingCSV: 'Démarrage du parseur CSV et JSON',
    errorProcess: 'Erreur pendant l\'entrainement',
    csvFinished: 'Parseur CSV et JSON terminé',
    instanceLaunch: 'Lancement de l\'entrainement instance',
    stepOneFinished: 'Première étape terminée',
  },
  en_EN: {
    titleTraining: 'Date of last completed training',
    titleNoTraining: 'No training performed',
    trainingDate: 'Model training successfully ended',
    starting: 'Starting model training',
    launch: 'Launching chatito process',
    error: 'The training for the model is already launched.',
    chatitoEnded: 'Chatito process ended',
    ecsLaunch: 'Launching ECS task',
    ecsCreated: 'ECS task created',
    startingCSV: 'Starting CSV and JSON parser',
    errorProcess: 'Erreur pendant l\'entrainement',
    csvFinished: 'CSV and JSON parser succesfully ended',
    instanceLaunch: 'Lauching training instance',
    stepOneFinished: 'Step one finished',
  },
};

export default training;
