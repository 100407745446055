import React from 'react';
import { AppContext } from 'app/App';
import Api from 'api/rest/';
import { Model } from 'api/rest/hooks/useModels';
import { groupModelsByName } from 'tools/utils';

export type Methods = {};

const useModel = (name: string, id?: number) => {
  const { app } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error>();
  const [model, setModel] = React.useState<Model>();
  const [languages, setLanguages] = React.useState<any[]>();

  React.useEffect(() => {
    const getModels = async () => {
      let api = new Api(`${app.mlApiUrl}/models`, app.token);
      let params = { name: name };
      try {
        let res = await api.get(null, params);
        let models = groupModelsByName(res.data);
        let model = models.find((m) => m.name === name);
        let languages = Object.keys(model);
        languages.shift();
        setModel(model);
        setLanguages(languages);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    getModels();
  }, [name, app.mlApiUrl, app.token]);

  const model_api: Methods = {};

  const data = {
    model,
    languages,
    model_api,
  };

  return { loading, error, data };
};

export default useModel;
