import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { BodyProps } from '../Body';
import Item from './content/Item';
import { Models } from 'api/rest/hooks/useModels';

const StyledContent = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing}px;
  overflow: auto;
`;

const Content = (props: BodyProps) => {
  const test = new Array(210);
  const [monolithModels, setMonolithModels] = useState<Models>([]);
  const [normalModels, setNormalModels] = useState<Models>([]);

  useEffect(() => {
    const monolithArray: Models = props.data.models.filter(
      (model) =>
        (model.en &&
          (!model.en.referenceBotId || model.en.referenceBotId === '0')) ||
        (model.fr &&
          (!model.fr.referenceBotId || model.fr.referenceBotId === '0'))
    );
    setMonolithModels(monolithArray);
    const normalArray: Models = props.data.models.filter(
      (model) =>
        (model.en &&
          model.en.referenceBotId &&
          model.en.referenceBotId !== '0') ||
        (model.fr && model.fr.referenceBotId && model.fr.referenceBotId !== '0')
    );
    setNormalModels(normalArray);
  }, [props.data.models]);

  test.fill(0);
  return (
    <StyledContent container justify="space-between" alignItems="center">
      {monolithModels &&
        monolithModels.map((model) => (
          <Item model={model} key={model.name} name={model.name} {...props} />
        ))}
      {normalModels &&
        normalModels.map((model) => (
          <Item model={model} key={model.name} name={model.name} {...props} />
        ))}
    </StyledContent>
  );
};

export default Content;
