import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

type SelectMultipleProps = {
  label?: string;
  items: any;
  value: any;
  onChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectMultiple = (props: SelectMultipleProps) => {
  return (
    <FormControl fullWidth>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        name={'languages'}
        multiple
        value={props.value}
        onChange={props.onChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {props.items.map((item: { key: number; value: any; name: string }) => (
          <MenuItem key={item.key} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectMultiple;
