// Actions types

export const SET_INTENTS_SKIP = 'SET_INTENTS_SKIP';
export const CLEAR_INTENTS_SKIP = 'CLEAR_INTENTS_SKIP';

// Initial State

export const paginationInitialState = {
  intentsSkip: 0,
};

// Message Reducer

export const paginationReducer = (state = paginationInitialState, action) => {
  switch (action.type) {
    case SET_INTENTS_SKIP:
      return {
        ...state,
        intentsSkip: action.payload,
      };
    case CLEAR_INTENTS_SKIP:
      return {
        ...state,
        ...paginationInitialState,
      };

    default:
      return state;
  }
};
