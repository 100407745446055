import React from 'react';
import { _randomHSL } from 'tools/color';
import { AppContext } from 'app/App';
import Api from 'api/rest';
import usePagination from 'hooks/pagination';
import { Params } from 'api/rest/types';

import { useStore } from 'store/store';
import useWords from 'hooks/words';
import { useGlobalMessage } from 'hooks/useGlobalMessage';

export type T_Topic = {
  id: number;
  title: string;
  intents: number[];
};

type Data = {
  title: string;
  intents?: number[];
};

const useEntities = (code?: number, takeAll?: boolean) => {
  const { app } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error>();
  const [api, setApi] = React.useState<any>();
  const [entities, setEntities] = React.useState<any>();
  const [total, setTotal] = React.useState<number>();
  const [take] = React.useState(100);
  const { pagination, pagination_api } = usePagination(take, total);

  const [{ reload: selectionReload }] = useStore();

  const [tr] = useWords();
  const notification = useGlobalMessage();

  React.useEffect(
    () => {
      const getTopics = async () => {
        setLoading(true);
        let api = new Api(`${app.mlApiUrl}/topics`, app.token);
        let entities: any[] = [];
        let total = 0;
        let params = {
          skip: pagination.skip.toString(),
          take: pagination.take.toString(),
          intents: code || '',
        } as Params;

        try {
          let res = await api.get(null, params);
          total = res.total;
          entities = entities.concat(res.data);
          if (takeAll) {
            for (
              let skip = pagination.take;
              entities.length < total;
              skip += pagination.take
            ) {
              let res = await api.get(null, {
                ...params,
                skip: skip,
                take: pagination.take,
              } as Params);
              entities = entities.concat(res.data);
            }
          }
          for (let i = 0, len = entities.length; i < len; i++) {
            entities[i]['color'] = _randomHSL();
          }
          setApi(api);
          setTotal(total);
          setEntities(entities);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };
      if (!loading) getTopics();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pagination.skip,
      pagination.take,
      code,
      app.mlApiUrl,
      app.token,
      takeAll,
      entities,
      selectionReload,
    ]
  );

  const createTopic = async (data: Data) => {
    setLoading(true);
    try {
      const res = await api.post(data);
      if (res.id) {
        setEntities(entities.concat({ ...res, color: _randomHSL() }));
        notification.success(tr.created);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      notification.error(tr.error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const addTopicToIntent = async (id: number, intentId: number) => {
    setLoading(true);
    try {
      const target = await api.getById(id);
      let intents: number[] = target.intents.map((t: T_Topic) => t.id);
      intents = intents.concat(Number(intentId));
      const res = await api.put(id, { intents: intents });
      if (res.id) {
        setEntities(entities.concat({ ...res, color: _randomHSL() }));
        notification.success(tr.saved);
      }
    } catch (err) {
      notification.error(tr.error);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const removeTopicFromIntent = async (
    id: number,
    intentId: number,
    remove?: boolean
  ) => {
    setLoading(true);

    try {
      const target = await api.getById(id);
      let intents: number[] = target.intents.map((t: T_Topic) => t.id);
      let filteredIntents: number[] | null = intents.filter(
        (id: number) => Number(id) !== Number(intentId)
      );
      if (filteredIntents.length === 0) {
        filteredIntents = [];
      }
      console.log(filteredIntents);
      const res = await api.put(id, {
        intents: filteredIntents,
      });
      if (res.id) {
        let new_entities;
        if (remove)
          new_entities = entities.filter((ent: T_Topic) => ent.id !== res.id);
        else
          new_entities = entities.map((ent: T_Topic) =>
            ent.id === res.id ? { ...ent, ...res } : ent
          );
        notification.success(tr.deletedFromIntent);
        setEntities(new_entities);
      }
    } catch (err) {
      notification.error(tr.error);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const deleteEntitie = async (id: number) => {
    try {
      const res = await api.delete(id);
      if (res.message.includes('succeed')) {
        setEntities(entities.filter((el: any) => el.id !== id));
        notification.success(tr.deleted);
      }
    } catch (error) {
      notification.error(tr.error);
    }
  };

  const entities_api = {
    createTopic,
    addTopicToIntent,
    removeTopicFromIntent,
    deleteEntitie,
  };

  const data = {
    entities,
    entities_api,
    pagination,
    pagination_api,
  };

  return { loading, error, data };
};

export default useEntities;
