import React from 'react';
import Client from 'api/graphql/Client';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from 'app/App';
import Loader from 'components/Loader';

import useWords from 'hooks/words';

import { useGlobalMessage } from 'hooks/useGlobalMessage';

type PrivateProps = {
  path: string;
  redirectPath: string;
  component: React.FC<any>;
};

const PrivateRoute = (props: PrivateProps) => {
  const { app, dispatch } = React.useContext(AppContext);
  const [auth, setAuth] = React.useState('pending');
  const [tr] = useWords();

  const { component: Component, redirectPath, ...other } = props;

  const notification = useGlobalMessage();

  React.useEffect(() => {
    const authenticate = async () => {
      try {
        let client = new Client(app.apiUrl, app.token);
        dispatch({ type: 'setClient', payload: client });
        let api = client.User;
        let res = await api.getUser();
        if (res.data.getUser.organization.id !== 1)
          throw new Error('Not authorized');
      } catch (err) {
        localStorage.removeItem('token');
        window.location.href = process.env.REACT_APP_ASKMONA || '/';
      } finally {
        setAuth('true');
        notification.success(tr.authenticated);
      }
    };
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.token, app.apiUrl]);

  return (
    <Route
      {...other}
      render={() => {
        if (auth === 'pending') return <Loader locker absolute />;
        else if (auth === 'true') return <Component {...other} />;
        else return <Redirect to={{ pathname: redirectPath }} />;
      }}
    />
  );
};

export default PrivateRoute;
