import React from 'react';
import { AppContext } from 'app/App';
import Api from 'api/rest/';
import usePagination from 'hooks/pagination';

const useLanguages = () => {
  const { app } = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [languages, setLanguages] = React.useState([]);
  const [total, setTotal] = React.useState();
  const { pagination, pagination_api } = usePagination(20, total);

  React.useEffect(() => {
    const getLanguages = async () => {
      let api = new Api(`${app.mlApiUrl}/languages`, app.token);
      let params = {
        skip: pagination.skip.toString(),
        take: pagination.take.toString(),
      };
      try {
        let res = await api.get(null, params);
        let languages = res.data;
        setLanguages(languages);
        setTotal(res.total);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    getLanguages();
  }, [pagination.skip, pagination.take, setTotal, app.mlApiUrl, app.token]);

  const data = {
    languages,
    pagination,
    pagination_api,
  };

  return { loading, error, data };
};

export default useLanguages;
