import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import Modal from 'components/old/Modal';
import Switch from "../../../../components/old/Switch";
import Flex from "../../../../components/old/Flex";
import Typo from "../../../../components/old/Typo";
import Button from "../../../../components/old/Button";

const Container = styled.div`
  min-height: 100px;
  padding: 15px;
`;

const IntentRow = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const TopicIntroRow = styled.div`
  display: flex;
  margin: 0px 0px 15px 0px;
  color:${(p) => p.disabled ? p.theme['colors']['grey'] : p.theme['colors']['primary']}
`;

const TopicRow = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  color:${(p) => p.disabled ? p.theme['colors']['grey'] : p.theme['colors']['primary']}
`;

const Head = styled.div`
  background-color: ${(p) => p.theme['colors']['primary']};
  position: sticky;
  top: 0;
  width: 100%;
  color: white;
  padding: 15px;
  font-weight: 600;
  border-radius: 5px 5px 0px 0px;
  font-size: 15px;
`;

const Scrollable = styled.div`
  overflow: scroll;
  max-height: 300px;
`;

const Separator = styled.div`
  background-color: ${(p) => p.disabled ? p.theme['colors']['grey'] : p.theme['colors']['primary']};
  width: 100%;
  height: 1px;
`;


export default (props) => {
  const {
    g: {w, api},
    intent,
    topics,
    model,
    isOpen,
    onIntentUpdated,
    close,
  } = props;
  const [intentWithLowPriority, setIntentWithLowPriority] = useState({
    ...intent,
    lowPriority: false
  });
  const [topicsWithLowPriority, setTopicsWithLowPriority] = useState([]);

  useEffect(()=> {
    setIntentWithLowPriority({
      ...intent,
      lowPriority: intent.modelsWithLowPriority.findIndex(({id})=> id === model.id)>=0
    })
  }, [model.id, intent]);

  useEffect(()=> {
    setTopicsWithLowPriority(topics.map((topic)=> ({
      ...topic,
      lowPriority: topic.modelsWithLowPriority.findIndex(({id})=> id === model.id)>=0
    })))
  }, [model.id, topics]);

  useEffect(()=> {
    if(intentWithLowPriority.lowPriority) {
      setTopicsWithLowPriority((topicsWithLowPriority)=> topicsWithLowPriority.map((topic)=> ({
        ...topic,
        lowPriority: false
      })))
    }
  }, [intentWithLowPriority]);

  const save = useCallback(()=> {
    const savePriorities = async () => {
      const initialLowPriority = intentWithLowPriority.modelsWithLowPriority.findIndex(({id})=> id === model.id)>=0;
      let updatedIntent = intent;
      if(intentWithLowPriority.lowPriority !== initialLowPriority) {
        if(intentWithLowPriority.lowPriority) {
          updatedIntent = await api.Models._deprioritizeIntent(model.id, intentWithLowPriority.id);
        } else {
          updatedIntent = await api.Models._reprioritizeIntent(model.id, intentWithLowPriority.id);
        }
      }
      await Promise.all(topicsWithLowPriority.map(async (topic)=> {
        const initialLowPriority = topic.modelsWithLowPriority.findIndex(({id})=> id === model.id)>=0;
        if(topic.lowPriority !== initialLowPriority) {
          if(topic.lowPriority) {
            updatedIntent = await api.Models._deprioritizeTopic(model.id, topic.id);
          } else {
            updatedIntent = await api.Models._reprioritizeTopic(model.id, topic.id);
          }
        }
      }));
      return updatedIntent;
    }
    savePriorities().then((intent)=> onIntentUpdated(intent)).finally(close);
  }, [api, close, intent, intentWithLowPriority, model, onIntentUpdated, topicsWithLowPriority]);

  return (
    <Modal
      isOpen={isOpen}
      close={close}
      width="20%"
      padding="0px"
      selector="#root"
      closeColor="white"
      minWidth="300px"
    >
      <Head>{w.deprioritizeIntentOrTopics}</Head>
      <Container>
        <IntentRow>
          <Flex flexDirection="colum">
            <Typo fontWeight="700" fontSize="16px" textTransform="none">{w.deprioritizeIntent}</Typo>
          </Flex>
          <Flex flexDirection="colum" justifyContent="flex-end">
            <Switch
              checked={intentWithLowPriority.lowPriority}
              onChange={() => {
                 setIntentWithLowPriority({...intentWithLowPriority, lowPriority: !intentWithLowPriority.lowPriority});
              }}
            />
          </Flex>
        </IntentRow>
        {topicsWithLowPriority.length > 0 && (
            <>
                <TopicIntroRow disabled={intentWithLowPriority.lowPriority}>
                  <Typo fontWeight="700" fontSize="16px" textTransform="none">{w.deprioritizeTopic}</Typo>
                </TopicIntroRow>
                <Scrollable>
                    {topicsWithLowPriority.map((topicWithLowPriority)=> (
                      <>
                        <Separator disabled={intentWithLowPriority.lowPriority} />
                        <TopicRow key={topicWithLowPriority.id} disabled={intentWithLowPriority.lowPriority}>
                            <Flex flexDirection="colum">
                              <Typo fontWeight="700" fontSize="16px" textTransform="none">{topicWithLowPriority.title}</Typo>
                            </Flex>
                            <Flex flexDirection="colum" justifyContent="flex-end">
                              <Switch
                                  disabled={intentWithLowPriority.lowPriority}
                                  checked={topicWithLowPriority.lowPriority}
                                  onChange={() => {
                                    setTopicsWithLowPriority(topicsWithLowPriority.map((topic)=> ({
                                      ...topic,
                                      lowPriority: (topic.id !== topicWithLowPriority.id) ? topic.lowPriority : !topicWithLowPriority.lowPriority
                                    })))
                                  }}
                              />
                            </Flex>
                          </TopicRow>
                      </>
                    ))}
                </Scrollable>
            </>
        )}
      </Container>
      <Flex justifyContent="space-between" margin="15px">
        <Button color="secondary" onClick={close}>
          {w.cancel}
        </Button>
        <Button onClick={save}>{w.validate}</Button>
      </Flex>
    </Modal>
  );
};
