import React from 'react';
import useWords from 'hooks/words';
import Grid from '@material-ui/core/Grid';
import TextField from 'components/TextField';
import Typography from 'components/Typography';
import Button from '@material-ui/core/Button';
import { Props } from '../Models';

const Header = (props: Props) => {
  const [tr] = useWords();

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <TextField
          placeholder="Rechercher"
          margin="none"
          onChange={(e) => props.setSearch(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6" transform="uppercase">
          {`${tr.models} ${tr.availables}`}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => props.setCreate(true)}
        >
          <span>{tr.create}</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Header;
