import actions from './actions';
import models from './models';
import nouns from './nouns';

const office = {
  fr_FR: {
    nlp: 'traitement du language naturel',
    models: 'modèles',
    accessModels: 'Gestion des modèles',
    trainModels: 'Entraînement continu des modèles',
    available: 'disponible',
    availables: 'disponibles',
    ...actions['fr_FR'],
    ...nouns['fr_FR'],
    ...models['fr_FR'],
  },
  en_EN: {
    nlp: 'nlp',
    models: 'models',
    accessModels: 'Management of Models',
    trainModels: 'Continuous Training of Models',
    available: 'available',
    availables: 'availables',
    ...actions['en_EN'],
    ...nouns['en_EN'],
    ...models['en_EN'],
  },
};

export default office;
