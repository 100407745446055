import React from 'react';
import { withGlobal } from 'context//';
import Button from 'components/old/Button';
import Modal from 'components/old/Modal';
import Flex from 'components/old/Flex';
import Typo from 'components/old/Typo';
import Title from 'components/old/Title';
import Header from 'components/old/Header';
import Loading from 'components/old/Loading';

export default withGlobal((props) => {
  const {
    g: { w },
    label,
    name,
    isOpen,
    loading,
    close,
    onDelete,
  } = props;
  return (
    <Modal small isOpen={isOpen} close={close} selector="#root">
      {loading && <Loading />}
      <Header>
        <Title>{label}</Title>
      </Header>
      <Typo color="secondary">- {name}</Typo>
      <Flex justifyContent="space-between" margin="10px 0px 0px 0px">
        <Button onClick={close}>{w.cancel}</Button>
        <Button color="secondary" onClick={onDelete}>
          {w.confirm}
        </Button>
      </Flex>
    </Modal>
  );
});
