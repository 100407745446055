import React from 'react';
import { Page } from './Ui';
import { withGlobal } from 'context/';

import Head from './testPanel/Head';
import Content from './testPanel/Content';

const Test = withGlobal((props) => {
  return (
    <Page>
      <Head {...props} />
      <Content {...props} />
    </Page>
  );
});

export default Test;
