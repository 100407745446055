import React, {useState, useEffect, useMemo} from 'react';
import { useStore } from 'store/store';
import { SET_VISIBLE } from 'store/redirectlottie';
import { SET_INTENTS_SKIP } from 'store/pagination';
import styled from 'styled-components';
import useWords from 'hooks/words';
import useTrainingPhrases from 'api/rest/hooks/useTrainingPhrases';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import ReplyRoundedIcon from '@material-ui/icons/ReplyRounded';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ClickIcon } from 'components/old/Icon';
import Typo from 'components/old/Typo';
import Delete from 'components/old/Delete';
import Table, {
  TWrap,
  THEAD,
  TBody,
  TRow,
  THead,
  TCell,
  Board,
} from 'components/old/Table';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import totalAmount from '../../../../assets/total-amount.png';
import deprioritize from '../../../../assets/deprioritize.png';
import deprioritizeActive from '../../../../assets/deprioritize_active.png';
import InfoIcon from '@material-ui/icons/Info';
import Deprioritize from "./Deprioritize";

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '10px',
  },
}));

export default (props) => {
  const {
    g: {
      w,
      api: { Intents, Topics },
    },
    state: { intents, total, skip, take },
    search,
    setSearch,
    setLocalTotal,
    model,
    dispatch,
  } = props;

  const [intentsArray, setIntentsArray] = useState(intents);
  const [topicsArray, setTopicsArray] = useState([]);
  const [{ pagination }, storeDispatch] = useStore();

  const [firstLoad, setFirstload] = useState(true);
  const getSearchIntents = async (input) => {
    return Intents._get(null, {
      title: input,
      models: [model.id],
      skip: skip,
      take: take,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setFirstload(false);
    }, 300);
    return () => {
      setFirstload(true);
    };
  }, []);

  useEffect(() => {
    if (search && !firstLoad) {
      dispatch({
        type: 'setSkip',
        payload: 0,
      });
    } else {
      dispatch({
        type: 'setSkip',
        payload: pagination.intentsSkip,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    storeDispatch({
      type: SET_INTENTS_SKIP,
      payload: skip,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  useEffect(() => {
    const searchFunction = async () => {
      if (search.length === 0) {
        setLocalTotal(total);
        setIntentsArray(intents);
      } else {
        const res = await getSearchIntents(search);
        setLocalTotal(res.total);
        setIntentsArray(res.data);
      }
    };
    searchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, skip, firstLoad, intents]);

  useEffect(() => {
    return () => {
      if (skip === 0) setSearch('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(intentsArray.length===0) {
      setTopicsArray([]);
    } else {
      Topics._get(null, {
        intents: intentsArray.map(({id})=> id),
      }).then((res)=> setTopicsArray(res.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentsArray]);

  return (
    <TWrap>
      <Table>
        <THEAD>
          <TRow>
            <THead> {w.intentName} </THead>
            <THead align="center"> {w.state} </THead>
            <THead align="end"> {w.options} </THead>
          </TRow>
        </THEAD>
        <TBody>
          {intentsArray.sort().map((i) => (
              <Intent
                key={i.id}
                {...props}
                i={i}
                topics={topicsArray.filter(({intents})=> intents.find(({id})=> id === i.id))}
                skip={skip}
                dispatch={dispatch}
              />
              )
          )}
        </TBody>
      </Table>
    </TWrap>
  );
};

const Intent = (props) => {
  const {
    g: {
      w,
      api: { Intents, Models },
      ...g
    },
    history,
    location: { pathname },
    i,
    topics,
    dispatch,
    intents,
    intentsWithModels,
    model,
    skip,
  } = props;
  const classes = useStyles();
  const [tr] = useWords();

  const [deletion, setDeletion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [monolithId, setMonolithId] = useState(0);
  const [infoTotal, setInfoTotal] = useState(false);
  const [deprioritizeDisplay, setDeprioritizeDisplay] = useState(false);
  const [isIntentFromMonolith, setIsIntentFromMonolith] = useState(false);
  const [, storeDispatch] = useStore();
  const notification = useGlobalMessage();
  const { total } = useTrainingPhrases(i.id);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const redirectToMonolith = async () => {
    storeDispatch({ type: SET_VISIBLE });

    await history.push(`/office/model/${monolithId}/intents/${i.id}`);
  };

  const isLowPriority = useMemo(()=> (
      i.modelsWithLowPriority.findIndex(({id})=> id === model.id)>=0 ||
          topics.filter(({modelsWithLowPriority})=> modelsWithLowPriority.findIndex(({id})=> id === model.id)>=0).length > 0
      ), [i, topics, model]);

  const historyPush = async () => {
    await storeDispatch({ type: SET_INTENTS_SKIP, payload: skip });
    history.push(`${pathname}/${i.id}`);
  };

  useEffect(() => {
    const intents = intentsWithModels.find((el) => el.id === i.id);
    isIntentFromMonolith && setMonolithId(intents.models[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntentFromMonolith]);

  const findModelsByIntent = () => {
    if (intentsWithModels) {
      const intents = intentsWithModels.find((el) => el.id === i.id);
      const isMonolith = intents?.models?.some((i) => i.isSharable);
      setIsIntentFromMonolith(isMonolith);
    }
  };

  useEffect(() => {
    findModelsByIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intentsWithModels]);

  return (
    <>
      <TRow key={i.id} height={'65px'}>
        <TCell
          onClick={() => {
            historyPush();
          }}
          clickable={true}
        >
          <Typo fontWeight="600">
            {i.title}
            {isIntentFromMonolith && (
              <StarIcon>
                <GradeRoundedIcon color="secondary" style={{ fontSize: 15 }} />
              </StarIcon>
            )}
          </Typo>
        </TCell>
        <TCell
          align="center"
          onClick={() => {
            isIntentFromMonolith && monolithId !== model.id
              ? void 0
              : history.push(`${pathname}/${i.id}`);
          }}
          clickable={
            isIntentFromMonolith && monolithId !== model.id ? false : true
          }
        >
          {i.remaining && i.remaining === 0 && w.trainingComplete}
          {i.remaining &&
            i.remaining !== 0 &&
            `${w.trainingEnd} ${w.in} ${i.remaining} ${w.mn}${
              i.remaining > 1 ? 's' : ''
            }`}
        </TCell>
        <TCell>
          <Board alignItem={'center'}>
            <ClickIcon material>
              <img
                  src={isLowPriority ? deprioritizeActive : deprioritize}
                  alt={isLowPriority ? w.reprioritizeIntent : w.deprioritizeIntent}
                  height="24px"
                  onClick={() => setDeprioritizeDisplay(true)}
              />
            </ClickIcon>

            <StyledTotal
              onMouseEnter={() => setInfoTotal(true)}
              onMouseLeave={() => setInfoTotal(false)}
            >
              {infoTotal && (
                <StyledInfo size={'16'}>
                  <InfoIcon />
                  <span>Nombre total de phrases d'entraînements</span>
                </StyledInfo>
              )}
              <StyledContentInfos>
                <img
                  src={totalAmount}
                  alt="Icôn représentant le total des phrases d'entraînement"
                  width="26px"
                  height="26px"
                />
                <p>{total}</p>
              </StyledContentInfos>
            </StyledTotal>
            {isIntentFromMonolith ? (
              <>
                <RedirectIcon onClick={redirectToMonolith}>
                  <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <ReplyRoundedIcon
                      color="primary"
                      style={{ fontSize: 20 }}
                    />
                  </Typography>
                </RedirectIcon>
              </>
            ) : (
              <ClickIcon
                label="edit"
                icon="fas fa-pencil-alt"
                onClick={() => history.push(`${pathname}/${i.id}`)}
              />
            )}

            <ClickIcon
              label="delete"
              color="secondary"
              icon="fas fa-trash-alt"
              onClick={() => setDeletion(true)}
            />
          </Board>
          <Deprioritize
              g={props.g}
              intent={i}
              topics={topics}
              model={model}
              isOpen={deprioritizeDisplay}
              close={()=>setDeprioritizeDisplay(false)}
              onIntentUpdated={(intent)=> {
                dispatch({
                  type: 'setIntents',
                  payload: intents.map((o)=>
                      (!intent || o.id !== intent.id) ? o : intent
                  ),
                });
              }}
          />
          <Delete
            label={
              isIntentFromMonolith && monolithId !== model.id
                ? tr.deletingIntentFromModel
                : tr.deletingPermanently
            }
            name={i.title}
            isOpen={deletion}
            close={() => setDeletion(false)}
            loading={loading}
            onDelete={async () => {
              let res = {};
              if (isIntentFromMonolith && monolithId !== model.id) {
                res = await Models._removeIntent(model.id, i.id);
                setLoading(true);
                if (res) {
                  g.dispatch({
                    type: 'setStatus',
                    payload: {
                      col: 'success',
                      msg: w.deletionSuccess,
                    },
                  });
                  notification.success(tr.deletedFromModel);
                  dispatch({
                    type: 'setIntents',
                    payload: intents.filter((o) => o.id !== i.id),
                  });
                } else {
                  notification.error(tr.error);
                  g.dispatch({
                    type: 'setStatus',
                    payload: {
                      col: 'secondary',
                      msg: w.error,
                    },
                  });
                }
              } else {
                res = await Intents._delete(i.id);
                setLoading(true);
                if (res) {
                  g.dispatch({
                    type: 'setStatus',
                    payload: {
                      col: 'success',
                      msg: w.deletionSuccess,
                    },
                  });
                  notification.success(tr.intentDeleted);
                  dispatch({
                    type: 'setIntents',
                    payload: intents.filter((o) => o.id !== i.id),
                  });
                } else {
                  notification.error(tr.error);
                  g.dispatch({
                    type: 'setStatus',
                    payload: {
                      col: 'secondary',
                      msg: w.error,
                    },
                  });
                }
              }

              setDeletion(false);
              setLoading(false);
            }}
          />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>{tr.redirectToModify}</Typography>
          </Popover>
        </TCell>
      </TRow>
    </>
  );
};

const StarIcon = styled.span`
  margin-top: 5px;
  margin-left: 3px;
`;

const RedirectIcon = styled.div`
  margin-top: 3px;
  margin-right: 6px;
  cursor: pointer;
`;

const StyledTotal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #2c2f7b;
  color: #ffffff;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 10px;
  padding: 0.2rem;
  transition: 2s;
  &&:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #2c2f7b;
    border-width: 10px 0 10px 10px;
    top: 50%;
    right: -10px;
    margin-top: -10px;
  }
`;

const StyledContentInfos = styled.div`
  display: flex;
  margin-left: 0.6rem;
  justify-content: center;
  align-items: center;
  > p {
    margin: 0 0 0 0.2rem;
    font-size: 1rem;
    font-weight: 600;
  }
`;
