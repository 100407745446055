class Crud {
  constructor(path, token = '') {
    this.apiUrl = process.env.REACT_APP_MLAPI;
    this.path = path;
    this.token = token;
  }

  _get = async (body = null, params = null) => {
    return await this._fetch(this.path, 'GET', body, params);
  };

  _getById = async (id) => {
    return await this._fetch(`${this.path}/${id}`);
  };

  _post = async (body = null) => {
    return await this._fetch(this.path, 'POST', body);
  };

  _put = async (id, body = null) => {
    return await this._fetch(`${this.path}/${id}`, 'PUT', body);
  };

  _delete = async (id) => {
    return await this._fetch(`${this.path}/${id}`, 'DELETE');
  };

  _fetch = async (path, method = 'GET', body = null, params = null) => {
    let url = new URL(`https://${this.apiUrl}${path}`);
    params &&
      Object.entries(params).forEach((b) => {
        url.searchParams.append(b[0], b[1]);
      });
    let req = {
      method: method,
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    req = body ? { ...req, body: JSON.stringify(body) } : req;
    return await fetch(url, req).then((res) => res.json());
  };
}

export default Crud;
