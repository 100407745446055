import Crud from './';

class Values extends Crud {
  constructor(token) {
    super('/values', token);
  }

  _getSynonyms = async (id, params) => {
    return await this._fetch(
      `${this.path}/${id}/synonyms`,
      'GET',
      null,
      params
    );
  };
}

export default Values;
