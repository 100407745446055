import React from 'react';
import styled from 'styled-components';
import Loader from 'components/Loader';
import useWords from 'hooks/words';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import SelectMultiple from 'components/SelectMultiple';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useBots from 'api/graphql/hooks/useBots';
import useLanguages from 'api/rest/hooks/useLanguages';
import { Props } from '../Models';
import { Models, Methods as Models_api } from 'api/rest/hooks/useModels';
import { Pagination, Methods as Pagination_api } from 'hooks/pagination';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
type Form = {
  name: string;
  referenceBotId: number | null;
  langs: string[];
  isSharable: boolean;
};
export type CreateProps = Props & {
  data: {
    models: Models;
    models_api: Models_api;
    pagination: Pagination;
    pagination_api: Pagination_api;
  };
};
type Actions =
  | { type: 'setReferenceBotId'; payload: number | null}
  | { type: 'setIsSharable'; payload: boolean }
  | { type: 'setName'; payload: string }
  | { type: 'setLangs'; payload: string[] };
const CreateReducer = (state: Form, action: Actions) => {
  switch (action.type) {
    case 'setReferenceBotId':
      return { ...state, referenceBotId: action.payload };
    case 'setIsSharable':
      return { ...state, isSharable: action.payload };
    case 'setName':
      return { ...state, name: action.payload };
    case 'setLangs':
      return { ...state, langs: action.payload };
    default:
      return { ...state };
  }
};
const MarginTop = styled.div`
  margin-top: 1.5rem;
`;
const Create = (props: CreateProps) => {
  const [tr] = useWords();
  const {
    data: { models },
  } = props;
  const {
    data: { bots },
    loading: bload,
  } = useBots();
  const {
    data: { languages },
    loading: lload,
  } = useLanguages();
  const loading = bload || lload;
  const [form, dispatch] = React.useReducer(CreateReducer, {
    name: '',
    referenceBotId: null,
    langs: [],
    isSharable: false,
  });

  const notification = useGlobalMessage();
  const onSubmit = async () => {
    const verification =
      form.name.length === 0 ||
      form.langs.length === 0 ||
      (!form.isSharable && form.referenceBotId === null);
    if (verification) {
      notification.warning(tr.missingField);
    } else {
      form.langs.forEach(async (lang) => {
        let acc = {};
        if (models.some((el) => el.name === form.name)) {
          notification.warning(tr.hasAlreadyName);
        } else if (
          !form.isSharable &&
          models.some(
            (el) =>
              (el.fr &&
                el.fr.referenceBotId === form.referenceBotId &&
                form.langs.some((el) => Number(el) === 1)) ||
              (el.en &&
                el.en.referenceBotId === form.referenceBotId &&
                form.langs.some((el) => Number(el) === 2))
          )
        ) {
          if (form.langs.length > 1) {
            notification.warning(tr.hasAlreadyAtLeastOneModel);
          } else {
            notification.warning(tr.hasAlreadyModel);
          }
        } else {
          try {
            let res = await props.data.models_api.createModel({
              language: Number(lang),
              referenceBotId: form.referenceBotId,
              name: form.name,
              isSharable: form.isSharable,
            });
            console.log(res);
            if (res.id) {
              notification.success(tr.created);
              const { language, name, ...other } = res;
              acc = { ...acc, name, [language.code]: other };
            }
          } catch (err) {
            notification.error(tr.error);
          } finally {
            props.data.models_api.addModel(acc);
            props.setCreate(false);
            notification.success(tr.created);
          }
        }
      });
    }
  };

  return (
    <Dialog open={true} onClose={() => props.setCreate(false)} fullWidth={true}>
      <DialogTitle>{tr.createModel}</DialogTitle>
      <DialogContent>
        {loading && <Loader absolute />}
        {!form.isSharable && (
          <TextField
            fullWidth
            required={true}
            select
            label={tr.referenceBotId}
            name="referenceBotId"
            onChange={(e) =>
              dispatch({
                type: 'setReferenceBotId',
                payload: Number(e.target.value),
              })
            }
          >
            {bots.map((bot) => (
              <MenuItem key={bot.id} value={bot.id}>
                {bot.name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          autoFocus
          fullWidth
          onChange={(e) =>
            dispatch({
              type: 'setName',
              payload: e.target.value,
            })
          }
          required={true}
          label={tr.modelName}
          name="name"
        />
        <SelectMultiple
          label={tr.languages}
          value={form.langs}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
            dispatch({
              type: 'setLangs',
              payload: e.target.value as string[],
            });
          }}
          items={languages.map((lang: any) => ({
            key: lang.id,
            value: lang.id,
            name: lang.name,
          }))}
        />
        <MarginTop>
          <FormControlLabel
            label={tr.isAMonolith}
            control={
              <Checkbox
                checked={form.isSharable}
                onChange={() => {
                  dispatch({
                    type: 'setReferenceBotId',
                    payload:
                      form.referenceBotId !== null ? null : form.referenceBotId,
                  });
                  dispatch({
                    type: 'setIsSharable',
                    payload: !form.isSharable,
                  });
                }}
              />
            }
          />
        </MarginTop>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setCreate(false)}>{tr.cancel}</Button>
        <Button onClick={onSubmit}>{tr.submit}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default Create;
