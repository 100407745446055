import React from 'react';
import useWords from 'hooks/words';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Props } from '../Models';
import { Models, Methods as Models_api } from 'api/rest/hooks/useModels';
import { Pagination, Methods as Pagination_api } from 'hooks/pagination';

import { useGlobalMessage } from 'hooks/useGlobalMessage';

export type CreateProps = Props & {
  data: {
    models: Models;
    models_api: Models_api;
    pagination: Pagination;
    pagination_api: Pagination_api;
  };
  deleteItem: any;
};

const Delete = (props: CreateProps) => {
  const [tr] = useWords();
  const { deleteItem } = props;

  const notification = useGlobalMessage();

  const onDelete = async () => {
    try {
      let res = await props.data.models_api.deleteModel(deleteItem.id);
      if (res) notification.success(tr.deleted);
    } catch (err) {
      notification.error(tr.error);
    } finally {
      props.data.models_api.removeModel(deleteItem);
      props.setDelete(null);
    }
  };

  return (
    <Dialog open={true} onClose={() => props.setDelete(null)} fullWidth={true}>
      <DialogTitle>{tr.deleteModel}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`${tr.delete.charAt(0).toUpperCase() + tr.delete.slice(1)} "${
            deleteItem.name
          }" (${tr.language}: ${deleteItem.lang}) ?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setDelete(null)}>{tr.cancel}</Button>
        <Button onClick={onDelete}>{tr.submit}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Delete;
