import React from 'react';
import { AppContext } from 'app/App';
import useWords from 'hooks/words';
import { useGlobalMessage } from 'hooks/useGlobalMessage';

const Server = () => {
  const { app } = React.useContext(AppContext);
  const [server, setServer] = React.useState(false);
  const [tr] = useWords();
  const notification = useGlobalMessage();

  React.useEffect(() => {
    const checkServer = async () => {
      try {
        notification.info(tr.loadingStart);
        const res = await fetch(`${app.apiUrl}/rest/status`).then((res) =>
          res.json()
        );
        setServer(res.status);
      } catch (err) {
        setServer(false);
      } finally {
        notification.info(tr.loadingEnd);
      }
    };
    checkServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.apiUrl]);

  if (server) return null;
  return <div>Serverdown</div>;
};

export default Server;
