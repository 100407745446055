import React, { useState, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import useWords from 'hooks/words';
import { withGlobal } from 'context//';
import { Page } from './Ui';
import Loading from 'components/old/Loading';
import Pagination from 'components/old/Pagination';
import { Head, Content } from './entities/Ui';
import { Legend } from '../models/Body';

const LegendContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const Redbox = styled.span`
  min-width: 10px;
  height: 10px;
  background-color: ${(p) => p.theme.colors.secondary};
  border-radius: 2px;
  margin-top: 6px;
  margin-right: 3px;
`;

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setEntities':
      return { ...state, entities: { ...state.entities, ...action.payload } };
    case 'setSearch':
      return { ...state, search: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setIntents':
      return { ...state, intents: action.payload };
    default:
      return state;
  }
};

const Entities = withGlobal((props) => {
  const {
    g: { api },
    match: {
      params: { id },
    },
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    entities: {
      intents: [],
      array: [],
      skip: 0,
      take: 10,
      total: 0,
      message: '',
    },
    loading: false,
  });

  const {
    entities: { skip, take },
  } = state;

  const [search, setSearch] = useState('');

  const [tr] = useWords();

  const [localTotal, setLocalTotal] = useState(state.entities.total);

  useEffect(() => {
    const getIntents = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await api.Intents._get(null, { models: id });
      if (res) {
        let arr = res.data.map((data) => data.id);
        dispatch({ type: 'setIntents', payload: arr });
      }
    };
    getIntents();
  }, [id, api]);

  useEffect(() => {
    const _getEntities = async () => {
      dispatch({ type: 'setLoading', payload: true });
      let params = {
        skip: state.entities.skip,
        take: state.entities.take,
      };
      const res = await api.Topics._get(null, params);
      if (res) {
        dispatch({
          type: 'setEntities',
          payload: {
            array: res.data || [],
            total: res.total,
            message: res.message,
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    if (state.intents) _getEntities();
  }, [state.entities.skip, state.entities.take, api, state.intents]);

  return (
    <Page>
      {state.loading && <Loading />}
      <Head {...props} dispatch={dispatch} setSearch={setSearch} />
      {state.entities &&
        state.entities.array &&
        state.entities.array.some((el) => el.intents.length === 0) && (
          <LegendContainer>
            <Legend>
              <Redbox /> : {tr.entitiesLegend}
            </Legend>
          </LegendContainer>
        )}
      <Content
        {...props}
        state={state}
        search={search}
        setSearch={setSearch}
        dispatch={dispatch}
        setLocalTotal={setLocalTotal}
      />
      <Pagination
        activePage={(skip + 10) / take}
        itemsCountPerPage={take}
        totalItemsCount={search.length > 0 ? localTotal : state.entities.total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          dispatch({
            type: 'setEntities',
            payload: { skip: (page - 1) * take },
          });
        }}
      />
    </Page>
  );
});

export default Entities;
