import React from 'react';
import { BodyProps } from '../../Body';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import Loader from 'components/Loader';
import useModel from 'api/rest/hooks/useModel';
import { Model } from 'api/rest/hooks/useModels';
import Flags from './Flags';
import Status from './Status';
import Actions from './Actions';

const StyledItem = styled(Grid)`
  border-bottom: 1px solid #eee;
  > * {
    text-align: left;
    align-items: center;
    display: flex;
  }
`;

const ModelName = styled(Grid)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StarIcon = styled.div`
  margin-top: 2px;
  margin-left: 3px;
`;

const Item = (props: BodyProps & { name: string } & { model: Model }) => {
  const { loading, error, data } = useModel(props.name);
  const [focus, setFocus] = React.useState<string>();

  React.useEffect(() => {
    if (data.languages) onChange(data.languages[0]);
  }, [loading, data.languages]);

  const onChange = (lang: string) => {
    setFocus(lang);
  };

  let dataModel: any = {};
  let focusLang: string = '';

  if (data.model) {
    dataModel = data.model;
  }

  if (focus) {
    focusLang = focus;
  }

  const onEdit = () => {
    props.history.push(`/office/model/${dataModel[focusLang].id}/intents`);
  };

  const onDelete = () => {
    props.setDelete(dataModel[focusLang]);
  };

  let languages = [];

  const isMonolith =
    (dataModel.en &&
      (!dataModel.en.referenceBotId || dataModel.en.referenceBotId === '0')) ||
    (dataModel.fr &&
      (!dataModel.fr.referenceBotId || dataModel.fr.referenceBotId === '0'));

  if (data.languages) {
    languages = data.languages;
  }

  return (
    <StyledItem container justify="space-between" alignItems="center">
      {loading && <Loader absolute />}
      {error && <div>{error}</div>}
      {!loading && (
        <React.Fragment>
          <ModelName item xs={3} onClick={onEdit}>
            {dataModel.name}
            {isMonolith && (
              <StarIcon>
                <GradeRoundedIcon color="secondary" style={{ fontSize: 15 }} />
              </StarIcon>
            )}
          </ModelName>
          <Grid item xs={2}>
            <Flags languages={languages} focus={focus} onChange={onChange} />
          </Grid>
          <Grid item xs={4}>
            {focus && <Status model={dataModel[focus]} />}
          </Grid>
          <Grid item xs={2}>
            {focus && (
              <Actions
                onEdit={onEdit}
                onDelete={isMonolith ? undefined : onDelete}
              />
            )}
          </Grid>
        </React.Fragment>
      )}
    </StyledItem>
  );
};

export default Item;
