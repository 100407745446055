import React from 'react';
import { AppContext } from 'app/App';

const useWords = () => {
  const { app } = React.useContext(AppContext);
  const tr = app.wording.translation;

  return [tr];
};

export default useWords;
