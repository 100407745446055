import Crud from './';

class Models extends Crud {
  constructor(token) {
    super('/models', token);
  }

  _addIntent = async (id, body = null) => {
    return await this._fetch(`${this.path}/${id}/intents`, 'POST', body, null);
  };

  _removeIntent = async (id, intentId) => {
    return await this._fetch(
      `${this.path}/${id}/intents/${intentId}`,
      'DELETE',
      null,
      null
    );
  };

  _deprioritizeIntent = async (id, intentId = null) => {
    return await this._fetch(`${this.path}/${id}/intents/${intentId}/deprioritize`, 'POST', null, null);
  };

  _reprioritizeIntent = async (id, intentId = null) => {
    return await this._fetch(`${this.path}/${id}/intents/${intentId}/reprioritize`, 'POST', null, null);
  };

  _deprioritizeTopic = async (id, topicId = null) => {
    return await this._fetch(`${this.path}/${id}/topics/${topicId}/deprioritize`, 'POST', null, null);
  };

  _reprioritizeTopic = async (id, topicId = null) => {
    return await this._fetch(`${this.path}/${id}/topics/${topicId}/reprioritize`, 'POST', null, null);
  };

}

export default Models;
