import React from 'react';
import styled, { css } from 'styled-components';

const Select = styled.select`
  cursor: pointer;
  outline: none;
  background-position: center;
  transition: background 0.3s;
  padding: 0.5rem 0.75rem;
  font-family: inherit;
  border-radius: 5px;
  font-size: inherit;
  width: 100%;
  max-width: 100%;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  > option {
  }

  ${({ color, shadow, expand, theme }) => css`
    color: ${theme['colors'][color]};
    box-shadow: ${theme['shadows'][shadow]};
    border: 1px solid ${theme['colors'][color]};
    background-color: ${theme['colors'][color]}20;
    &:focus {
      background: ${theme['colors'][color]}60;
    }
    &:active {
      background-color: ${theme['colors'][color]}60;
      background-size: 100%;
    }
    &:disabled,
    &[disabled] {
      color: #aaa;
      background-color: #ddd;
      border-color: #ddd;
    }
  `}
`;

const Selector = ({ options, label, onSelect, ...other }) => {
  return (
    <Select {...other}>
      <option>{label}</option>
      {options.map((o, i) => (
        <option key={o.key} value={JSON.stringify(o)}>
          {o.label}
        </option>
      ))}
    </Select>
  );
};

Selector.defaultProps = {
  onChange: (e) => console.log(e.target.value),
  options: [],
  label: '',
  color: 'primary',
  shadow: 1,
};

export default Selector;
