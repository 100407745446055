import gql from 'graphql-tag';
import { _mutate, _query } from 'api/graphql/old/client';

class User {
  login = async (args) => {
    return await _mutate(LOGIN, args);
  };

  getUser = async (args) => {
    return await _query(GET_USER, args);
  };
}

export default User;

const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      token
      permissions
      organization {
        id
      }
    }
  }
`;

const userScheme = `
  id
  email
  firstname
  lastname
  token
  permissions
  bots {
    id
    name
  }
  organization {
    id
  }
`;

const GET_USER = gql`
  query getUser (
    $token: String
  ) {
    getUser (
      token: $token
    ) {
      ${userScheme}
    }
  }
`;
