const actions = {
  fr_FR: {
    warning: 'Attention',
    valueWithSameNameExists: 'Une valeur avec le même nom existe déjà',
    synonymWithSameNameExists: 'Un synonyme avec le même nom existe déjà',
    monolithModelWarning: 'Attention, vous êtes sur un modèle monolithe !',
  },
  en_EN: {
    warning: 'Warning',
    valueWithSameNameExists: 'A value with the same name already exists',
    synonymWithSameNameExists: 'A synonym with the same name already exists',
    monolithModelWarning: 'Careful, you are on a monolith model !',
  },
};
export default actions;
