// Actions types

export const SET_VISIBLE = 'SET_VISIBLE';
export const CLEAR_VISIBLE = 'CLEAR_VISIBLE';
export const SET_PAUSE = 'SET_PAUSE';
export const ClEAR_PAUSE = 'CLEAR_PAUSE';

// Initial State

export const redirectLottieInitialState = {
  isStopped: true,
  isPaused: false,
};

// Message Reducer

export const redirectLottieReducer = (
  state = redirectLottieInitialState,
  action
) => {
  switch (action.type) {
    case SET_VISIBLE:
      return {
        ...state,
        isStopped: false,
      };
    case CLEAR_VISIBLE:
      return {
        ...state,
        ...redirectLottieInitialState,
      };

    case SET_PAUSE:
      return {
        ...state,
        isPaused: true,
      };
    case ClEAR_PAUSE:
      return {
        ...state,
        isPaused: false,
      };

    default:
      return state;
  }
};
