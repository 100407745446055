import React from 'react';
import useWords from 'hooks/words';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

const StyledHead = styled(Grid)`
  background: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing}px;
  color: white;
  > * {
    font-weight: 700;
    :last-child {
      text-align: center;
    }
    :first-letter {
      text-transform: capitalize;
    }
  }
`;

const Head = () => {
  const [tr] = useWords();
  return (
    <StyledHead container justify="space-between" alignItems="center">
      <Grid item xs={3}>
        {tr.name}
      </Grid>
      <Grid item xs={2}>
        {tr.language}
      </Grid>
      <Grid item xs={4}>
        {tr.status}
      </Grid>
      <Grid item xs={2}>
        {tr.options}
      </Grid>
    </StyledHead>
  );
};

export default Head;
