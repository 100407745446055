const actions = {
  fr_FR: {
    deletedFromIntent: "L'entitie a été supprimé de l'intent",
    entitieMustHaveIntent:
      'Une entitie doit être attachée au minimum à un intent',
    deletionConfirmationText:
      "Cette entitie est uniquement ratachée à cet intent. Vous pouvez conserver l'entitie sans intent si vous pensez pouvoir l'utiliser plus tard, ou bien supprimer l'entitie définitivement.",
    entitiesLegend: 'Entities sans intent',
    keepWithoutIntent: 'Conserver sans intent',
    synonymDuplicata: 'Un synonyme avec le même nom existe déjà',
    noImportEntitiesLeft: 'Toutes les entities ont été importées',
    noEntitieResult: 'Aucun Résultat',
  },
  en_EN: {
    deletedFromIntent: 'Entitie successfully removed from intent',
    entitieMustHaveIntent: 'An entitie must have at least one intent',
    deletionConfirmationText:
      'This entitie is only attached to this intent. You can keep the entitie without intent in case you think you could still use it later, or delete the entitie permanently',
    entitiesLegend: 'Entities with no intent',
    keepWithoutIntent: 'Keep without intent',
    synonymDuplicata: 'A synonym with the same name already exists',
    noImportEntitiesLeft: 'All Entities have been imported',
    noEntitieResult: 'No Results',
  },
};
export default actions;
