import React, { useState, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { withGlobal } from 'context//';
import useWords from 'hooks/words';
import { useStore } from 'store/store';
import { SET_INTENTS_SEARCH } from 'store/search';
import { Page } from './Ui';
import Typo from 'components/old/Typo';
import Pagination from 'components/old/Pagination';
import IntentsHeader from './intents/Header';
import ErrorBoundary from 'components/old/ErrorBoundary';
import Loading from 'components/old/Loading';
import IntentsContent from './intents/Content.js';
import { Legend, StarIcon } from '../models/Body';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setIntents':
      return { ...state, intents: action.payload, loading: false };
    case 'setIntentsWithAllModels':
      return { ...state, intentsWithAllModels: action.payload };
    case 'setMessage':
      return { ...state, message: action.payload };
    case 'setSkip':
      return { ...state, skip: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setState':
      return { ...state, ...action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const LegendContainer = styled.div`
  width: 100%;
`;

const NoIntents = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`;

const Intents = withGlobal((props) => {
  const {
    g: { w, api },
    match: {
      params: { id },
    },
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    intents: [],
    intentsWithAllModels: [],
    loading: true,
    search: '',
    skip: 0,
    take: 10,
    total: 0,
  });

  const [localTotal, setLocalTotal] = useState(state.total);

  // const [search, setSearch] = useState('');
  const [{ search }, storeDispatch] = useStore();

  const [tr] = useWords();
  const setSearch = (input) =>
    storeDispatch({ type: SET_INTENTS_SEARCH, payload: input });

  useEffect(() => {
    const _getIntents = async () => {
      dispatch({ type: 'setLoading', payload: true });
      let params = {
        skip: state.skip,
        take: state.take,
        models: id,
      };
      if (state.search.length > 2) params = { ...params, title: state.search };
      const res = await api.Intents._get(null, params);
      if (res) {
        dispatch({
          type: 'setState',
          payload: {
            intents: res.data || [],
            total: res.total,
            message: res.message,
            loading: false,
          },
        });
        res.message && console.warn(res.message);
      }
    };
    if (id) _getIntents();
  }, [state.skip, state.take, state.search, api, id]);

  useEffect(() => {
    const _getIntents = async () => {
      const res = await api.Intents._get();
      if (res) {
        // console.log(res);
        dispatch({
          type: 'setIntentsWithAllModels',
          payload: res.data,
        });
      }
    };
    _getIntents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      {state.loading && <Loading />}
      <IntentsHeader
        {...props}
        state={state}
        search={search.intentsSearch}
        setSearch={setSearch}
        dispatch={dispatch}
        intents={state.intents}
        refetch={async () => {
          const res = await api.Intents._get(null, {
            skip: state.skip,
            take: state.take,
            models: id,
          });
          if (res) {
            dispatch({
              type: 'setState',
              payload: {
                intents: res.data || [],
                total: res.total,
                message: res.message,
                loading: false,
              },
            });
            res.message && console.warn(res.message);
          }
        }}
      />
      {state.intents.length !== 0 && (
        <LegendContainer>
          <Legend>
            {' '}
            <StarIcon>
              <GradeRoundedIcon color="secondary" style={{ fontSize: 15 }} />
            </StarIcon>
            {` : ${tr.monolithIntent}`}
          </Legend>
        </LegendContainer>
      )}
      <ErrorBoundary>
        <IntentsContent
          {...props}
          state={state}
          setSearch={setSearch}
          dispatch={dispatch}
          search={search.intentsSearch}
          localTotal={localTotal}
          setLocalTotal={setLocalTotal}
          intents={state.intents}
          intentsWithModels={state.intentsWithAllModels}
        />
        {state.intents.length === 0 && <NoIntents>{tr.noIntents}</NoIntents>}
      </ErrorBoundary>
      {state.message && <Typo color="secondary">{w.errors.data}</Typo>}
      <Pagination
        activePage={(state.skip + 10) / state.take}
        itemsCountPerPage={state.take}
        totalItemsCount={
          search.intentsSearch.length > 0 ? localTotal : state.total
        }
        pageRangeDisplayed={5}
        onChange={(page) => {
          dispatch({ type: 'setSkip', payload: (page - 1) * state.take });
        }}
      />
    </Page>
  );
});

export default Intents;
