import React from 'react';
import { AppContext } from 'app/App';

import useWords from 'hooks/words';
import { useGlobalMessage } from 'hooks/useGlobalMessage';

const useBots = () => {
  const { app } = React.useContext(AppContext);
  const [bots, setBots] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  const [tr] = useWords();
  const notification = useGlobalMessage();

  React.useEffect(() => {
    const getBots = async () => {
      try {
        if (app.client) {
          let api = app.client.Bot;
          let res = await api.getBots();
          setBots(res.data.getBots.bots);
        }
      } catch (err) {
        setError(err);
        notification.error(tr.failedToFetch);
      } finally {
        setLoading(false);
      }
    };
    getBots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = {
    bots,
  };

  return { loading, error, data };
};

export default useBots;
