import React from 'react';
import { AppContext } from 'app/App';
import Api from 'api/rest';
import useWords from 'hooks/words';
import { useGlobalMessage } from 'hooks/useGlobalMessage';

type State = {
  loading: boolean;
  error: any;
  api: any;
  total: number;
  intents: any;
};

type Data = {
  title: string;
  models: any;
};

type Action =
  | { type: 'setLoading'; payload: boolean }
  | { type: 'setError'; payload: string }
  | { type: 'setTotal'; payload: number }
  | { type: 'setApi'; payload: any }
  | { type: 'setIntents'; payload: any }
  | { type: 'setState'; payload: any };

const IntentsReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setError':
      return { ...state, error: action.payload };
    case 'setApi':
      return { ...state, api: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setIntents':
      return { ...state, intents: action.payload };
    case 'setState':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const useIntents = (take?: number, skip?: any, title?: any, models?: any) => {
  const { app } = React.useContext(AppContext);
  const [state, dispatch] = React.useReducer(IntentsReducer, {
    loading: true,
    error: undefined,
    total: 0,
    api: undefined,
    intents: [],
  });
  const [tr] = useWords();
  const notification = useGlobalMessage();

  React.useEffect(() => {
    const getIntents = async () => {
      let api = new Api(`${app.mlApiUrl}/intents`, app.token);
      let params = {
        take: take || '',
        skip: skip || '',
        title: title || '',
        models: models || '',
      };
      try {
        let res = await api.get(null, params);
        let intents = res.data;
        dispatch({
          type: 'setState',
          payload: {
            intents,
            api,
            total: res.total,
          },
        });
      } catch (err) {
        dispatch({ type: 'setError', payload: err });
      } finally {
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    getIntents();
  }, [app.mlApiUrl, app.token, take, skip, title, models]);

  const createIntent = async (data: Data) => {
    return await state.api.post(data);
  };

  const getIntentById = async (id: number) => {
    return await state.api.getById(id);
  };

  const updateIntent = async (id: number, data?: Data) => {
    return await state.api.put(id, data);
  };

  const deleteIntent = async (id: number) => {
    const res = await state.api.delete(id);
    if (res) {
      notification.success(tr.deleted);
      dispatch({
        type: 'setIntents',
        payload: state.intents.filter((el: any) => el.id !== id),
      });
    }
  };

  const fileImport = async (data: any) => {
    console.log(data);
  };

  const { loading, error, ...other } = state;

  const intents_api = {
    createIntent,
    getIntentById,
    updateIntent,
    deleteIntent,
    fileImport,
  };

  const data = {
    intents: other.intents,
    total: other.total,
    intents_api,
  };

  return { loading, error, data };
};

export default useIntents;
