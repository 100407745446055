import React, { useEffect } from 'react';
import { withGlobal } from 'context//';
import { Page } from './Ui';
import Header from 'components/old/Header';
import { ClickIcon } from 'components/old/Icon';
import Row from 'components/old/Row';
import Title from 'components/old/Title';
import Content from './trainingRasa/Content';
import TrainConfirmation from 'components/old/TrainConfirmation';
import {
  getMessageFromCML as getMessage,
  convertTimestampToDate,
} from 'tools/utils';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import useWords from 'hooks/words';

const Head = (props) => {
  const {
    g: { w },
    history,
  } = props;
  return (
    <Header>
      <Row>
        <ClickIcon
          className="home"
          label="home"
          icon="fas fa-arrow-left"
          onClick={() => history.goBack()}
        />
        <Title>{w.train}</Title>
      </Row>
    </Header>
  );
};

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setError':
      return { ...state, error: action.payload };
    case 'setDataMaxLength':
      return { ...state, dataMaxLength: action.payload };
    case 'setModelIterations':
      return { ...state, modelIterations: action.payload };
    default:
      return { ...state };
  }
};

export default withGlobal((props) => {
  const {
    g: { api, w },
    model,
    setModel,
  } = props;
  const [isRunning, setIsRunning] = React.useState(model.isCmliRunning);
  const [message, setMessage] = React.useState(
    getMessage(model.lastCmliRunningMessage)
  );
  const [modal, setModal] = React.useState(false);
  const [state, dispatch] = React.useReducer(_reducer, {
    error: '',
    dataMaxLength: 250,
    modelIterations: 150,
  });

  const [tr] = useWords();
  const notification = useGlobalMessage();
  useEffect(() => {
    switch (message) {
      case `Chatito process ended for model '${model.name}'`:
        notification.info(tr.chatitoEnded);
        break;
      case `Launching ECS task for model '${model.name}'`:
        notification.info(tr.ecsLaunch);
        break;
      case `ECS task created for model '${model.name}'`:
        notification.info(tr.ecsCreated);
        break;
      case 'Starting CSV and JSON parser':
        notification.info(tr.stratingCVS);
        break;
      case 'CSV and JSON parser succesfully ended':
        notification.info(tr.cvsFinished);
        break;
      case 'Lauching training instance':
        notification.info(tr.instanceLaunch);
        break;
      case 'Training instance succesfully launched':
        notification.info(tr.instanceLaunch);
        break;
      case 'Model training successfully ended':
        notification.info(tr.trainingDate);
        break;
      case 'Starting model training':
        notification.info(tr.starting);
        break;
      case `Launching chatito process for model '${model.name}'`:
        notification.info(tr.launch);
        break;
      case 'Mar Training succesfully lauched':
        notification.info(tr.starting);
        break;
      case 'Step one finished':
        notification.info(tr.stepOneFinished);
        break;
      case `The training for the model '${model.name}' is already launched.`:
        notification.error(tr.error);
        break;
      case 'Error during the process':
        notification.error(tr.errorProcess);
        break;
      case 'Error during the training process':
        notification.error(tr.errorProcess);
        break;
      default:
        notification.info(message);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const openModal = () => {
    try {
      if (!state.dataMaxLength) throw new Error('Missing dataMaxLength');
      else if (!state.modelIterations) throw new Error('Missing modelIterations');
      else setModal(true);
    } catch (err) {
      handleError(err);
    }
  };

  const submit = async () => {
    try {
      setModal(false);
      const modelID = Number(props.match.params.id);
      const res = await api.Training.trainRasa({
        model: modelID,
        config: {
          dataMaxLength: parseInt(state.dataMaxLength),
          modelIterations: parseInt(state.modelIterations),
        },
      });
      handleRes(res);
    } catch (err) {
      handleError(err);
    }
  };

  const handleRes = (res) => {
    if (res.message) setMessage(res.message);
    setIsRunning(true);
    setModel({ ...model, isCmliRunning: true });
  };

  const handleError = (err) => {
    let error = '';
    switch (err.message) {
      case 'Missing dataMaxLength':
        error = "Le maximum de phrases d'entrainement est requis";
        break;
      case 'Missing modelIterations':
        error = "le ombre de cycle d'entrainement est requis";
        break;
      default:
        setMessage(err.message);
        break;
    }
    dispatch({ type: 'setError', payload: error });
  };

  return (
    <Page>
      <Head {...props} />
      {model.lastCmliRunningDate ? (
        <h2>
          {' '}
          {tr.titleTraining} :{' '}
          {convertTimestampToDate(model.lastCmliRunningDate)}
        </h2>
      ) : (
        <h2>{tr.titleNoTraining}</h2>
      )}
      <TrainConfirmation
        {...props}
        label={w.trainConfirmation}
        state={state}
        isOpen={modal}
        close={() => setModal(false)}
        onConfirm={submit}
      />
      <Content
        {...props}
        state={state}
        dispatch={dispatch}
        onSubmit={openModal}
        disabled={isRunning}
      />
    </Page>
  );
});
