import styled, { css } from 'styled-components';
import media from 'styles/styled/media';

const Page = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 0px 50px;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  ${({ theme, padding, shadow }) => css`
    padding: ${padding};
    box-shadow: ${theme['shadows'][shadow]};
  `}
  ${media.tablet`
    margin: 10px 10px 10px 10px;
    padding: 10px;
  `}
  ${media.phone`
    margin: 5px 5px 5px 5px;
    padding: 10px;
  `}
`;

Page.defaultProps = {
  shadow: 1,
  padding: '50px',
};

export default Page;
