const success = {
  fr_FR: {
    saved: 'Sauvegardé !',
    created: 'Créé !',
    logged: 'Connecté !',
    authenticated: 'Bienvenue !',
    deleted: 'Effacé !',
    trainingPhraseAdded: 'La phrase a été ajoutée avec succès',
    synchronized: 'Les données du MAR sont maintenant à jour',
  },
  en_EN: {
    saved: 'Saved !',
    created: 'Created !',
    logged: 'Logged !',
    authenticated: 'Welcome !',
    deleted: 'Deleted !',
    trainingPhraseAdded: 'Training phrase successfully added',
    synchronized: 'The MAR data are now up to date',
  },
};
export default success;
