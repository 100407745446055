// Actions types

export const SET_INTENTS_SEARCH = 'SET_INTENTS_SEARCH';
export const CLEAR_INTENTS_SEARCH = 'CLEAR_INTENTS_SEARCH';

// Initial State

export const searchInitialState = {
  intentsSearch: '',
};

// Message Reducer

export const searchReducer = (state = searchInitialState, action) => {
  switch (action.type) {
    case SET_INTENTS_SEARCH:
      return {
        ...state,
        intentsSearch: action.payload,
      };
    case CLEAR_INTENTS_SEARCH:
      return {
        ...state,
        ...searchInitialState,
      };

    default:
      return state;
  }
};
