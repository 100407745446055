import React from 'react';

import { Icon, List, FirstText, Underline } from '../layout/Layout';

import SuccessIcon from 'icons/SuccessIcon';

const BabelResult = ({ content, type = 'babel', tr }) => {
  return (
    <span>
      <Icon>
        <SuccessIcon width="17" />
      </Icon>
      {tr.testResult}
      <List>
        {content(type).language && (
          <FirstText>
            <Underline>{tr.languageDetected}</Underline>:{' '}
            {content(type).language === 'fr'
              ? tr.babelFrench
              : content(type).language === 'en'
              ? tr.babelEnglish
              : content(type).language}
          </FirstText>
        )}
        {content(type).score && (
          <FirstText>
            <Underline>{tr.precisionScore}</Underline>: {content(type).score}
          </FirstText>
        )}
        {content(type).error && (
          <FirstText>
            <Underline>{tr.babelError}</Underline>:{' '}
            {content(type).error === 'sentence too short'
              ? tr.sentenceTooShort
              : content(type).error === 'not_supported_language'
              ? tr.notSupportedLanguage
              : content(type).error === 'not_enough_confidence'
              ? tr.notEnoughConfidence
              : content(type).error}
          </FirstText>
        )}
      </List>
    </span>
  );
};

export default BabelResult;
