import React, { useEffect, useReducer } from 'react';
import { withGlobal } from 'context//';
import Header from 'components/old/Header';
import { ClickIcon } from 'components/old/Icon';
import Row from 'components/old/Row';
import Title from 'components/old/Title';
import { Page } from './Ui';
import Content from './parameters/Content';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setModel':
      return { ...state, model: action.payload };
    default:
      return state;
  }
};

const Head = (props) => {
  const {
    g: { w },
    history,
  } = props;
  return (
    <Header>
      <Row>
        <ClickIcon
          className="home"
          label="home"
          icon="fas fa-arrow-left"
          onClick={() => history.goBack()}
        />
        <Title>{w.parameters}</Title>
      </Row>
    </Header>
  );
};

export default withGlobal((props) => {
  const [state, dispatch] = useReducer(_reducer, {
    model: props.model,
  });

  const {
    g: {
      api: { Models },
    },
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    const _getModel = async () => {
      const res = await Models._getById(id);
      if (res && !res.error) {
        dispatch({ type: 'setModel', payload: res });
      }
    };
    _getModel();
  }, [id, Models]);

  return (
    <Page>
      <Head {...props} />
      <Content {...props} state={state} dispatch={dispatch} />
    </Page>
  );
});
