import React from 'react';
import Server from './Server';
import Loading from './Loading';
import Success from './Success';
import Error from './Error';
import styled from 'styled-components';

interface StyleProps {
  readonly success?: boolean;
}

const Style = styled.div<StyleProps>`
  position: absolute;
  right: 0;
  bottom: 15%;
  width: 100%;
  color: white;
  font-size: 1.3rem;
  z-index: 99;
  background: ${({ theme, success }) =>
    theme.colors[success ? 'success' : 'error']};
  padding: ${({ theme }) => `${theme.spacing}px`};
`;

const Indicators: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  return (
    <React.Fragment>
      <Loading />
      <Success />
      <Error />
      <Server />
      {props.children}
    </React.Fragment>
  );
};

export default Indicators;
export { Style };
