import React from 'react';
import styled from 'styled-components';
import Header from 'components/old/Header';
import Flex from 'components/old/Flex';
import Title from 'components/old/Title';
import Modal from 'components/old/Modal';
import { Spacing } from 'components/Layout';
import Button from 'components/old/Button';

const ErrorHead = styled.div`
  background-color: ${(p) => p.theme['colors']['secondary']};
  position: sticky;
  top: 0;
  width: 100%;
  color: white;
  padding: 10px;
  padding-left: 25px;
  font-weight: 700;
  text-transform: capitalize;
`;

const ErrorBody = styled.div`
  padding: 25px;
`;

const TextError = styled.div`
  color: ${({ theme, color }) => theme['colors'][color]};
`;

const ErrorModal = (props) => {
  const { error, open, tr, close } = props;

  return (
    <Modal
      padding="1px"
      borderRadius="0px"
      isOpen={open}
      close={close}
      selector="#root"
    >
      <ErrorHead>{tr.error}</ErrorHead>
      <ErrorBody>
        <Header>
          <Title>{tr.messageDetected}</Title>
        </Header>
        <Spacing top="1rem" bottom="1rem">
          <TextError>{error}</TextError>
        </Spacing>
        <Flex justifyContent="flex start" margin="10px 0px;">
          <Button color="secondary" onClick={close}>
            {tr.close}
          </Button>
        </Flex>
      </ErrorBody>
    </Modal>
  );
};

export default ErrorModal;
