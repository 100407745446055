import React, { useState } from 'react';
import Header from 'components/old/Header';
import Import from './Import';
import Row from 'components/old/Row';
import Title from 'components/old/Title';
import { ClickIcon } from 'components/old/Icon';
import TextField from '@material-ui/core/TextField';
import Button from 'components/old/Button';
import Create from 'components/old/CreateFactory';

export default (props) => {
  const {
    g: {
      w,
      languages,
      api: { Intents },
      ...g
    },
    dispatch,
    search,
    setSearch,
    history,
    intents,
    match: {
      params: { id },
    },
  } = props;
  const [add, setAdd] = useState(false);
  const [create, setCreate] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <Header>
      <Import isOpen={add} close={() => setAdd(false)} {...props} />
      <Create
        {...props}
        label={w.intentCreation}
        isOpen={create}
        loading={loading}
        close={() => setCreate(false)}
        elements={[{ name: 'title', label: w.intentName, type: 'input' }]}
        onCreate={async (state) => {
          if (state.title) {
            setLoading(true);
            let res = await Intents._post({ ...state, models: [id] });
            if (res && res.createdAt) {
              g.dispatch({
                type: 'setStatus',
                payload: {
                  col: 'success',
                  msg: w.creationSuccess,
                },
              });
              dispatch({
                type: 'setIntents',
                payload: intents.concat(res),
              });
            } else
              g.dispatch({
                type: 'setStatus',
                payload: {
                  col: 'secondary',
                  msg: w.error,
                },
              });
            setLoading(false);
            return { title: '', language: null };
          }
        }}
      />
      <Row>
        <ClickIcon
          className="home"
          label="home"
          icon="fas fa-arrow-left"
          onClick={() => history.goBack()}
        />
        <TextField
          variant="outlined"
          type="search"
          value={search}
          placeholder={`${w.Search}`}
          onChange={(e) => {
            handleSearch(e);
          }}
        />
      </Row>
      <Title>{w.intents}</Title>
      <Row>
        <Button
          color="secondary"
          textTransform="capitalize"
          onClick={() => setAdd(true)}
        >
          {`${w.intentImport}`}
        </Button>
        <Button
          color="secondary"
          textTransform="capitalize"
          onClick={() => setCreate(true)}
        >
          {`${w.intentCreate}`}
        </Button>
      </Row>
    </Header>
  );
};
