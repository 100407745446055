import React from 'react';
import useWords from 'hooks/words';
import Typography from '@material-ui/core/Typography';
import { extractStatusMessage, extractStatusDate } from 'tools/utils';

type Status = {
  id: number;
  isCmlaRunning: boolean;
  isCmliRunning: boolean;
  lastCmlaRunningDate: string;
  lastCmliRunningDate: string;
  lastCmlaRunningMessage: string;
  lastCmliRunningMessage: string;
};
const Status = (props: { model: Status }) => {
  const [tr] = useWords();
  const [cmla, setCmla] = React.useState('');
  const [cmli, setCmli] = React.useState('');
  const [extractedCmlaDate, setExtractedCmlaDate] = React.useState('');
  const [extractedCmliDate, setExtractedCmliDate] = React.useState('');
  const [cmla_date, setCmlaDate] = React.useState('');
  const [cmli_date, setCmliDate] = React.useState('');
  React.useEffect(() => {
    let cmla_message = extractStatusMessage(props.model.lastCmlaRunningMessage);
    let cmli_message = extractStatusMessage(props.model.lastCmliRunningMessage);
    /*
    /*This may be useful in future, but for now it's useless
    */
    // let cmla_date = extractStatusMessage(props.model.lastCmlaRunningDate);
    // let cmli_date = extractStatusMessage(props.model.lastCmliRunningDate);
    let extractedCmlaDate = extractStatusDate(
      props.model.lastCmlaRunningMessage
    );
    let extractedCmliDate = extractStatusDate(
      props.model.lastCmliRunningMessage
    );
    let dateCmla = new Date(extractedCmlaDate)
      .toLocaleDateString()
      .substr(0, 10)
      .replace(/-/g, '/');
    let dateCmli = new Date(extractedCmliDate)
      .toLocaleDateString()
      .substr(0, 10)
      .replace(/-/g, '/');
    setCmla(cmla_message);
    setCmli(cmli_message);
    setExtractedCmlaDate(extractedCmlaDate);
    setExtractedCmliDate(extractedCmliDate);
    setCmlaDate(dateCmla);
    setCmliDate(dateCmli);
  }, [
    props.model.id,
    props.model.lastCmlaRunningMessage,
    props.model.lastCmliRunningMessage,
    props.model.lastCmlaRunningDate,
    props.model.lastCmliRunningDate,
  ]);
  return (
    <div>
      <Typography variant="caption">
        {cmla && `${tr.leoMar} : ${cmla}`}
        {extractedCmlaDate && ` - ${cmla_date}`}
      </Typography>
      <br />
      <Typography variant="caption">
        {cmli && `${tr.leoRasa} : ${cmli}`}
        {extractedCmliDate && ` - ${cmli_date}`}
      </Typography>
    </div>
  );
};
export default Status;
