import React, { Fragment } from 'react';
import styled from 'styled-components';

import {
  Icon,
  List,
  FirstText,
  SecondText,
  Underline,
  Padding,
} from '../layout/Layout';

import SuccessIcon from 'icons/SuccessIcon';

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  border: 1px solid white;
  border-radius: 10px;
  width: 100%;
  max-width: 18rem;
  margin-top: 0.5rem;
`;

const Item = styled.div`
  ${(p) => p.column && `grid-column: ${p.column};`}
  ${(p) => p.row && `grid-row: ${p.row};`}
  ${(p) => p.borderright && `border-right: 1px solid white;`}
  ${(p) => p.borderbottom && `border-bottom: 1px solid white;`}
  width: 100%;
  height: 100%;
`;

const Number = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  height: 100%;
`;

const Content = styled.div`
  line-height: 1.5rem;
`;

const RasaResult = ({ data, type = 'rasa', tr }) => {
  return (
    <span>
      <Icon>
        <SuccessIcon width="17" />
      </Icon>
      {tr.testResult}
      <List>
        <FirstText>
          <Underline>{tr.resultText}</Underline> : "
          {data(type).text && data(type).text}"
        </FirstText>

        <FirstText>
          <Underline>{tr.resultEntities}</Underline>
        </FirstText>
        <SecondText>
          {data(type).entities.length === 0 ? (
            `${tr.resultNoEntities}`
          ) : (
            <Box>
              {data(type).entities.map((el, i) => (
                <Fragment key={`entities${i}`}>
                  <Item
                    row={i + 1}
                    column={1}
                    borderright={true}
                    borderbottom={
                      i !== data(type).entities.length - 1 &&
                      data(type).entities.length > 1
                    }
                  >
                    <Number>{i + 1}</Number>
                  </Item>
                  <Item
                    row={i + 1}
                    column={2}
                    borderbottom={
                      i !== data(type).entities.length - 1 &&
                      data(type).entities.length > 1
                    }
                  >
                    <Content>
                      <Padding padding="0.5rem">
                        <div>
                          {tr.resultName} "{el.word}"
                        </div>
                        <div>
                          {tr.resultValue} "{el.title}"
                        </div>
                        <div>
                          {tr.resultConfidence} {el.similarity}
                        </div>
                        <div>
                          {tr.resultId} {el.id}
                        </div>
                      </Padding>
                    </Content>
                  </Item>
                </Fragment>
              ))}
            </Box>
          )}
        </SecondText>

        <FirstText>
          <Underline>{tr.resultIntent}</Underline>
        </FirstText>
        <Content>
          <SecondText>
            {tr.resultName} {data(type).intent.name && data(type).intent.name}
          </SecondText>
          <SecondText>
            {tr.resultConfidence}{' '}
            {data(type).intent.confidence && data(type).intent.confidence}
          </SecondText>
          <SecondText>
            {tr.resultId} {data(type).intent.id && data(type).intent.id}
          </SecondText>
        </Content>

        <FirstText>
          <Underline>{tr.resultIntentRanking}</Underline> :{' '}
          <SecondText>
            {data(type).intent_ranking.length === 0 ? (
              `${tr.noResult}`
            ) : (
              <Box>
                {data(type).intent_ranking.map((el, i) => (
                  <Fragment key={`intentranking${i}`}>
                    <Item
                      row={i + 1}
                      column={1}
                      borderright={true}
                      borderbottom={
                        i !== data(type).intent_ranking.length - 1 &&
                        data(type).intent_ranking.length > 1
                      }
                    >
                      <Number>{i + 1}</Number>
                    </Item>
                    <Item
                      row={i + 1}
                      column={2}
                      borderbottom={
                        i !== data(type).intent_ranking.length - 1 &&
                        data(type).intent_ranking.length > 1
                      }
                    >
                      <Content>
                        <Padding padding="0.5rem">
                          <div>
                            {tr.resultName} "{el.name}"
                          </div>
                          <div>
                            {tr.resultConfidence} {el.confidence}
                          </div>
                          <div>
                            {tr.resultId} {el.id}
                          </div>
                        </Padding>
                      </Content>
                    </Item>
                  </Fragment>
                ))}
              </Box>
            )}
          </SecondText>
        </FirstText>
      </List>
    </span>
  );
};

export default RasaResult;
