import React from 'react';
import { withGlobal } from 'context//';
import Button from 'components/old/Button';
import styled from 'styled-components';
import Modal from 'components/old/Modal';
import Flex from 'components/old/Flex';
import Typo from 'components/old/Typo';
import Title from 'components/old/Title';
import Header from 'components/old/Header';
import Loading from 'components/old/Loading';

const Typobreak = styled(Typo)`
  white-space: pre-line;
`;

export default withGlobal((props) => {
  const {
    g: { w },
    label,
    state,
    isOpen,
    loading,
    close,
    onConfirm,
  } = props;
  return (
    <Modal small isOpen={isOpen} close={close} selector="#root">
      {loading && <Loading />}
      <Header>
        <Title>{label}</Title>
      </Header>
      <Typobreak color="secondary">
        {Object.entries(state).map(([key, value]) =>
          value ? `${key}: ${value}\n` : ''
        )}
      </Typobreak>
      <Flex justifyContent="space-between" margin="10px 0px 0px 0px">
        <Button onClick={close}>{w.cancel}</Button>
        <Button color="secondary" onClick={onConfirm}>
          {w.confirm}
        </Button>
      </Flex>
    </Modal>
  );
});
