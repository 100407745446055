import React from 'react';
import PapaParse from 'papaparse';
import { File } from 'components/old/Button';

const FileImport = (props) => {
  const {
    g: { w },
  } = props;
  const handleChangeFile = (e) => {
    const { parserOptions = {}, onError, onFileLoaded } = props;
    let reader = new FileReader();
    const filename = e.target.files[0].name;
    reader.onload = (event) => {
      const csvData = PapaParse.parse(
        event.target.result,
        Object.assign(parserOptions, {
          error: onError,
        })
      );
      onFileLoaded(csvData.data, filename);
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <File>
      {w.import}
      <input type="file" accept=".csv,.txt" onChange={handleChangeFile} />
    </File>
  );
};

export default FileImport;
