import React from 'react';
import styled from 'styled-components';
import Typo from 'components/old/Typo';

const Error = styled.div`
  width: 100%;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Error>
          <div className="flex column flex-start">
            <Typo color="secondary"> {this.state.error} </Typo>
            {process.env.REACT_APP_DEV === 'true' && (
              <details open style={{ background: 'rgba(206, 17, 38, 0.05)' }}>
                <summary style={{ background: 'rgba(206, 17, 38, 0.15)' }}>
                  {this.state.error && this.state.error.toString()}
                </summary>
                <div>{this.state.errorInfo.componentStack}</div>
              </details>
            )}
          </div>
        </Error>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
