import React from 'react';
import styled from 'styled-components';
import { Spacing } from 'components/Layout';
import Header from 'components/old/Header';
import TextField from '@material-ui/core/TextField';
import Button from 'components/old/Button';
import Title from 'components/old/Title';
import Typo from 'components/old/Typo';
import Loading from 'components/old/Loading';
import Modal from 'components/old/Modal';
import Flex from 'components/old/Flex';

const SearchAndImport = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  padding: 1rem 1rem 0 0;
  flex: 1;
`;

const Scrollable = styled.div`
  max-height: 90vh;
  overflow: scroll;
`;

export const Head = (props) => {
  const {
    g: { w },
    title,
    state: { selected },
    dispatch,
    setSearch,
  } = props;

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  return (
    <Header>
      <Title>{title}</Title>
      <SearchAndImport>
        <Spacing right="5px">
          <TextField
            size="small"
            variant="outlined"
            type="search"
            placeholder={`${w.Search}`}
            onChange={(e) => handleSearch(e)}
            margin="0px 0.5rem 0px 0px"
          />
        </Spacing>
        <Button
          color="secondary"
          onClick={() => {
            if (selected.length) dispatch({ type: 'setAdd', payload: true });
          }}
        >
          <Typo textTransform="uppercase">{w.add}</Typo>
        </Button>
      </SearchAndImport>
    </Header>
  );
};

export const OnAdd = (props) => {
  const {
    g: { w },
    title,
    items,
    isOpen,
    loading,
    close,
    onAdd,
  } = props;
  return (
    <Modal small isOpen={isOpen} close={close} selector="#root">
      {loading && <Loading />}
      <Scrollable>
        <Header>
          <Title>{title}</Title>
        </Header>
        <Flex flexDirection="column">
          {items.map((el) => (
            <Typo color="secondary" key={el.id}>
              - {el.title}
            </Typo>
          ))}
        </Flex>
        <Flex justifyContent="space-between" margin="10px 0px 0px 0px">
          <Button onClick={close}>{w.cancel}</Button>
          <Button color="secondary" onClick={onAdd}>
            {w.confirm}
          </Button>
        </Flex>
      </Scrollable>
    </Modal>
  );
};
