import React from 'react';
import { Icon, List, FirstText } from '../layout/Layout';
import SuccessIcon from 'icons/SuccessIcon';

const MarResult = ({ tr, data }) => (
  <span>
    <Icon>
      <SuccessIcon width="17" />
    </Icon>
    {tr.testResult}
    {data.map((el, i, els) => {
      return (
        <>
          <List>
            <FirstText>{`${tr.id} ${el.id}`}</FirstText>
            <FirstText>{`${tr.similarity} ${el.similarity.toFixed(
              3
            )}`}</FirstText>
            <FirstText>{`${tr.theme} ${el.theme}`} </FirstText>
            <FirstText>{`${tr.word} ${el.word}`} </FirstText>
          </List>
          {els[i + 1] && <hr />}
        </>
      );
    })}
  </span>
);

export default MarResult;
