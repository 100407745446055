const models = {
  fr_FR: {
    createModel: "Création d'un modèle",
    deleteModel: "Suppression d'un modèle",
    leoRasa: 'Leo-RASA',
    leoMar: 'Leo-MAR',
    hasAlreadyModel: 'Un modèle existe déjà pour ce bot dans cette langue',
    hasAlreadyAtLeastOneModel:
      'Un modèle existe déjà pour ce bot dans au moins une de ces langues',
    hasAlreadyName: 'Un modèle avec le même nom existe déjà',
    missingField: 'Tous les champs doivent être remplis',
  },
  en_EN: {
    createModel: 'Create a model',
    deleteModel: 'Delete a model',
    leoRasa: 'Leo-RASA',
    leoMar: 'Leo-MAR',
    hasAlreadyModel: 'A model already exists for this bot in this language',
    hasAlreadyAtLeastOneModel:
      'A model already exists for this bot in at least one of the selected languages',
    hasAlreadyName: 'There is already a model with the same name',
    missingField: 'All fields must be completed',
  },
};

export default models;
