import styled, { keyframes } from 'styled-components';
import BaseAnimation from './Animation';

const GrowAnimation = keyframes`
  0% { opacity: 0; transform: scale(0.5); }
  100% { opacity: 1; transform: scale(1); }
`;

const Grow = styled(BaseAnimation)`
  z-index: ${({ zIndex }) => zIndex};
  animation-name: ${GrowAnimation};
`;

Grow.defaultProps = {
  zIndex: 'initial',
};

export default Grow;
