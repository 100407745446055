import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Table, {
  TWrap,
  THEAD,
  TBody,
  TRow,
  THead,
  TCell,
} from 'components/old/Table';
import GradeRoundedIcon from '@material-ui/icons/GradeRounded';
import Checkbox from 'components/old/Checkbox';
import Flex from 'components/old/Flex';
import Pagination from 'components/old/Pagination';
import Typo from 'components/old/Typo';

export default (props) => {
  const {
    g: { w },
    items,
    models: { total, skip, take },
    dispatch,
  } = props;

  const [monolithModels, setMonolithModels] = useState([]);
  const [normalModels, setNormalModels] = useState([]);
  const [duplicates, setDuplicates] = useState([]);

  useEffect(() => {
    const monolithArray = items.filter(
      (model) => !model.referenceBotId || model.referenceBotId === '0'
    );
    setMonolithModels(monolithArray);
    const normalArray = items.filter(
      (model) => model.referenceBotId && model.referenceBotId !== '0'
    );
    setNormalModels(normalArray);
  }, [items]);

  const FindDuplicateModels = (modelarray) => {
    const object = {};
    const result = [];

    modelarray.forEach((item) => {
      if (!object[item.name]) object[item.name] = 0;
      object[item.name] += 1;
    });

    for (const prop in object) {
      if (object[prop] >= 2) {
        result.push(prop);
      }
    }

    return result;
  };

  useEffect(() => {
    const duplicateArray = FindDuplicateModels(items);
    setDuplicates(duplicateArray);
  }, [items]);

  return (
    <Flex flex={3} flexDirection="column" alignItems="center">
      <TWrap>
        <Table layout="auto">
          <THEAD>
            <TRow>
              <THead>{w.modelFilter}</THead>
            </TRow>
          </THEAD>
          <TBody>
            {monolithModels.map((el) => (
              <Model
                key={el.id}
                actualmodel={el}
                {...props}
                duplicates={duplicates}
              />
            ))}
            {normalModels.map((el) => (
              <Model
                key={el.id}
                actualmodel={el}
                {...props}
                duplicates={duplicates}
              />
            ))}
          </TBody>
        </Table>
      </TWrap>
      <Pagination
        activePage={(skip + 10) / take}
        itemsCountPerPage={take}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        onChange={(page) => {
          dispatch({ type: 'setModels', payload: { skip: (page - 1) * take } });
        }}
      />
    </Flex>
  );
};

const ModelContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  direction: row;
  width: 100%;
`;

const StyledTypo = styled.div`
  display: flex;
  justify-items: flex-end;
  max-width: 80%;
  text-align: right;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
`;

const StarIcon = styled.span`
  margin-top: 0px;
  margin-left: 3px;
`;

const Model = (props) => {
  const {
    actualmodel,
    duplicates,
    models: { selected },
    dispatch,
  } = props;

  const isMonolith =
    !actualmodel.referenceBotId || actualmodel.referenceBotId === '0';

  return (
    <TRow>
      <TCell>
        <ModelContent>
          <Checkbox
            checked={selected.some((el) => el.id === actualmodel.id)}
            onChange={() => {
              !selected.some((el) => el.id === actualmodel.id)
                ? dispatch({ type: 'onSelectModels', payload: actualmodel })
                : dispatch({
                    type: 'onDeselectModels',
                    payload: actualmodel,
                  });
            }}
          />
          <StyledTypo>
            <Typo>
              {' '}
              <Title>
                {actualmodel.name}{' '}
                {duplicates.some((el) => el === actualmodel.name) && (
                  <span>&nbsp;({actualmodel.language.code})</span>
                )}
                {isMonolith && (
                  <StarIcon>
                    <GradeRoundedIcon
                      color="secondary"
                      style={{ fontSize: 14 }}
                    />
                  </StarIcon>
                )}
              </Title>
            </Typo>
          </StyledTypo>
        </ModelContent>
      </TCell>
    </TRow>
  );
};
