import React from 'react';
import styled, { css } from 'styled-components';
import Pagination from 'react-js-pagination';

const StylePagination = styled.div`
  width: 100%;
  max-width: 420px;
  padding: 0px 10px;
  margin: auto;
  > .pagination {
    margin: 10px 0px;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => css`
      > li .link {
        font-weight: 600;
        color: ${(p) =>
          p.persist && p.totalItemsCount <= p.itemsCountPerPage
            ? 'lightgray'
            : `${theme['colors']['primary']}`};
        text-decoration: none;
        &.active {
          font-size: 1.2rem;
          color: ${(p) =>
            p.persist && p.totalItemsCount <= p.itemsCountPerPage
              ? 'lightgray'
              : `${theme['colors']['secondary']}`};
        }
      }
    `};
  }
`;

export default (props) => {
  if (!props.persist && props.totalItemsCount <= props.itemsCountPerPage)
    return null;
  return (
    <StylePagination
      persist={props.persist}
      totalItemsCount={props.totalItemsCount}
      itemsCountPerPage={props.itemsCountPerPage}
    >
      <Pagination linkClass="link" activeLinkClass="active" {...props} />
    </StylePagination>
  );
};
