const nouns = {
  fr_FR: {
    name: 'nom',
    status: 'statut',
    general: 'général',
    referenceBotId: 'Nom du bot',
    modelName: 'Nom du model',
    sharable: 'partager',
    language: 'langue',
    public: 'public',
    isAMonolith: 'Est un monolithe',
    languages: 'Langages',
    options: 'options',
  },
  en_EN: {
    name: 'name',
    status: 'status',
    general: 'general',
    language: 'Language',
    public: 'public',
    isAMonolith: 'Is a monolithe',
    referenceBotId: "Bot's name",
    modelName: "Model's name",
    sharable: 'share',
    languages: 'languages',
    options: 'options',
  },
};

export default nouns;
