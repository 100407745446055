import React from 'react';
import Input from 'components/old/Input';
import styled from 'styled-components';
import Title from 'components/old/Title';
import Button from 'components/old/Button';
import Typo from 'components/old/Typo';
import { Spacing } from 'components/Layout';

const Content = (props) => {
  const { state, dispatch, onSubmit } = props;
  const {
    g: { w },
    disabled,
  } = props;

  return (
    <Body>
      <Spacing bottom="1rem">
        <Title fontWeight="bold">{w.trainingMaxLengthParams}</Title>
        <Buttons>
          <Input
            value={state.dataMaxLength}
            type="number"
            placeholder={state.dataMaxLength}
            min="10"
            max="1000"
            onChange={(e) =>
              dispatch({ type: 'setDataMaxLength', payload: e.target.value })
            }
          />
        </Buttons>
      </Spacing>
      <Spacing>
        <Title fontWeight="bold">{w.trainingModelsParams}</Title>
        <Buttons>
          <Input
            value={state.modelIterations}
            type="number"
            placeholder={state.modelIterations}
            min="5"
            max="300"
            onChange={(e) =>
              dispatch({ type: 'setModelIterations', payload: e.target.value })
            }
          />
        </Buttons>
      </Spacing>
      {state.error && <Typo color="secondary">{state.error}</Typo>}
      <Button
        disabled={disabled}
        margin="1rem 0.5rem"
        color="secondary"
        onClick={onSubmit}
      >
        {w.toTrain}
      </Button>
    </Body>
  );
};

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 10px;
  width: 100%;
  height: inherit;
  position: relative;
  > input {
    width: 100%;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 0.3rem;
`;

export default Content;
