// Actions types

export const SET_SELECTION_RELOAD = 'SET_SELECTION_RELOAD';

// Initial State

export const reloadInitialState = {
  selectionReload: false,
};

// Message Reducer

export const reloadReducer = (state = reloadInitialState, action) => {
  switch (action.type) {
    case SET_SELECTION_RELOAD:
      return {
        ...state,
        selectionReload: action.payload,
      };

    default:
      return state;
  }
};
