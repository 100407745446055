import React from 'react';

export type Pagination = {
  skip: number;
  take: number;
  total: number;
};

export type Methods = {
  first: () => void;
  last: () => void;
  next: () => void;
  prev: () => void;
  skip: (nb: number) => void;
  page: (nb: number) => void;
};

type Actions =
  | { type: 'first' }
  | { type: 'last' }
  | { type: 'next' }
  | { type: 'prev' }
  | { type: 'skip'; payload: number }
  | { type: 'page'; payload: number }
  | { type: 'total'; payload: number };

const reducer = (state: Pagination, action: Actions) => {
  switch (action.type) {
    case 'first':
      return { ...state, skip: 0 };
    case 'last':
      return { ...state, skip: state.total - state.take };
    case 'next':
      return { ...state, skip: (state.skip + state.take) % state.total };
    case 'prev':
      return { ...state, skip: state.skip - state.take };
    case 'skip':
      return { ...state, skip: action.payload };
    case 'total':
      return { ...state, total: action.payload };
    case 'page':
      return { ...state, skip: state.take * action.payload - state.take };
    default:
      return { ...state };
  }
};

const usePagination = (take?: number, total?: number) => {
  const [pagination, dispatch] = React.useReducer(reducer, {
    skip: 0,
    take: take || 20,
    total: total || 0,
  });

  React.useEffect(() => {
    setTotal(total || 0);
  }, [total]);

  const first = () => dispatch({ type: 'first' });
  const last = () => dispatch({ type: 'last' });
  const next = () => dispatch({ type: 'next' });
  const prev = () => dispatch({ type: 'prev' });
  const skip = (nb: number) => dispatch({ type: 'skip', payload: nb });
  const page = (nb: number) => dispatch({ type: 'page', payload: nb });
  const setTotal = (nb: number) => dispatch({ type: 'total', payload: nb });

  const pagination_api: Methods = {
    first,
    last,
    next,
    prev,
    skip,
    page,
  };

  return { pagination, pagination_api };
};

export default usePagination;
