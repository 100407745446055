import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Office from './Office';
import Desk from './Desk';
import PrivateRoute from './PrivateRoute';
import Message from 'components/Message';
import { useStore } from 'store/store';

const Routes = () => {
  const [{ message }] = useStore();

  return (
    <BrowserRouter basename="/">
      <Switch>
        <PrivateRoute path="/office" redirectPath="/" component={Office} />
        <Route strict path="/" component={Desk} />
      </Switch>
      {message.content.text && (
        <Message
          type={message.content.type}
          autoClose={message.content.autoClose}
        >
          {message.content.text}
        </Message>
      )}
    </BrowserRouter>
  );
};
export default Routes;
