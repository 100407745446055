const errors = {
  fr_FR: {
    missingEmail: 'Merci de saisir votre e-mail',
    missingPassword: 'Veuillez saisir votre mot de passe',
    badFormatEmail: "L'email est invalide",
    passwordAndEmailNotMatch: 'Les identifiants sont incorrects',
    connexionLost: 'La connexion a été perdue...',
    error: 'Erreur',
    failedToFetch: 'La requête a echoué',
    couldNotAddTrainingPhrase: "La phrase n'a pa pu être ajoutée à l'intent",
    couldNotAddTrainingPhraseDouble:
      "La phrase n'a pa pu être ajoutée, vérifiez qu'elle n'est pas déjà présente dans l'intent",
    messageDetected: 'Message détécté',
    errorInResult: 'Le résultat comporte une erreur',
    errWrongEntityData: 'La valeur ne peut pas être enregistrée: une valeur avec la même \'expression\' existe déjà pour cet intent',
    errorSearchModel: 'Le modèle n\'existe pas',
    noSearchResult: 'Aucun résultat pour cette recherche',
  },
  en_EN: {
    missingEmail: 'Enter your email',
    missingPassword: 'Enter your password',
    badFormatEmail: 'Bad format email',
    passwordAndEmailNotMatch: 'Bad credentials',
    connexionLost: 'Connexion lost...',
    error: 'Error',
    failedToFetch: 'Failed to fetch',
    couldNotAddTrainingPhrase:
      'The training phrase could not be added to intent',
    couldNotAddTrainingPhraseDouble:
      'The training phrase could not be added, please check if it is not already present in intent',
    messageDetected: 'Message detected',
    errorInResult: 'The result contains an error',
    errWrongEntityData: 'Value can\'t be saved: A value with the same \'expression\' already exist for this intent',
    errorSearchModel: 'The model does not exist',
    noSearchResult: 'No results for this search',
  },
};

export default errors;
