import React, { useEffect, useReducer } from 'react';
import useWords from 'hooks/words';
import Typo from 'components/old/Typo';
import Select from 'components/old/Select';
import Input from 'components/old/Input';
import Title from 'components/old/Title';
import styled from 'styled-components';
import Button from 'components/old/Button';

import { useGlobalMessage } from 'hooks/useGlobalMessage';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  height: inherit;
  position: relative;
  > input {
    width: 100%;
  }
`;

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setState':
      return { ...state, ...action.payload };
    case 'setName':
      return { ...state, name: action.payload };
    case 'setLang': {
      return { ...state, language: action.payload };
    }
    case 'setPrivacy':
      return { ...state, isSharable: action.payload };
    default:
      return state;
  }
};

export default (props) => {
  const [tr] = useWords();
  const {
    g,
    state: { model },
    match: {
      params: { id },
    },
  } = props;
  const {
    w,
    languages,
    api: { Models },
  } = g;

  const notification = useGlobalMessage();

  const [state, dispatch] = useReducer(_reducer, model);

  useEffect(() => {
    dispatch({ type: 'setState', payload: model });
  }, [model]);

  return (
    <Body>
      <Title fontWeight="bold">{tr.general}</Title>
      <Typo margin="5px 0px">{w.modelTitle}:</Typo>
      <Input
        defaultValue={model.name}
        onChange={(e) => dispatch({ type: 'setName', payload: e.target.value })}
      />
      <Typo margin="5px 0px">{w.modelLang}</Typo>
      <Select
        label={state.language.name}
        options={languages.map((l) => ({
          key: l.id,
          id: l.id,
          label: l.name,
          code: l.code,
        }))}
        onChange={(e) => {
          const item = JSON.parse(e.target.value);
          dispatch({ type: 'setLang', payload: item });
        }}
      />
      <Button
        margin="2rem 0rem"
        onClick={async () => {
          const res = await Models._put(id, {
            name: state.name,
            language: state.language.id,
            isSharable: state.isSharable,
          });
          if (res) {
            g.dispatch({ type: 'setSuccess', payload: w.saveSuccess });
            notification.success(tr.saved);
          } else g.dispatch({ type: 'setError', payload: w.error });
        }}
      >
        {w.validate}
      </Button>
    </Body>
  );
};
