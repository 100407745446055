import React, { useState } from 'react';
import styled from 'styled-components';
import Loading from 'components/old/Loading';
import Modal from 'components/old/Modal';
import Button from 'components/old/Button';
import Select from 'components/old/Select';
import Input from 'components/old/Input';
import media from 'styles/styled/media';
import Subtitle from 'components/old/Subtitle';
import Grow from 'components/old/animations/Grow';

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
  > * {
    display: flex;
    flex: 1;
  }
  > :nth-child(2) {
    margin: 0px 5px;
  }

  ${media.tablet`
    flex-direction: column;
    align-items: start;
    >:nth-child(2) {
      margin: 0px
    }
  `}
`;

const Relative = styled(Grow)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const Error = styled.div`
  color: tomato;
  margin-bottom: 5px;
`;

const NewAppellation = (props) => {
  const {
    g: { w },
    edit,
    loading,
    cases,
    create,
    setModal,
  } = props;

  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [category, setCategorie] = useState(null);
  const [referenceId, setReferenceId] = useState(null);

  return (
    <Modal isOpen={create} close={() => setModal(false)} selector="#root">
      <Relative>
        {(loading || edit) && <Loading />}
        <Subtitle>{w.createAppellation}</Subtitle>
        <Footer>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
          <Select
            label={w.category}
            onChange={(e) => {
              try {
                let value = JSON.parse(e.target.value);
                setCategorie(value.label);
              } catch (err) {
                setCategorie('');
              }
            }}
            options={[
              { key: 'type', label: 'type' },
              { key: 'theme', label: 'theme' },
              { key: 'tag', label: 'tag' },
              { key: 'public', label: 'public' },
              { key: 'place', label: 'place' },
              { key: 'event', label: 'event' },
            ]}
          />
          <RefSelect
            loading={loading}
            label={w.referenceId}
            onChange={(e) => {
              try {
                let value = JSON.parse(e.target.value);
                setReferenceId(value.key);
              } catch (err) {
                setReferenceId(null);
              }
            }}
            category={category}
            cases={cases}
          />
        </Footer>
        {error && <Error> {error} </Error>}
        <Button
          disabled={loading}
          onClick={() => {
            if (!name || !category || !referenceId) {
              setError(w.allFieldAreRequired);
            } else {
              setError('');
              props.onCreate({
                name: name,
                category: category,
                referenceId: referenceId,
              });
            }
          }}
        >
          {w.add}
        </Button>
      </Relative>
    </Modal>
  );
};

export default NewAppellation;

const RefSelect = (props) => {
  const { category, cases, loading, ...config } = props;
  const { types, themes, tags, publics, places, events } = cases;
  switch (category) {
    case 'type':
      return (
        <Select
          {...config}
          disabled={!types.length && loading}
          options={types.map((el) => ({ key: el.id, label: el.slug }))}
        />
      );
    case 'theme':
      return (
        <Select
          {...config}
          disabled={!themes.length && loading}
          options={themes.map((el) => ({ key: el.id, label: el.slug }))}
        />
      );
    case 'tag':
      return (
        <Select
          {...config}
          disabled={!tags.length && loading}
          options={tags.map((el) => ({ key: el.id, label: el.slug }))}
        />
      );
    case 'public':
      return (
        <Select
          {...config}
          disabled={!publics.length && loading}
          options={publics.map((el) => ({ key: el.id, label: el.slug }))}
        />
      );
    case 'place':
      return (
        <Select
          {...config}
          disabled={!places.length && loading}
          options={places.map((el) => ({ key: el.id, label: el.name }))}
        />
      );
    case 'event':
      return (
        <Select
          {...config}
          disabled={!events.length && loading}
          options={events.map((el) => ({ key: el.id, label: el.title }))}
        />
      );
    default:
      return <Select {...config} disabled />;
  }
};
