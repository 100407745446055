import React, { useState, useReducer, useEffect } from 'react';
import { withGlobal } from 'context//';
import { useStore } from 'store/store';
import { CLEAR_VISIBLE, SET_PAUSE } from 'store/redirectlottie';
import { useWindowSize } from 'hooks/useWindowSize';
import useWords from 'hooks/words';
import { Page } from './Ui';
import media from 'styles/styled/media';
import styled from 'styled-components';
import Sentences from './intent/Sentences';
import Entities from './intent/Entities.js';
import useEntities from 'api/rest/hooks/useEntities';
import Row from 'components/old/Row';
import useTrainingPhrases from 'api/rest/hooks/useTrainingPhrases';
import Head from './intent/Header';

import WarningIcon from 'icons/WarningIcon';

import Lottie from 'react-lottie';
import success from 'animations/success.json';

const Content = styled(Row)`
  flex: 1;
  width: 100%;
  color: ${({ theme }) => theme['colors']['primary']};
  align-items: baseline;
  > :not(:last-child) {
    margin-right: 1rem;
  }
  > input {
    width: 100%;
  }
  ${media.tablet`
>:not(:last-child) {
margin-right: 0.3rem;
}
`}
`;

const LottiePosition = styled.div`
  display: inline-block;
  border-radius: 15rem;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1.5rem 2.9rem 5.5rem 3.3rem;
  ${(p) => p.hide && 'display: none'}
  position: absolute;
  top: 90px;
  right: ${(p) => `${Math.round(p.width / 2) - 80}px`};
  z-index: 1000000000000000000000;
`;

const LottieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LottieText = styled.div`
  display: inline-block;
  ${(p) => p.hide && 'display: none'}
  font-size: 1rem;
  margin-top: -3rem;
  letter-spacing: 0.3rem;
  color: '#039ea9';
  z-index: 1000000000000000000000;
`;

const MonolithWarning = styled.div`
  font-size: 0.8rem;
  max-width: 60%;
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  background-color: #e66e6e;
  color: white;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
`;

const Icon = styled.span`
  margin-top: 0.3rem;
  margin-right: 0.5rem;
`;

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setIntent':
      return { ...state, intent: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setSearch':
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

const Intent = withGlobal((props) => {
  const {
    g: { api },
    match: {
      params: { id, code },
    },
    history,
  } = props;
  const [search, setSearch] = useState('');

  const [
    {
      redirectLottie: { isStopped, isPaused },
    },
    lottieDispatch,
  ] = useStore();

  const { loading: entities_loading, data: entities_data } = useEntities(
    code,
    true
  );
  const { loading: trainingPhrase_loading, data: trainingPhrase_data } =
    useTrainingPhrases(code, entities_data.entities);
  const [state, dispatch] = useReducer(_reducer, {
    intent: {},
  });

  const { width } = useWindowSize();
  const [tr] = useWords();

  useEffect(() => {
    const _getIntent = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await api.Intents._getById(code);
      if (res) dispatch({ type: 'setIntent', payload: res });
    };
    _getIntent();
  }, [code, api]);

  const isIntentFromMonolith =
    state.intent.models &&
    state.intent.models.some(
      (model) => !model.referenceBotId || model.referenceBotId === '0'
    );

  useEffect(() => {
    if (isIntentFromMonolith) {
      const monolith = state.intent.models.filter(
        (model) => !model.referenceBotId || model.referenceBotId === '0'
      );
      const monolithID = monolith[0].id;
      if (parseInt(id) !== monolithID) {
        history.push(`/office/model/${monolith[0].id}/intents`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setTimeout(() => {
      lottieDispatch({ type: SET_PAUSE });
    }, 3000);
    setTimeout(() => {
      lottieDispatch({ type: CLEAR_VISIBLE });
    }, 3000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <LottiePosition hide={isStopped} width={Math.round(width / 2)}>
        <LottieContainer>
          <Lottie
            options={defaultOptions}
            height={300}
            width={300}
            isStopped={isStopped}
            isPaused={isPaused}
          />
          <LottieText hide={isStopped}>{tr.redirectionSuccessful}</LottieText>
        </LottieContainer>
      </LottiePosition>
      <Head
        {...props}
        state={state}
        setSearch={setSearch}
        dispatch={dispatch}
      />

      {isIntentFromMonolith && (
        <MonolithWarning>
          <Icon>
            <WarningIcon width={20} />
          </Icon>
          {tr.monolithModelWarning}
        </MonolithWarning>
      )}

      <Content>
        <Sentences
          {...props}
          search={search}
          setSearch={setSearch}
          state={state}
          loading={entities_loading || trainingPhrase_loading}
          data={trainingPhrase_data}
          entities_data={entities_data}
        />
        <Entities
          {...props}
          state={state}
          search={search}
          loading={entities_loading}
          data={entities_data}
        />
      </Content>
    </Page>
  );
});

export default Intent;
