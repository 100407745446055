import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import styled from 'styled-components';
import AskMonaStudioLogoWhite from 'assets/ask_mona_studio_logo_white.png';
import Navigation from './officebar/Navigation';
import UserMenu from './officebar/UserMenu';

const Logo = styled.img`
  height: 36px;
  width: auto;
`;

const Expand = styled.div`
  width: stretch;
`;

const OfficeBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <ButtonBase focusRipple>
          <Link to="/office">
            <Logo src={AskMonaStudioLogoWhite} alt="ask_mona_studio_logo" />
          </Link>
        </ButtonBase>
        <Navigation />
        <Expand />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default OfficeBar;
