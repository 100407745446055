import React from 'react';
import styled from 'styled-components';
import { Spinner3 } from 'styled-icons/evil/Spinner3';

const Loading = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  top: 0;
  left: 0;
  color: ${props => props.color ? '#E66E6E' : '#FFF'};
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(200, 200, 200, 0.5);
`;

const Spinner = styled.div`
  width: 42px;
  height: 42px;
`;

export default ({ color }) => {
  return (
    <Loading color >
      <Spinner>
        <Spinner3 className="fa-spin" />
      </Spinner>
    </Loading>
  );
};
