import React from 'react';
import { AppContext } from 'app/App';
import { RouteComponentProps } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';

const Desktop = (props: RouteComponentProps) => {
  const { app } = React.useContext(AppContext);
  const tr = app.wording.translation;

  return (
    <Grow in={true}>
      <Box>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => props.history.push('/signin')}
        >
          {tr.signin}
        </Button>
      </Box>
    </Grow>
  );
};

export default Desktop;
