import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import 'array-flat-polyfill';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import styled_theme from 'styles/styled/theme';
import mui_theme from 'styles/mui/theme';

ReactDOM.render(
  <StyledThemeProvider theme={styled_theme}>
    <ThemeProvider theme={mui_theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StyledThemeProvider>,
  document.getElementById('root')
);

serviceWorker.register();
