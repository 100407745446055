import gql from 'graphql-tag';
import { _query } from 'api/graphql/old/client';

class Public {
  getPublics = async (args) => {
    return await _query(GET_PUBLICS, args);
  };
}

export default Public;

const GET_PUBLICS = gql`
  query getPublics {
    getPublics {
      publics {
        id
        slug
        translations {
          language {
            code
            name
          }
          name
        }
      }
    }
  }
`;
