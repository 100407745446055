import React from 'react';
import Header from 'components/old/Header';
import Title from 'components/old/Title';
import Row from 'components/old/Row';
import { ClickIcon } from 'components/old/Icon';

const Head = (props) => {
  const {
    g: { w },
    history,
  } = props;
  return (
    <Header>
      <Row>
        <ClickIcon
          className="home"
          label="home"
          icon="fas fa-arrow-left"
          onClick={() => history.goBack()}
        />
        <Title>{w.testSpace}</Title>
      </Row>
    </Header>
  );
};

export default Head;
