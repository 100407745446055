import { useState, useEffect } from 'react';
import Type from 'api/graphql/old/Type';
import Theme from 'api/graphql/old/Theme';
import Tag from 'api/graphql/old/Tag';
import Public from 'api/graphql/old/Public';
import Place from 'api/graphql/old/Place';
import Event from 'api/graphql/old/Event';

const useTypeThemeTagPublicPlaceEvent = (botId) => {
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [themes, setThemes] = useState([]);
  const [tags, setTags] = useState([]);
  const [publics, setPublics] = useState([]);
  const [places, setPlaces] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      let types = await getTypes();
      let themes = await getThemes();
      let tags = await getTags();
      let publics = await getPublics();
      let places = await getPlaces();
      let events = await getAllEvents();

      setTypes(types);
      setThemes(themes);
      setTags(tags);
      setPublics(publics);
      setPlaces(places);
      setEvents(events);

      setLoading(false);
    };

    const getTypes = async () => {
      try {
        const type_api = new Type();
        let types = await type_api.getTypes({ bot_id: botId });
        types = types.getTypes.types;
        return types;
      } catch (err) {
        console.warn(err);
        return [];
      }
    };

    const getThemes = async () => {
      try {
        const theme_api = new Theme();
        let themes = await theme_api.getThemes({ bot_id: botId });
        themes = themes.getThemes.themes;
        return themes;
      } catch (err) {
        console.warn(err);
        return [];
      }
    };

    const getTags = async () => {
      try {
        const tag_api = new Tag();
        let tags = await tag_api.getTags({ bot_id: botId });
        tags = tags.getTags.tags;
        return tags;
      } catch (err) {
        console.warn(err);
        return [];
      }
    };

    const getPublics = async () => {
      try {
        const public_api = new Public();
        let publics = await public_api.getPublics();
        publics = publics.getPublics.publics;
        return publics;
      } catch (err) {
        console.warn(err);
        return [];
      }
    };

    const getPlaces = async () => {
      try {
        const place_api = new Place();
        let places = await place_api.getPlaces({ bot_id: botId });
        places = places.getPlaces.places;
        return places;
      } catch (err) {
        console.warn(err);
        return [];
      }
    };

    const getAllEvents = async () => {
      try {
        const event_api = new Event();
        let events = await event_api.getAllEvents({ bot_id: botId });
        let uniqueEvents = [...new Set(events.map((event) => event.id))];

        events = events.filter((event) => {
          if (uniqueEvents.some((id) => id === event.id)) {
            uniqueEvents = uniqueEvents.filter((id) => id !== event.id);
            return true;
          }
          return false;
        });

        events = events.reduce((acc, event) => {
          let title = findTitle(event.translations);
          return acc.concat({ id: event.id, title });
        }, []);

        return events;
      } catch (err) {
        console.warn(err);
        return [];
      }
    };

    const findTitle = (translations) => {
      const lang = localStorage.getItem('locale') || 'fr';
      let title = '';
      for (let i = 0; i < translations.length; i++) {
        let trans = translations[i];
        title = trans['short_title'] || trans['long_title'];
        if (trans['language']['code'] === lang && title) break;
      }
      return title;
    };

    if (botId) {
      console.log(botId);
      getAll();
    }
  }, [botId]);

  return [
    loading,
    {
      types: types,
      themes: themes,
      tags: tags,
      publics: publics,
      places: places,
      events: events,
    },
  ];
};

export { useTypeThemeTagPublicPlaceEvent };
