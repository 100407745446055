import React from 'react';
import { Card, CardTitle, Data, Icon, StyledButton } from '../layout/Layout';

import ErrorIcon from 'icons/ErrorIcon';
import SuccessIcon from 'icons/SuccessIcon';
import WarningIcon from 'icons/WarningIcon';

import RasaResult from '../results/RasaResult';
import LieuxResult from '../results/LieuxResult';
import DateResult from '../results/DateResult';
import BabelResult from '../results/BabelResult';
import MarResult from '../results/MarResult';

const ResultCard = ({ tr, color, title, type, result, openModal }) => {
  const content = (type) => result[`leo-${type}`]['content'];
  const data = (type) => content(type)['data'];

  return (
    <Card color={color}>
      <CardTitle>{title}</CardTitle>
      <Data>
        {result[`leo-${type}`] && result[`leo-${type}`]['content'] && (
          <span>
            {
              // Empty Content
              data(type) && Object.keys(data(type)).length === 0 ? (
                <span>
                  <Icon>
                    <WarningIcon width="17" />
                  </Icon>
                  {tr.emptyContent}
                </span>
              ) : // Simple Data Result
              data(type) && typeof data(type) === 'string' ? (
                <span>
                  <Icon>
                    <SuccessIcon width="17" />
                  </Icon>
                  {tr.testResult} {data(type)}
                </span>
              ) : // RASA Result
              data(type) &&
                Object.keys(data(type)).length > 0 &&
                type === 'rasa' ? (
                <RasaResult data={data} tr={tr} />
              ) : // Lieux Result
              data(type) &&
                Object.keys(data(type)).length > 0 &&
                type === 'lieux' ? (
                <LieuxResult data={data} tr={tr} />
              ) : // Date Result
              data(type) &&
                Object.keys(data(type)).length > 0 &&
                type === 'date' ? (
                <DateResult data={data} tr={tr} />
              ) : // Babel Result
              Object.keys(content(type)).length > 0 && type === 'babel' ? (
                <BabelResult content={content} tr={tr} />
              ) : // MAR Result
              Object.keys(content(type)).length > 0 && type === 'mar' ? (
                <MarResult data={data('mar')} tr={tr} />
              ) : (
                // Coming Soon if result not yet handled
                <span>
                  <Icon>
                    <SuccessIcon width="17" />
                  </Icon>
                  {tr.testResult} {tr.comingSoon}
                </span>
              )
            }
          </span>
        )}
        {result[`leo-${type}`] && result[`leo-${type}`]['error'] && (
          // Error Reception
          <span>
            <Icon>
              <ErrorIcon width="17" />
            </Icon>
            {tr.errorInResult}
            <StyledButton
              onClick={() => openModal(result[`leo-${type}`]['error'].summary)}
            >
              {tr.details}
            </StyledButton>
          </span>
        )}
      </Data>
    </Card>
  );
};

export default ResultCard;
