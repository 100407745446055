import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';

interface ButtonProps {
  readonly stomp?: 'true' | 'false';
}

const StyledButton = styled(Button)<ButtonProps>`
  ${({ stomp }) => css`
    height: 200px;
    width: 150px;
    > .MuiButton-label {
      display: flex;
      flex-direction: column;
    }
  `}
`;

export default StyledButton;
