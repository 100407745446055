import React, { useEffect, useReducer, useState } from 'react';
import Delete from 'components/old/Delete';
import { ClickIcon } from 'components/old/Icon';
import Typo from 'components/old/Typo';
import Modal from './Modal';
import ConfirmationModal from './ConfirmationModal';
import { withGlobal } from 'context//';
import { TRow, TCell, Board } from 'components/old/Table';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'setValues':
      return { ...state, values: action.payload };
    case 'addSynonyms':
      return { ...state, synonyms: { ...state.synonyms, ...action.payload } };
    case 'editSynonyms':
      const { id, array } = action.payload;
      return { ...state, synonyms: { ...state.synonyms, [id]: array } };
    case 'setSynonyms':
      return { ...state, synonyms: action.payload };
    case 'setDeletion':
      return { ...state, deletion: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    default:
      return state;
  }
};

export default withGlobal((props) => {
  const {
    entitie,
    view,
    g,
    state: { entities },
    data,
    handleValidateGrpe,
  } = props;
  const {
    w,
    api: { Topics, Values, Synonyms },
  } = g;
  const [preview, setPreview] = useState(false);
  const [confirmPreview, setConfirmPreview] = useState(false);
  const [edit, setEdit] = useState(false);
  const [state, dispatch] = useReducer(_reducer, {
    values: [],
    synonyms: {},
    total: 0,
    deletion: false,
    loading: false,
  });

  useEffect(() => {
    const _getValues = async () => {
      const res = await Values._get(null, {
        skip: 0,
        take: 5,
        topics: entitie.id,
      });
      if (res && !res.error) {
        dispatch({
          type: 'setValues',
          payload: res.data,
        });
        dispatch({ type: 'setTotal', payload: res.total });
        dispatch({ type: 'setLoading', payload: false });
        res.message && console.warn(res.message);
      }
    };
    _getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitie.id]);

  useEffect(() => {
    const _getAllSynonyms = () => {
      const getSynonym = async () => {
        for (let i = 0; i < 5; i++) {
          const res = await Synonyms._get(null, {
            skip: 0,
            take: 5,
            values: state.values[i] ? state.values[i].id : '',
          });
          if (res && !res.error && state.values[i]) {
            dispatch({
              type: 'addSynonyms',
              payload: { [state.values[i].id]: res.data },
            });
          }
        }
      };
      getSynonym();
    };
    if (state.values.length) _getAllSynonyms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.values]);

  const refresh = async () => {
    const res = await Values._get(null, {
      skip: 0,
      take: 100,
      topics: entitie.id,
    });
    if (res && !res.error) {
      dispatch({
        type: 'setValues',
        payload: res.data,
      });
      dispatch({ type: 'setTotal', payload: res.total });
      dispatch({ type: 'setLoading', payload: false });
      res.message && console.warn(res.message);
    }
  };

  return (
    <TRow>
      <Modal
        view={view}
        isOpen={preview}
        close={() => {
          setEdit(false);
          setPreview(false);
        }}
        {...props}
        edition={edit}
        state={state}
        dispatch={dispatch}
        callback={refresh}
        handleValidateGrpe={handleValidateGrpe}
      />
      <ConfirmationModal
        isOpen={confirmPreview}
        close={() => {
          setConfirmPreview(false);
        }}
        entitie={entitie}
        intent={props.state.intent}
        data={data}
      />
      <TCell>
        <Typo
          fontWeight="600"
          background={entitie.color || ''}
          color={entitie.intents.length === 0 && 'secondary'}
        >
          {entitie.title}
        </Typo>
      </TCell>
      <TCell align="center" whiteSpace="nowrap">
        {state.values.map((el) => el.expression).join(', ')}
      </TCell>
      <TCell>
        <Board>
          <ClickIcon
            label="edit"
            icon="fas fa-eye"
            onClick={() => setPreview(!preview)}
          />
          {!view && (
            <ClickIcon
              label="edit"
              icon="fas fa-pencil-alt"
              onClick={() => {
                setEdit(true);
                setPreview(!preview);
              }}
            />
          )}
          {!view && (
            <ClickIcon
              label="delete"
              color="secondary"
              icon="fas fa-trash-alt"
              onClick={() => dispatch({ type: 'setDeletion', payload: true })}
            />
          )}
          {view && (
            <ClickIcon
              label="delete"
              color="secondary"
              icon="fas fa-trash-alt"
              onClick={() => {
                if (entitie.intents.length <= 1) {
                  setConfirmPreview(true);
                } else {
                  props.removeFromIntent();
                }
              }}
            />
          )}
        </Board>
        <Delete
          label={w.entitieDeletion}
          name={entitie.title}
          isOpen={state.deletion}
          close={() => dispatch({ type: 'setDeletion', payload: false })}
          loading={state.loading}
          onDelete={async () => {
            let res = await Topics._delete(entitie.id);
            dispatch({ type: 'setLoading', payload: false });
            if (res) {
              g.dispatch({
                type: 'setStatus',
                payload: {
                  col: 'success',
                  msg: w.deletionSuccess,
                },
              });
              props.dispatch({
                type: 'setEntities',
                payload: {
                  array: entities.array.filter((o) => o.id !== entitie.id),
                },
              });
              await refresh();
            } else
              g.dispatch({
                type: 'setStatus',
                payload: {
                  col: 'secondary',
                  msg: w.error,
                },
              });
            dispatch({ type: 'setDeletion', payload: false });
            dispatch({ type: 'setLoading', payload: false });
          }}
        />
      </TCell>
    </TRow>
  );
});
