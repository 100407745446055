import React from 'react';
import Grow from '@material-ui/core/Grow';
import { AppContext } from 'app/App';
import { Style } from './';

const Success = () => {
  const { app } = React.useContext(AppContext);
  const [success, setSuccess] = React.useState('');
  const tr = app.wording.translation;

  React.useEffect(() => {
    const success_saved = () => setSuccess(tr.saved);
    const success_created = () => setSuccess(tr.created);
    const success_authenticated = () => setSuccess(tr.authenticated);
    const success_deleted = () => setSuccess(tr.deleted);

    window.addEventListener('success_saved', success_saved);
    window.addEventListener('success_created', success_created);
    window.addEventListener('success_deleted', success_deleted);
    window.addEventListener('success_authenticated', success_authenticated);

    return () => {
      window.removeEventListener('success_saved', success_saved);
      window.removeEventListener('success_created', success_created);
      window.removeEventListener(
        'success_authenticated',
        success_authenticated
      );
      window.removeEventListener('success_deleted', success_deleted);
    };
  });

  React.useEffect(() => {
    let timeId = null;
    if (success) {
      if (timeId) clearTimeout(timeId);
      else
        timeId = setTimeout(() => {
          setSuccess('');
        }, 3000);
    }
  }, [success]);

  if (!success) return null;

  return (
    <Grow in={true}>
      <Style success>{success}</Style>
    </Grow>
  );
};

export default Success;
