import React from 'react';
import ButtonBase from '@material-ui/core/ButtonBase';
import styled, { css } from 'styled-components';
import FlagIcon from 'components/FlagIcon';

const ButtonFlag = styled(ButtonBase)<{ focused: string }>`
  > .flag-icon {
    margin: 2px;
    width: 25px;
    height: 18px;
    position: relative;
  }

  ${({ focused }) => {
    if (focused === 'true') {
      return css`
        border-bottom: 2px solid black !important;
      `;
    }
  }}
`;

const Flags: React.FC<{
  languages: Array<string>;
  focus?: string;
  onChange: (lang: string) => void;
}> = (props) => {
  return (
    <React.Fragment>
      {props.languages.map((lang) => {
        let code = lang.split('_')[0];
        code = code === 'en' ? 'gb' : code;
        return (
          <ButtonFlag
            focused={String(props.focus === lang)}
            key={code}
            onClick={() => props.onChange(lang)}
          >
            <FlagIcon code={code} />
          </ButtonFlag>
        );
      })}
    </React.Fragment>
  );
};

export default Flags;
