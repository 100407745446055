import styled, { css } from 'styled-components';

const Input = styled.input`
  outline: none;
  background-position: center;
  transition: background 0.8s;
  font-family: inherit;
  border-radius: 5px;
  font-size: inherit;
  box-shadow: none;
  border: 1px solid #ccc;
  ${({ margin, small }) => css`
    margin: ${margin};
    padding: ${small ? '0.3rem 0.55rem' : '0.5rem 0.75rem'};
  `}
`;

Input.defaultProps = {
  type: 'text',
  margin: 'none',
};

export default Input;
