import styled, { css } from 'styled-components';
import { _modCol } from 'tools/color';
import media from 'styles/styled/media';

const Button = styled.button`
  cursor: pointer;
  outline: none;
  background-position: center;
  transition: background 0.6s;
  padding: 0.5rem 0.5rem;
  font-family: inherit;
  border-radius: ${({ round }) => (round ? '100%' : '5px')};
  font-size: inherit;

  ${({ color, shadow, expand, outlined, margin, theme }) => css`
    margin: ${margin};
    box-shadow: ${theme['shadows'][shadow]};
    border: 1px solid ${theme['colors'][color]};
    background-color: ${outlined ? 'none' : theme['colors'][color]};
    color: ${outlined ? theme['colors'][color] : 'white'};
    width: ${expand ? '100%' : 'auto'};
    height: auto;
    &:focus {
      background: ${_modCol(theme['colors'][color], 80)}
        radial-gradient(
          circle,
          transparent 1%,
          ${_modCol(theme['colors'][color], 40)} 1%
        )
        center/20000%;
    }
    &:active {
      background-color: ${_modCol(theme['colors'][color], 40)};
      background-size: 100%;
      transition: background 0s;
    }
    &:disabled,
    &[disabled] {
      color: #aaa;
      background-color: #ddd;
      border-color: #ddd;
    }
  `}

  >svg {
    color: white;
  }
`;

Button.defaultProps = {
  margin: 'none',
  onClick: () => console.log('click'),
  color: 'primary',
  shadow: 1,
};

export const NavButton = styled(Button)`
  margin: 3rem;
  width: 150px;
  height: 200px;
  > svg {
    margin: 42px;
  }
  ${media.tablet`
      margin: 1rem;
  `}
  ${media.phone`
    height: auto;
    >svg {
      display: none;
    }
  `}
`;

export const File = styled.label`
  cursor: pointer;
  outline: none;
  background-position: center;
  transition: background 0.6s;
  padding: 0.5rem 0.5rem;
  font-family: inherit;
  border-radius: ${({ round }) => (round ? '100%' : '5px')};
  font-size: inherit;

  ${({ color, shadow, expand, outlined, theme }) => css`
    box-shadow: ${theme['shadows'][shadow]};
    border: 1px solid ${theme['colors'][color]};
    background-color: ${outlined ? 'none' : theme['colors'][color]};
    color: ${outlined ? theme['colors'][color] : 'white'};
    width: ${expand ? '100%' : 'auto'};
    height: auto;
    &:focus {
      background: ${_modCol(theme['colors'][color], 80)}
        radial-gradient(
          circle,
          transparent 1%,
          ${_modCol(theme['colors'][color], 40)} 1%
        )
        center/20000%;
    }
    &:active {
      background-color: ${_modCol(theme['colors'][color], 40)};
      background-size: 100%;
      transition: background 0s;
    }
    &:disabled,
    &[disabled] {
      color: #aaa;
      background-color: #ddd;
      border-color: #ddd;
    }
  `}

  >svg {
    color: white;
  }

  > input {
    display: none;
  }
`;

File.defaultProps = {
  color: 'primary',
  shadow: 1,
};

export default Button;
