import styled, { css } from 'styled-components';
import media from 'styles/styled/media';

const Table = styled.table`
  text-align: left;
  border-collapse: collapse;
  ${({ theme, layout, width }) => css`
    border: 1px solid ${theme['colors']['primary']};
    color: ${theme['colors']['primary']};
    table-layout: ${layout};
    width: ${width};
  `}
  ${media.tablet`
    table-layout: fixed;
  `}
  ${(p) => p.noBorder && 'border: none'}
  margin: ${(p) => p.margin && p.margin};
`;

Table.defaultProps = {
  layout: 'fixed',
  width: '100%',
};

export default Table;

export const TWrap = styled.div`
  clear: both;
  max-height: ${({ fullHeight }) => (fullHeight ? '100vh' : '60vh')};
  width: 100%;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme['colors']['primary']};
  ${(p) => p.noBorder && 'border: none'}
`;

export const TBody = styled.tbody`
  height: ${({ height }) => height};
`;

export const TRow = styled.tr`
  height: ${({ height }) => height};
  > * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${({ theme, background }) => css`
    border-bottom: 1px solid ${theme['colors']['primary']};
    background: ${background ? background : 'none'};
  `}
  ${(p) => p.noBorder && 'border: none'}
`;

export const THEAD = styled.thead``;

export const TFOOT = styled.tfoot``;

export const THead = styled.th`
  position: sticky;
  top: 0;
  height: 100%;
  &::first-letter {
    text-transform: capitalize;
  }
  color: white;
  border: none;
  padding: 10px;
  ${({ align, theme }) => css`
    text-align: ${align ? align : 'left'};
    background: ${theme['colors']['primary']};
  `}
  width: ${(props) => (props.width ? '100%' : 'auto')};
`;

export const TCell = styled.td`
  ${({ align, theme, whiteSpace, clickable, padding }) => css`
    text-align: ${align ? align : 'left'};
    padding: ${padding ? padding : '10px'}
    cursor: ${clickable ? 'pointer' : 'initial'};
    white-space: ${whiteSpace ? whiteSpace : 'initial'};
  `}
  :first-child {
    width: 100%;
  }
`;

export const TCase = styled(TCell)`
  border: 1px solid ${({ theme }) => theme['colors']['primary']};
  padding: 0px;
  vertical-align: baseline;
  > :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme['colors']['primary']};
  }
  > * {
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Board = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: ${({ alignItem }) => alignItem};
`;
