import React, { useState, useEffect } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import Page from 'components/old/Page';
import { withGlobal } from 'context/';
import { withRouter } from 'react-router-dom';
import TrainingHeader from './training/Header';
import TrainingContent from './training/Content';
import Api from 'api/rest';
import { AppContext } from 'app/App';
import { useGlobalMessage } from 'hooks/useGlobalMessage';
import useWords from 'hooks/words';

const Training = withGlobal(
  withRouter((props) => {
    const [datas, setDatas] = useState([]);
    const [load, setLoad] = useState(true);
    const [scrollLoad, setScrollLoad] = useState(false);
    const [err, setErr] = useState(null);
    const [lastItem, setLastItem] = useState('');
    const [search, setSearch] = useState('');
    const [toggle, setToggle] = useState(false);
    const [searchBy, setSearchBy] = useState('sentence');
    const [models, setModels] = useState([]);
    const [toggleSubmit, setToggleSubmit] = useState(false);
    const [toggleSubmitModels, setToggleSubmitModels] = useState(false);
    const [update, setUpdate] = useState(false);
    const {
      g: { api },
    } = props;
    const { app } = React.useContext(AppContext);
    const notification = useGlobalMessage();
    const [tr] = useWords();

    const { height } = useWindowSize();

    useEffect(() => {
      const fetch = async () => {
        const res = await api.Models._get();
        setModels(res.data);
      };
      fetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      fetchData('');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update]);

    useEffect(() => {
      if (toggle && search.length === 0) {
        setDatas([]);
      }
    }, [search, toggle]);

    useEffect(() => {
      const fetch = async () => {
        if (toggle && search.length === 0 && datas.length === 0) {
          await fetchData('');
          setToggle(false);
        }
      };
      fetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datas]);

    useEffect(() => {
      if (search.length !== 0) {
        setToggleSubmit(true);
      }
    }, [search, searchBy]);

    useEffect(() => {
      setSearch('');
    }, [searchBy]);

    useEffect(() => {
      const fetch = async () => {
        if (toggleSubmitModels) {
          await handleSubmitModels();
        }
      };
      fetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toggleSubmitModels]);

    const handleScroll = async (e) => {
      const target = e.target;
      const bottom =
        target.scrollHeight - target.scrollTop <= target.clientHeight + 150;
      if (bottom && datas.length !== 0 && datas.lastItem !== lastItem) {
        setScrollLoad(true);
        if (searchBy === 'sentence') {
          fetchScrollData(lastItem, search);
        } else if (searchBy === 'model') {
          fetchScrollData(lastItem, null, search);
        } else {
          fetchScrollData(lastItem);
        }
      }
    };
    const fetchData = async (lastItem, sentence = null, model = null) => {
      let api = new Api(`${app.mlApiUrl}/reading_logs`, app.token);
      let params = {
        take: 100,
        start: lastItem,
        sentence: sentence,
        model: model,
      };
      try {
        let res = await api.get(null, params);
        if (res.items) {
          let readingLogs = res.items;
          setDatas([...readingLogs]);
          setLastItem(res.lastItem);
        }
      } catch (error) {
        setErr(error);
      } finally {
        setLoad(false);
        setScrollLoad(false);
        setToggleSubmit(false);
        setToggleSubmitModels(false);
      }
    };

    const fetchScrollData = async (lastItem, sentence = null, model = null) => {
      let api = new Api(`${app.mlApiUrl}/reading_logs`, app.token);

      let params = {
        take: 100,
        start: lastItem,
        sentence: sentence,
        model: model,
      };
      try {
        let res = await api.get(null, params);
        if (res.items) {
          let readingLogs = res.items;
          setDatas([...datas, ...readingLogs]);
          setLastItem(res.lastItem);
        }
      } catch (error) {
        setErr(error);
      } finally {
        setLoad(false);
        setScrollLoad(false);
        setToggleSubmit(false);
        setToggleSubmitModels(false);
      }
    };

    const handleSubmit = async () => {
      if (search.length !== 0 && toggleSubmit && searchBy === 'sentence') {
        fetchData('', search);
        setToggle(true);
        setLoad(true);
      }
    };

    const handleSubmitModels = async (value) => {
      if (value !== undefined && search.length !== 0) {
        setSearch(value);
      }
      const findModels = models.some((model) => model.name === search);
      if (search.length !== 0) {
        setToggleSubmit(true);
        setLoad(true);
        setToggle(true);
        fetchData('', null, search);
      } else if (!findModels && value !== undefined) {
        notification.error(tr.errorSearchModel);
      }
    };

    const submitSearchModels = (value) => {
      setSearch(value);
      setToggleSubmitModels(true);
    };
    return (
      <Page padding="10px">
        <TrainingHeader
          setSearch={setSearch}
          value={search}
          handleSubmit={handleSubmit}
          searchLogs={setSearchBy}
          searchBy={searchBy}
          models={models}
          setValueModels={submitSearchModels}
          {...props}
        />
        <TrainingContent
          {...props}
          readingLogs={datas}
          loading={load}
          error={err}
          detectBottom={handleScroll}
          scrollLoad={scrollLoad}
          setUpdate={setUpdate}
          update={update}
          height={height}
        />
      </Page>
    );
  })
);

export default Training;
