import Crud from './';

class Training extends Crud {
  constructor(token) {
    super('/training', token);
  }

  trainMar = async (args) => {
    return await this._fetch(`${this.path}/launch-mar-training`, 'POST', args);
  };

  trainRasa = async (args) => {
    return await this._fetch(`${this.path}/launch-rasa-training`, 'POST', args);
  };
}

export default Training;
