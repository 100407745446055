import Schema from './Schema';
import gql from 'graphql-tag';

class User extends Schema {
  login = async (variables?: object) => await this.mutate(LOGIN, variables);

  getUser = async () => await this.query(GET_USER);
}

const userScheme = `
  id
  email
  firstname
  lastname
  token
  permissions
  bots {
    id
    name
  }
  organization {
    id
  }
`;

const LOGIN = gql`
  mutation login (
    $email: String!
    $password: String!
  ) {
    login (
      email: $email
      password: $password
    ) {
      ${userScheme}
    }
  }
`;

const GET_USER = gql`
  query getUser (
    $token: String
  ) {
    getUser (
      token: $token
    ) {
      ${userScheme}
    }
  }
`;
export default User;
