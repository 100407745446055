import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loading from 'components/old/Loading';
import Button from 'components/old/Button';
import Input from 'components/old/Input';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Subtitle from 'components/old/Subtitle';
import Modal from 'components/old/Modal';

const Small = styled.div`
  font-size: 0.7rem;
  text-align: right;
`;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
  > * {
    display: flex;
    flex: 1;
  }
  > :nth-child(2) {
    margin: 0px 5px;
  }
`;

const Error = styled.div`
  color: tomato;
  margin-bottom: 5px;
`;

const Varia = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Variation = (props) => {
  return (
    <Varia>
      <div>{props.name}</div>
      <IconButton
        disabled={!props.onDelete}
        size="small"
        onClick={props.onDelete}
        color="secondary"
        aria-label="delete"
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Varia>
  );
};

const EditAppellation = (props) => {
  const {
    g: {
      w,
      api: { Variations },
    },
    edit,
    loading,
    isOpen,
    close,
  } = props;

  const [error, setError] = useState('');
  const [nickname, setNickname] = useState('');
  const [expression, setExpression] = useState('');
  const [variations, setVariations] = useState([]);

  useEffect(() => {
    if (edit) {
      setNickname(edit.name);
      setVariations(edit.variations);
    }
  }, [edit]);

  if (!edit) return null;
  return (
    <Modal
      noClickInside={true}
      width={'30%'}
      isOpen={isOpen}
      close={close}
      selector="#root"
    >
      {loading && <Loading />}
      <Subtitle>{w.editAppellation}</Subtitle>
      <Form>
        <Input
          defaultValue={edit.nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
      </Form>
      <Subtitle>{w.addVariation}</Subtitle>
      <Form>
        <Input onChange={(e) => setExpression(e.target.value)} />
        <div>
          <Button
            onClick={async () => {
              if (!expression) setError(w.allFieldAreRequired);
              else {
                const res = await Variations._post({
                  name: expression,
                  appellation: edit.id,
                });
                if (res.id) setVariations(variations.concat(res));
                else if (res.message) setError(res.message);
              }
            }}
          >
            {w.add}
          </Button>
        </div>
      </Form>
      {variations.map((v) => (
        <Variation
          key={v.id}
          name={v.name}
          id={v.id}
          w={w}
          onDelete={async () => {
            const res = await Variations._delete(v.id);
            if (res) setVariations(variations.filter((o) => o.id !== v.id));
          }}
        />
      ))}
      {error && <Error> {error} </Error>}
      <Button
        disabled={loading}
        onClick={() => {
          if (!nickname) {
            setError(w.allFieldAreRequired);
          } else {
            setError('');
            props.onSave({
              nickname,
              category: edit.category,
              referenceId: edit.referenceId,
            });
          }
        }}
      >
        {w.save}
      </Button>
      <Small>Ref id: {edit.referenceId}</Small>
    </Modal>
  );
};

export default EditAppellation;
