const intents = {
  fr_FR: {
    noGroupEntitie: 'Pas de group entitie pour cet intent',
    noValues: 'Pas de values pour cet intent',
    noIntents: 'Aucun intent à afficher pour ce modèle',
    noIntentResult: 'Aucun Résultat',
    redirectToModify:
      'Rediriger vers le monolithe concerné pour modifier cet intent',
    redirectionSuccessful: 'Redirection réussie',
    monolithModelOrIntent: 'Modèle ou Intent Monolithe',
  },
  en_EN: {
    noGroupEntitie: 'No entitie group for this intent',
    noValues: 'No values for this intent',
    noIntents: 'No intents to show for this model',
    noIntentResult: 'No Results',
    redirectToModify:
      'Redirect associated monolith model to modify this intent',
    redirectionSuccessful: 'Redirectinon successful',
    monolithModelOrIntent: 'Monolith Model or Intent',
  },
};

export default intents;
