import React, { useState, useEffect } from 'react';
import useWords from 'hooks/words';
import { ClickIcon } from 'components/old/Icon';
import Header from 'components/old/Header';
import Title from 'components/old/Title';
import Flex from 'components/old/Flex';
import Table, {
  TWrap,
  THEAD,
  TBody,
  TRow,
  THead,
  TCase,
} from 'components/old/Table';

export default (props) => {
  const {
    g: {
      w,
      api: { Values },
    },
    state,
    preview,
    setPreview,
  } = props;

  const [values, setValues] = useState([]);
  const [tr] = useWords();

  const filteredEntities = state.entities.array.filter(
    (m) => m.intents[0] && m.intents[0].id === preview.id
  );

  useEffect(() => {
    const _getValues = async () => {
      return Promise.all(
        filteredEntities.map(async (el) => {
          const res = await Values._get(null, {
            skip: 0,
            take: 10,
            topics: el.id,
          });
          if (res && !res.error) {
            const valueList = await res.data
              .map((el) => el.expression)
              .join(', ');
            await setValues((prevState) => [...prevState, valueList]);
            res.message && console.warn(res.message);
          }
        })
      );
    };
    _getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview]);

  return (
    <>
      <Header>
        <Title underlined>{preview.title}:</Title>
      </Header>
      <TWrap>
        <Table>
          <THEAD>
            <TRow>
              <THead>{w.group}</THead>
              <THead>{w.groupValue}</THead>
            </TRow>
          </THEAD>
          <TBody>
            <TRow>
              <TCase>
                {filteredEntities.length !== 0 ? (
                  filteredEntities.map((el) => {
                    return <div key={el.id}>{el.title}</div>;
                  })
                ) : (
                  <div>{tr.noGroupEntitie}</div>
                )}
              </TCase>
              <TCase>
                {values.length !== 0 ? (
                  values.map((el) => <div key={el}>{el}</div>)
                ) : (
                  <div>{tr.noValues}</div>
                )}
              </TCase>
            </TRow>
          </TBody>
        </Table>
      </TWrap>
      <Flex justifyContent="center" margin="10px">
        <ClickIcon
          label="edit"
          icon="fas fa-arrow-left"
          bordered
          squared
          onClick={() => setPreview('')}
        />
      </Flex>
    </>
  );
};
