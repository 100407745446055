import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import useWords from 'hooks/words';
import Grid from '@material-ui/core/Grid';
import Button from 'components/Button';
import DiagramIcon from 'icons/DiagramIcon';
import ArtificialIntelligenceIcon from 'icons/ArtificialIntelligenceIcon';

const Margin = styled.div`
  margin-top: 1.6rem;
  margin-bottom: 0.6rem;
`;

const Text = styled.div`
  margin-bottom: 2rem;
  text-transform: uppercase;
`;

const Spacing = styled.div`
  width: 1rem;

  @media only screen and (min-width: 992px) {
    width: 10rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 992px) {
    width: 5rem;
  }
  @media only screen and (min-width: 420px) and (max-width: 768px) {
    width: 3rem;
  }
`;

const Home = (props: RouteComponentProps) => {
  const [tr] = useWords();

  const pushToModels = () => {
    props.history.push('/office/models');
  };

  const pushToContinuousTraining = () => {
    props.history.push('/office/training');
  };

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      direction="row"
      justify="center"
    >
      <Grid item>
        <Button
          stomp="true"
          color="secondary"
          variant="contained"
          onClick={pushToContinuousTraining}
        >
          <Margin>
            <ArtificialIntelligenceIcon />
          </Margin>
          <Text>{tr.trainModels}</Text>
        </Button>
      </Grid>
      <Grid item>
        <Spacing />
      </Grid>
      <Grid item>
        <Button
          stomp="true"
          color="primary"
          variant="contained"
          onClick={pushToModels}
        >
          <Margin>
            <DiagramIcon />
          </Margin>
          <Text>{tr.accessModels}</Text>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Home;
