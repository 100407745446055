export const _removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
export const getMessageFromCML = (json) => {
  try {
    const obj = JSON.parse(json);
    return obj.message;
  } catch (err) {
    return '';
  }
};
export const isEmail = (email) => {
  // eslint-disable-next-line
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  return re.test(String(email).toLowerCase());
};
export const groupModelsByName = (data) => {
  let models = [];
  data.forEach((val) => {
    const { name, language, ...other } = val;
    let index = models.findIndex((model) => model.name === name);
    if (index >= 0)
      models[index] = {
        ...models[index],
        [language.code]: { ...other, name, lang: language.code },
      };
    else
      models = models.concat({
        name: name,
        [language.code]: { ...other, name, lang: language.code },
      });
  });
  return models;
};
export const groupModelsBy = (data, key) => {
  return data.reduce((acc, obj) => {
    let k = obj[key];
    if (!acc[k]) acc[k] = [];
    acc[k].push(obj);
    return acc;
  });
};
export const extractStatusMessage = (status) => {
  if (!status) return null;
  try {
    let res = JSON.parse(status);
    return res.message;
  } catch (err) {
    console.warn(err);
    console.warn(status);
    return 'Could not extract message';
  }
};
export const extractStatusDate = (status) => {
  if (!status) return null;
  try {
    let res = JSON.parse(status);
    return res.timestamp;
  } catch (err) {
    console.warn(err);
    console.warn(status);
    return 'Could not extract message';
  }
};

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunkSize {Integer} Size of every group
 */
export const chunkArray = (arr, chunkSize) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

/**
 * Convert iso format to date
 * @param {Int} timestamp iso format: 2020-05-07T15:36:08.000Z
 * @return {Int} Date in new format: 07/05/2020 - 15h36
 */
export const convertTimestampToDate = (time) => {
  const event = new Date(time);
  const years = event.getFullYear();
  const month = `0${event.getMonth() + 1}`;
  const days = `0${event.getDate()}`;
  const hours = `0${event.getHours()}`;
  const minutes = `0${event.getMinutes()}`;
  const formattedTime = `${days.substr(-2)}/${month.substr(
    -2
  )}/${years} - ${hours.substr(-2)}h${minutes.substr(-2)}`;

  return formattedTime;
};
