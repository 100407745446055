import React, { useState, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import useWords from 'hooks/words';
import Modal from 'components/old/Modal';
import Row from 'components/old/Row';
import { Head, OnAdd } from 'components/old/onAddUi';
import Table, {
  TWrap,
  THEAD,
  TBody,
  TRow,
  THead,
  TCell,
} from 'components/old/Table';
import Checkbox from 'components/old/Checkbox';
import Icon, { ClickIcon } from 'components/old/Icon';
import Flex from 'components/old/Flex';
import Pagination from 'components/old/Pagination';

const _reducer = (state, action) => {
  switch (action.type) {
    case 'onSelect':
      return {
        ...state,
        selected: state.selected.some((el) => el.id === action.payload.id)
          ? state.selected.filter((el) => el.id !== action.payload.id)
          : state.selected.concat(action.payload),
      };
    case 'selectAll':
      return {
        ...state,
        selected: action.payload,
      };
    case 'setSkip':
      return {
        ...state,
        skip: action.payload,
      };
    case 'deselectAll':
      return {
        ...state,
        selected: [],
      };
    case 'setAdd':
      return { ...state, add: action.payload };
    case 'setState':
      return { ...state, ...action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default (props) => {
  const {
    isOpen,
    close,
    g: { api, w },
    match: {
      params: { code },
    },
    data: { entities_api },
    entities,
  } = props;
  const [state, dispatch] = useReducer(_reducer, {
    array: [],
    totalArray: [],
    filteredArray: [],
    selected: [],
    skip: 0,
    take: 10,
    total: 0,
    message: '',
    loading: true,
    add: false,
  });

  const [search, setSearch] = useState('');

  useEffect(() => {
    const _getTopics = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await api.Topics._get(null, {
        skip: state.skip,
        take: state.take,
      });
      if (res) {
        dispatch({
          type: 'setState',
          payload: {
            array: res.data || [],
            message: res.message,
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    _getTopics();
  }, [state.skip, state.take, api]);

  return (
    <Modal
      isOpen={isOpen}
      close={!state.add ? close : () => {}}
      selector="#root"
    >
      <OnAdd
        {...props}
        title={w.intentAddTopic}
        items={state.selected}
        isOpen={state.add}
        close={() => dispatch({ type: 'setAdd', payload: false })}
        loading={state.loading}
        onAdd={() => {
          state.selected.forEach(async (el) => {
            try {
              await entities_api.addTopicToIntent(el.id, code);
            } catch (err) {
              console.warn(err);
            }
          });
          dispatch({ type: 'setSelected', payload: [] });
          setTimeout(() => {
            close();
          }, 300);
        }}
      />
      <Head
        title={w.topicsSelect}
        {...props}
        dispatch={dispatch}
        state={state}
        setSearch={setSearch}
      />
      <Row align="flex-start">
        <Topics
          {...props}
          dispatch={dispatch}
          state={state}
          entities={entities}
          search={search}
          setSearch={setSearch}
        />
      </Row>
    </Modal>
  );
};

const EmptyText = styled.div`
  display: flex;
  flex: 1;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
`;

const Topics = (props) => {
  const {
    g: { w, api },
    state: { array, total, skip, take, selected, filteredArray, totalArray },
    dispatch,
    entities,
    search,
    setSearch,
  } = props;

  const [tr] = useWords();

  const [filteredEntities, setFilteredEntities] = useState(
    array.filter((el) =>
      entities.some(
        (entitie) => entitie.id === el.id || el.title === entitie.title
      )
        ? false
        : true
    )
  );

  const getSearchEntities = async (input) => {
    return api.Topics._get(null, {
      title: input,
      skip: skip,
      take: total,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'setSkip',
      payload: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const filterFunction = async () => {
      if (search.length === 0) {
        setFilteredEntities([]);
      }
      if (search.length > 0) {
        const res = await getSearchEntities(search);
        if (res.data) {
          await setFilteredEntities(
            res.data.filter((el) =>
              entities.some(
                (entitie) => entitie.id === el.id || el.title === entitie.title
              )
                ? false
                : true
            )
          );
        }
      }
    };
    filterFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, search]);

  useEffect(() => {
    const _getTopics = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await api.Topics._get(null, {
        skip: skip,
        take: total,
      });
      if (res) {
        await dispatch({
          type: 'setState',
          payload: {
            totalArray:
              res.data.filter((el) =>
                entities.some(
                  (entitie) =>
                    entitie.id === el.id || el.title === entitie.title
                )
                  ? false
                  : true
              ) || [],
            total: res.data.filter((el) =>
              entities.some(
                (entitie) => entitie.id === el.id || el.title === entitie.title
              )
                ? false
                : true
            ).length,
            message: res.message,
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    _getTopics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected) {
      dispatch({
        type: 'setState',
        payload: {
          selected: [],
        },
      });
    }
    const _getFilteredIntents = async () => {
      dispatch({ type: 'setLoading', payload: true });

      const res = await getSearchEntities(search);
      if (res) {
        await dispatch({
          type: 'setState',
          payload: {
            filteredArray:
              res.data.filter((el) =>
                entities.some(
                  (entitie) =>
                    entitie.id === el.id || el.title === entitie.title
                )
                  ? false
                  : true
              ) || [],
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    if (search.length > 0) {
      _getFilteredIntents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    return () => {
      setSearch('');
      dispatch({ type: 'deselectAll' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center">
      {search.length > 0 && filteredEntities.length === 0 ? (
        <EmptyText>{tr.noEntitieResult}</EmptyText>
      ) : array.filter((el) =>
          entities.some(
            (entitie) => entitie.id === el.id || el.title === entitie.title
          )
            ? false
            : true
        ).length === 0 ? (
        <EmptyText>{tr.noImportEntitiesLeft}</EmptyText>
      ) : (
        <TWrap>
          <Table layout="auto">
            <THEAD>
              <TRow>
                <THead>
                  {((search.length === 0 &&
                    array.filter((el) =>
                      entities.some(
                        (entitie) =>
                          entitie.id === el.id || el.title === entitie.title
                      )
                        ? false
                        : true
                    ).length !== 0) ||
                    (search.length > 0 && filteredEntities.length !== 0)) && (
                    <Checkbox
                      outlined
                      checked={
                        search.length > 0
                          ? selected && selected.length === filteredArray.length
                          : selected && selected.length === totalArray.length
                      }
                      onChange={() => {
                        selected && search.length > 0
                          ? selected && selected.length === filteredArray.length
                            ? dispatch({ type: 'deselectAll' })
                            : dispatch({
                                type: 'selectAll',
                                payload: filteredArray,
                              })
                          : selected && selected.length === totalArray.length
                          ? dispatch({ type: 'deselectAll' })
                          : dispatch({
                              type: 'selectAll',
                              payload: totalArray,
                            });
                      }}
                    />
                  )}
                </THead>
                <THead>{w.topicsSelectSeveral}</THead>
                <THead align="-webkit-right">
                  <Icon icon="fas fa-eye" />
                </THead>
              </TRow>
            </THEAD>
            <TBody>
              {filteredEntities.length === 0 && search.length === 0
                ? array
                    .filter((el) =>
                      entities.some(
                        (entitie) =>
                          entitie.id === el.id || el.title === entitie.title
                      )
                        ? false
                        : true
                    )
                    .map((data) => {
                      return <Topic key={data.id} topic={data} {...props} />;
                    })
                : filteredEntities.map((data) => {
                    return <Topic key={data.id} topic={data} {...props} />;
                  })}
            </TBody>
          </Table>
        </TWrap>
      )}

      <Pagination
        activePage={(skip + 10) / take}
        itemsCountPerPage={take}
        totalItemsCount={
          search.length > 0 ? filteredArray.length : totalArray.length
        }
        pageRangeDisplayed={5}
        onChange={(page) => {
          dispatch({ type: 'setSkip', payload: (page - 1) * take });
        }}
      />
    </Flex>
  );
};

const Topic = (props) => {
  const {
    topic,
    state: { selected },
    dispatch,
  } = props;
  return (
    <TRow>
      <TCell>
        <Checkbox
          checked={selected.some((el) => el.id === topic.id)}
          onChange={() => {
            dispatch({ type: 'onSelect', payload: topic });
          }}
        />
      </TCell>
      <TCell>{topic.title}</TCell>
      <TCell align="-webkit-right">
        <ClickIcon icon="fas fa-eye" />
      </TCell>
    </TRow>
  );
};
