import gql from 'graphql-tag';
import { _query } from 'api/graphql/old/client';

class Event {
  getEvents = async (args) => {
    return await _query(GET_EVENTS, args);
  };

  getAllEvents = async (args) => {
    let limit = 100;
    let offset = 0;
    let events = [];

    let hasMoreEvents = true;
    while (hasMoreEvents) {
      let res = null;
      try {
        res = await this.getEvents({ ...args, limit, offset });
        events = events.concat(res.getEvents.events);
      } catch (err) {
        console.warn(err);
        break;
      } finally {
        if (res.getEvents.events.length < limit) hasMoreEvents = false;
        offset += limit;
      }
    }
    return events;
  };
}

export default Event;

const GET_EVENTS = gql`
  query getEvents($limit: Int, $offset: Int, $bot_id: Int) {
    getEvents(limit: $limit, offset: $offset, bot_id: $bot_id) {
      events {
        id
        translations {
          language {
            code
          }
          short_title
          long_title
        }
      }
    }
  }
`;
