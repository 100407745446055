import React from 'react';
import Table, {
  TWrap,
  THEAD,
  TBody,
  TRow,
  THead,
  TCell,
  TCase,
} from 'components/old/Table';

export default (props) => {
  const {
    g: { w },
    entitie,
    state,
  } = props;
  let allSyns = Object.values(state.synonyms).flat();
  return (
    <TWrap>
      <Table>
        <THEAD>
          <TRow>
            <THead>{w.group}</THead>
            <THead>{w.groupValue}</THead>
            <THead>{w.synonyms}</THead>
          </TRow>
        </THEAD>
        <TBody>
          <TRow>
            <TCell>{entitie.title}</TCell>
            <TCase>
              {state.values.map((el) => {
                return <div key={el.id}>{el.expression}</div>;
              })}
              {state.total > state.values.length && <div> ... </div>}
            </TCase>
            <TCase>
              {allSyns.slice(0, 3).map((el) => {
                return <div key={el.id}>{el.expression}</div>;
              })}
              {allSyns.length >= 3 && <div>...</div>}
            </TCase>
          </TRow>
        </TBody>
      </Table>
    </TWrap>
  );
};
