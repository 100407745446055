import React, { useState, useEffect } from 'react';
import useWords from 'hooks/words';
import { ClickIcon } from 'components/old/Icon';
import styled from 'styled-components';
import Loading from 'components/old/Loading';
import { Page } from '../Ui';
import Header from 'components/old/Header';
import Typo from 'components/old/Typo';
import Input from 'components/old/Input';
import FileImport from 'components/FileImport';
import Pagination from 'components/old/Pagination';
import Flex from 'components/old/Flex';
import SelectModal from './SelectModal';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';

import { useStore } from 'store/store';

const SentencesLayout = styled(Page)`
  max-height: 60vh;
  border: 1px solid ${({ theme }) => theme['colors']['primary']};
  > input {
    width: 100%;
  }
  box-shadow: none;
`;

const Content = styled.div`
  display: flex;
  overflow: scroll;
  flex-direction: column;
  width: 100%;
`;

const Sentence = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.7rem;
  color: #222;
  border-bottom: 1px solid #222;
`;

const TrainingSentence = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;

const InputSentence = styled(Input)`
  border: 1px solid ${({ theme }) => theme['colors']['primary']};
  margin-right: 8px;
  width: 100%;
`;

export default (props) => {
  const [rf] = useState(React.createRef());
  const [value, setValue] = useState('');
  const {
    g: {
      w,
      api: { Sentences },
    },
    match: {
      params: { code },
    },
    loading,
    search,
    setSearch,
    data: { trainingPhrases, trainingPhrases_api, pagination, pagination_api },
    entities_data,
  } = props;

  const [
    {
      reload: { selectionReload },
    },
  ] = useStore();

  const [sentences, setSentences] = useState(trainingPhrases);
  const [formatedSentences, setFormatedSentences] = useState([]);

  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [selection, setSelection] = useState('');

  const onFileLoaded = (data) => {
    trainingPhrases_api.fileImport(data);
  };
  const [localTotal, setLocalTotal] = useState(pagination.total);

  const onError = (err) => {
    console.log(err);
  };

  React.useEffect(() => {
    const formatValue = async () => {
      let tr = sentences;
      for (let i = 0, len = tr.length; i < len; i++) {
        let str = tr[i].sentence;
        try {
          let { values } = await Sentences._getById(tr[i].id);

          for (let i = 0, len = values.length; i < len; i++) {
            if (!entities_data.entities) break;

            let target = entities_data.entities.find(
              (el) => el.id === values[i].topic.id
            );
            if (target === undefined) break;
            let reg = (e, array) =>
              new RegExp(`(\\{${array[e].expression}\\})`, 'ig');

            for (let j = 0; j < values.length; j++) {
              if (
                values[i].expression.includes(values[j].expression) &&
                values[j].expression !== values[i].expression
              ) {
                let str1 = str.replace(
                  reg(j, values),
                  `${values[j].expression}`
                );
                str = str1.replace(
                  reg(i, values),
                  `<span style="background-color:${target.color}">${values[i].expression}</span>`
                );
              } else {
                str = str.replace(
                  reg(i, values),
                  `<span style="background-color:${target.color}">${values[i].expression}</span>`
                );
              }
            }
          }
        } catch (err) {
          console.log(err);
        } finally {
          tr[i]['innerHtml'] = str;
        }
      }
      setFormatedSentences(tr);
    };
    formatValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentences, entities_data.entities, selectionReload]);

  const getSearchSentences = async (input) => {
    return Sentences._get(null, {
      sentence: input,
      skip: pagination.skip,
      take: pagination.take,
      intents: [code],
    });
  };

  useEffect(() => {
    pagination_api.skip(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const searchFunction = async () => {
      if (search.length === 0) {
        setLocalTotal(pagination.total);
        setSentences([]);
      } else {
        const res = await getSearchSentences(search);
        await setSentences(res.data);
        setLocalTotal(res.total);
      }
    };
    searchFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pagination.skip]);

  useEffect(() => {
    return () => {
      setSearch('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSelectModal = () => {
    setOpenSelectModal(false);
  };

  const setSelectModal = (bool) => {
    setOpenSelectModal(bool);
  };

  return (
    <>
      <SentencesLayout>
        <SelectModal
          isOpen={openSelectModal}
          close={closeSelectModal}
          entities_data={entities_data}
          selection={selection}
          setSelection={setSelection}
          {...props}
        />
        {loading && <Loading />}
        <Header>
          <Typo fontWeight={600} textTransform="capitalize">
            {w.trainingPhrase}
          </Typo>
          <FileImport
            {...props}
            onError={onError}
            onFileLoaded={onFileLoaded}
            parserOptions={{
              step: async (row) => {
                if (row['data'][0]) {
                  await trainingPhrases_api.createTrainingPhrases(
                    row['data'][0]
                  );
                }
              },
              complete: () => {
                console.log('All done!');
              },
            }}
          />
        </Header>
        <InputSentence
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            pagination_api.skip(0);
          }}
          onKeyPress={async (e) => {
            if (e.key === 'Enter' && value) {
              const res = await trainingPhrases_api.createTrainingPhrases(
                value
              );
              if (res) setValue('');
            }
          }}
          ref={rf}
          placeholder={w.addSentence}
        />
        <Content>
          {formatedSentences.length === 0 && search.length === 0
            ? trainingPhrases.map((data) => {
                return (
                  <TrainingPhrase
                    key={data.id}
                    trainingPhrases_api={trainingPhrases_api}
                    data={data}
                    setSelectModal={setSelectModal}
                    selection={selection}
                    setSelection={setSelection}
                  />
                );
              })
            : formatedSentences.length > 0 &&
              formatedSentences.map((data) => {
                return (
                  <TrainingPhrase
                    key={data.id}
                    trainingPhrases_api={trainingPhrases_api}
                    data={data}
                    setSelectModal={setSelectModal}
                    selection={selection}
                    setSelection={setSelection}
                  />
                );
              })}
          <Pagination
            activePage={pagination.skip / pagination.take + 1}
            itemsCountPerPage={pagination.take}
            totalItemsCount={search.length > 0 ? localTotal : pagination.total}
            pageRangeDisplayed={5}
            onChange={(page) => {
              pagination_api.page(page);
            }}
          />
        </Content>
      </SentencesLayout>
    </>
  );
};

const PopoverContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PopoverText = styled.div`
  text-align: center;
  font-size: 1rem;
`;

const PopoverButtons = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const TrainingPhrase = ({
  data,
  trainingPhrases_api,
  setSelectModal,
  setSelection,
}) => {
  const [edit, setEdit] = React.useState(false);
  const [value, setValue] = React.useState(data.sentence);
  const [anchor, setAnchor] = React.useState(null);

  const [tr] = useWords();

  const handlePopoverOpen = (event) => {
    setAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchor(null);
  };

  const handleSelectionPopover = (e, selected) => {
    setSelection(selected.toString());
    handlePopoverOpen(e);
  };

  const handleSelect = (e) => {
    var selected;

    if (window.getSelection) {
      selected = window.getSelection();
    } else if (document.selection) {
      selected = document.selection.createRange();
    }

    selected.toString() !== '' && handleSelectionPopover(e, selected);
  };

  const open = Boolean(anchor);

  const handleOpenModal = () => {
    setSelectModal(true);
    handlePopoverClose();
  };

  return (
    <Sentence key={data.id}>
      {edit ? (
        <InputSentence
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <>
          <TrainingSentence
            dangerouslySetInnerHTML={{ __html: data.innerHtml }}
            onMouseUp={(e) => handleSelect(e)}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
          />
          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <PopoverContainer>
              <PopoverText>{tr.addingSelectionConfirmation}</PopoverText>
              <PopoverButtons>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handlePopoverClose}
                >
                  {tr.no}
                </Button>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleOpenModal}
                >
                  {tr.yes}
                </Button>
              </PopoverButtons>
            </PopoverContainer>
          </Popover>
        </>
      )}
      <Flex justifyContent="flex-end">
        {edit ? (
          <ClickIcon
            label="edit"
            color="primary"
            icon="fas fa-check"
            onClick={() => {
              const res = trainingPhrases_api.updateTrainingPhrases(
                data.id,
                value
              );
              if (res) setEdit(!edit);
            }}
          />
        ) : (
          <ClickIcon
            label="edit"
            color="primary"
            icon="fas fa-pen-alt"
            onClick={() => setEdit(!edit)}
          />
        )}
        <ClickIcon
          label="delete"
          color="secondary"
          icon="fas fa-trash-alt"
          onClick={async () => {
            await trainingPhrases_api.deleteTrainingPhrases(data.id);
          }}
        />
      </Flex>
    </Sentence>
  );
};
