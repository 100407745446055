import styled, { css } from 'styled-components';
import media from 'styles/styled/media';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  > :not(:last-child) {
    margin-right: 0.3rem;
  }
  ${({ align, justifyContent, background }) => css`
    align-items: ${align};
    justify-content: ${justifyContent};
    background: ${background};
  `}
  ${media.tablet`
    width: 100%;
    >input,
    >button {
      width: 100%;
    }
    >button {
      display: none;
    }
  `}
`;

Row.defaultProps = {
  align: 'center',
  justifyContent: 'initial',
  background: 'none',
};

export default Row;
