import React from 'react';

import {
  Icon,
  List,
  FirstText,
  SecondText,
  Underline,
  Padding,
} from '../layout/Layout';

import SuccessIcon from 'icons/SuccessIcon';

const DateResult = ({ data, type = 'date', tr }) => {
  return (
    <span>
      <Icon>
        <SuccessIcon width="17" />
      </Icon>
      {tr.testResult}
      <List>
        <FirstText>
          {data(type).filter((el) => el.text).length > 1 ? (
            <Underline>{tr.datesDetected}</Underline>
          ) : (
            <Underline>{tr.dateDetected}</Underline>
          )}
          :{' '}
          <Padding padding="0.5rem 0 0.3rem">
            {data(type).some((el) => el.text) ? (
              data(type)
                .filter((el) => el.text)
                .map(
                  (el, i) =>
                    el.text && <SecondText key={i}>- {el.text}</SecondText>
                )
            ) : (
              <span>{tr.resultNotFoundOrNotYetTreatable}</span>
            )}
          </Padding>
        </FirstText>
      </List>
    </span>
  );
};

export default DateResult;
