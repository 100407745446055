const navigation = {
  fr_FR: {
    models: 'Modèles',
    training: 'Entraînement',
  },
  en_EN: {
    models: 'Models',
    training: 'Training',
  },
};
export default navigation;
