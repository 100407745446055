const testpanel = {
  fr_FR: {
    testRasa: 'Rasa',
    testMar: 'Mar',
    testLieux: 'Lieux',
    testDate: 'Date',
    testFeel: 'Feel',
    testOrthographe: 'Orthographe',
    testBabel: 'Babel',
    comingSoon: 'Coming soon',
    emptyContent: 'Ce contenu est vide',
    testResult: 'Résultat : ',
    testMonolith: 'Monolithe',
    testMonolithText:
      'Le modèle actuel est un monolithe. Le panel test est uniquement accessible aux modèles associés à un bot.',
    pleaseChooseAnother:
      'Veuillez choisir un autre modèle pour pouvoir effectuer des tests',
    resultEntities: 'Entities',
    resultNoEntities: 'Aucune Entitie',
    resultIntent: 'Intent',
    resultConfidence: 'Confiance : ',
    resultId: 'ID : ',
    resultName: 'Nom : ',
    resultValue: 'Valeur : ',
    resultIntentRanking: 'Classement des intents',
    resultText: 'Texte',
    noResult: 'Aucun résultat',
    addressDetected: 'Adresse détectée',
    addressesDetected: 'Adresses détectées',
    resultNotYetTreatable: "Ce résultat n'est pas encore pris en charge",
    dateDetected: 'Date détéctée',
    datesDetected: 'Dates détéctées',
    resultNotFoundOrNotYetTreatable:
      "Ce résultat n'a pas été trouvé ou n'est pas encore pris en charge",
    languageDetected: ' Langue détectée',
    precisionScore: ' Score de précision',
    babelError: 'Erreur',
    sentenceTooShort: ' La phrase est trop courte',
    notSupportedLanguage: " La langue détectée n'est pas supportée",
    notEnoughConfidence: " Le taux de confiance n'est pas assez élevé",
    babelFrench: ' Français',
    babelEnglish: ' Anglais',
    similarity: 'Similarité: ',
    id: 'id: ',
    theme: 'Thème: ',
    word: 'Mot: ',
  },
  en_EN: {
    testRasa: 'Rasa',
    testMar: 'Mar',
    testLieux: 'Lieux',
    testDate: 'Date',
    testFeel: 'Feel',
    testOrthographe: 'Spelling',
    testBabel: 'Babel',
    comingSoon: 'Coming soon',
    emptyContent: 'This content is empty',
    testResult: 'Result: ',
    testMonolith: 'Monolith',
    testMonolithText:
      'The actual model is a public model. The testing panel is only accessible to models associated with a determined bot',
    pleaseChooseAnother: 'Please choose another model in order to test phrases',
    resultEntities: 'Entities',
    resultNoEntities: 'No Entitie',
    resultIntent: 'Intent',
    resultConfidence: 'Confidence : ',
    resultId: 'ID : ',
    resultName: 'Name : ',
    resultValue: 'Value : ',
    resultIntentRanking: 'Intent Ranking : ',
    resultText: 'Text : ',
    noResult: 'No result',
    addressDetected: 'Detected address',
    addressesDetected: 'Detected addresses',
    resultNotYetTreatable: 'Result not yet treatable',
    dateDetected: 'Detected date',
    datesDetected: 'Detected dates',
    resultNotFoundOrNotYetTreatable: 'Result not found or not yet treatable',
    languageDetected: ' Language detected ',
    precisionScore: ' Precision score',
    babelError: 'Error',
    sentenceTooShort: ' The sentence is too short',
    notSupportedLanguage: ' The detected language is not supported',
    notEnoughConfidence: ' Confidence level is too low',
    babelFrench: ' French',
    babelEnglish: ' English',
    similarity: 'Similarity: ',
    id: 'id: ',
    theme: 'Theme: ',
    word: 'Word: ',
  },
};

export default testpanel;
