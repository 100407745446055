import React, { useState } from 'react';
import { TRow, TCell, Board } from 'components/old/Table';
import { ClickIcon } from 'components/old/Icon';
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';
import Delete from 'components/old/Delete';

export default (props) => {
  const { g, onOpen, updateIsArchived } = props;
  const {
    w,
    api: { Appellations },
  } = g;
  const { name, nickname, category, id, variations } = props.item;
  const [loading, setLoading] = useState(false);
  const [deletion, setDeletion] = useState(false);

  return (
    <TRow>
      <TCell>{name}</TCell>
      <TCell>{nickname}</TCell>
      <TCell align="center">{category}</TCell>
      <TCell>{variations.map((v) => v.name).join(', ')}</TCell>
      <TCell>
        <Board>
          <ClickIcon material={true}>
            <CreateRoundedIcon
              fontSize="small"
              label="edit"
              onClick={() => {
                props.onEdit({ ...props.item, variations });
                onOpen(true);
              }}
            />
          </ClickIcon>
          <ClickIcon material={true}>
            <ArchiveRoundedIcon
              fontSize="small"
              label="archive"
              color="secondary"
              onClick={() => updateIsArchived(id, true)}
            />
          </ClickIcon>
        </Board>
        <Delete
          label={w.appellationDeletion}
          name={name}
          isOpen={deletion}
          close={() => setDeletion(false)}
          loading={loading}
          onDelete={async () => {
            let res = await Appellations._delete(id);
            setLoading(true);
            if (res) {
              g.dispatch({
                type: 'setStatus',
                payload: {
                  col: 'success',
                  msg: w.deletionSuccess,
                },
              });
              props.onDelete(id);
            } else
              g.dispatch({
                type: 'setStatus',
                payload: {
                  col: 'secondary',
                  msg: w.error,
                },
              });
            setDeletion(false);
            setLoading(false);
          }}
        />
      </TCell>
    </TRow>
  );
};
