import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ClickIcon } from 'components/old/Icon';
import media from 'styles/styled/media';
import { useClickInside } from 'hooks/old/';

const StyledModal = styled.div`
  z-index: 9001;
  background: rgba(0, 0, 0, 0.62);
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ full, small, width }) =>
    width ? width : full ? '100%' : small ? '60%' : '80%'};
  margin: ${(p) => (p.margin ? p.margin : '70px')};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : '5px')};
  background: white;
  padding: ${(p) => (p.padding ? p.padding : '10px')};
  box-shadow: ${({ theme }) => theme['shadows'][3]};
  height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  ${media.tablet`
    margin:${(p) => (p.margin ? p.margin : `${Math.round(p.margin / 7)}px`)};
    width: ${(p) => (p.full ? '100%' : '80%')};
  `}
  ${media.phone`
    margin: ${(p) => (p.margin ? p.margin : `${Math.round(p.margin / 14)}px`)};;
    width: ${(p) => (p.full ? '100%' : '80%')};
  `}
`;

const Modal = ({
  isOpen,
  small,
  close,
  selector,
  children,
  full = false,
  margin,
  padding,
  borderRadius,
  width,
  noClickInside,
  minHeight,
  minWidth,
  closeColor,
}) => {
  const element = document.querySelector(selector);
  const dp = React.createRef();
  const [inside, setInside] = useClickInside(dp);

  useEffect(() => {
    setInside(true);
  });

  useEffect(() => {
    if (!inside && !noClickInside) close();
  }, [inside, close, noClickInside]);

  if (!isOpen || !element) return null;
  return ReactDOM.createPortal(
    <StyledModal className="modal absolute top left flex center items-center">
      <Box
        ref={dp}
        small={small}
        full={full}
        margin={margin}
        padding={padding}
        borderRadius={borderRadius}
        width={width}
        minHeight={minHeight}
        minWidth={minWidth}
      >
        {children}
        <ClickIcon color={closeColor || 'primary'} icon="fas fa-times" onClick={close} inBox />
      </Box>
    </StyledModal>,
    element
  );
};

export default Modal;
