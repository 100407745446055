import React, { useState, useEffect, useReducer } from 'react';
import styled, { keyframes } from 'styled-components';
import ErrorBoundary from 'components/old/ErrorBoundary';
import TextField from '@material-ui/core/TextField';
import { withGlobal } from 'context//';
import { Page } from './Ui';
import Header from 'components/old/Header';
import Title from 'components/old/Title';
import { ClickIcon } from 'components/old/Icon';
import Row from 'components/old/Row';
import Content from './appellations/Content';
import UnarchiveRoundedIcon from '@material-ui/icons/UnarchiveRounded';
import ArchiveModal from './appellations/ArchiveModal';
import { Spacing } from 'components/Layout';
import { chunkArray } from '../../../tools/utils';
import useWords from '../../../hooks/words';
import { useGlobalMessage } from '../../../hooks/useGlobalMessage';

const reducer = (state, action) => {
  switch (action.type) {
    case 'setModel':
      return { ...state, model: action.payload };
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setAppellations':
      return { ...state, appellations: action.payload };
    case 'setTotalArray':
      return { ...state, totalArray: action.payload };
    case 'setAllAppellations':
      return { ...state, allAppelations: action.payload };
    case 'setArchived':
      return { ...state, archived: action.payload };
    case 'setTotalArchived':
      return { ...state, totalArchived: action.payload };
    case 'setSkip':
      return { ...state, skip: action.payload };
    case 'setState':
      return { ...state, ...action.payload };
    case 'setTotal':
      return { ...state, total: action.payload };
    case 'setEdit':
      return { ...state, edit: action.payload };
    case 'setCategory':
      return { ...state, category: action.payload };
    case 'setSearch':
      return { ...state, search: action.payload };
    default:
      return state;
  }
};

const Head = (props) => {
  const {
    g: { w },
    history,
    search,
    dispatch,
    setArchiveModal,
  } = props;

  const [synchronized, setSynchronized] = useState(false);
  const [tr] = useWords();
  const notification = useGlobalMessage();

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch({
      type: 'setSearch',
      payload: e.target.value,
    });
  };

  const synchronize = async () => {
    const res = await props.g.api.Appellations.synchronize(
      props.match.params.id
    );
    if (res) {
      setSynchronized(false);
      notification.success(tr.synchronized);
    }
  };

  return (
    <Header>
      <Row justifyContent="space-between">
        <ClickIcon
          className="home"
          label="home"
          icon="fas fa-arrow-left"
          onClick={() => history.goBack()}
        />
        <Title>{w.appellations}</Title>
        <Margin>
          <TextField
            variant="outlined"
            type="search"
            placeholder={`${w.Search}`}
            value={search}
            onChange={(e) => handleSearch(e)}
          />
        </Margin>
      </Row>
      <Wrapper>
        <SyncWrapper
            onClick={() => {
              setSynchronized(true);
              synchronize();
            }}
        >
          <Spacing right="8px">
            <p>Mise à jour</p>
          </Spacing>
          <SyncProgress
              synchronized={synchronized}
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            width="18"
            height="18"
            viewBox="0 0 22 22"
          >
            <path d="M20.065,17.240 L21.606,18.784 L16.657,19.491 L17.364,14.538 L18.621,15.795 C20.812,12.316 20.393,7.663 17.365,4.632 C15.452,2.716 12.891,1.844 10.388,2.015 L10.185,1.671 L8.741,0.226 C12.252,-0.509 16.053,0.488 18.779,3.217 C22.591,7.032 23.020,12.951 20.065,17.240 ZM3.973,5.379 C1.144,8.916 1.366,14.091 4.641,17.369 C6.838,19.568 9.888,20.392 12.723,19.842 L14.364,21.484 C10.567,22.700 6.240,21.800 3.226,18.784 C-0.830,14.725 -1.056,8.281 2.550,3.956 L1.106,2.509 L6.055,1.802 L5.348,6.755 L3.973,5.379 Z" />
          </svg>
        </SyncProgress>
        </SyncWrapper>
        <Spacing right="11px">
          <ClickIcon material={true}>
            <UnarchiveRoundedIcon
              fontSize="medium"
              label="archives"
              onClick={() => {
                setArchiveModal(true);
              }}
            />
          </ClickIcon>
        </Spacing>
      </Wrapper>
    </Header>
  );
};

const Appellations = withGlobal((props) => {
  const {
    g: {
      api: { Appellations },
    },
    match: {
      params: { id },
    },
  } = props;
  const [create, setCreate] = useState(false);
  const [archive, setArchive] = useState(false);
  const [reload, setReload] = useState(false);
  const [openEditPopUp, setOpenEditPopUp] = useState(false);
  const [searchAppellationsArchived, setSearchAppellationsArchived] =
    useState('');
  const [appellationsArchivedSorted, setAppellationsArchivedSorted] = useState(
    []
  );
  let [paginate, setPaginate] = useState(0);
  const [actionPagination, setActionPagination] = useState('');
  const [takeArchived] = useState(10);

  const [state, dispatch] = useReducer(reducer, {
    model: null,
    edit: null,
    appellations: [],
    allAppellations: [],
    totalArray: [],
    archived: [],
    totalArchived: [],
    loading: true,
    category: '',
    search: '',
    skip: 0,
    take: 25,
    total: 0,
    nonArchivedTotal: 0,
    archivedTotal: 0,
  });

  const [tr] = useWords();
  const notification = useGlobalMessage();

  useEffect(() => {
    setAppellationsArchivedSorted(
      chunkArray(
        state.totalArchived.filter((el) =>
          el.name
            .toLowerCase()
            .includes(searchAppellationsArchived.toLocaleLowerCase())
        ),
        takeArchived
      )
    );
  }, [searchAppellationsArchived]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (appellationsArchivedSorted.length <= 1) setPaginate(0);
  }, [appellationsArchivedSorted]);

  useEffect(() => {
    if (actionPagination === 'decrease') {
      setPaginate(paginate - 1);
      setActionPagination('');
    } else if (actionPagination === 'increase') {
      setPaginate(paginate + 1);
      setActionPagination('');
    }
  }, [actionPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAppellations();
    getAppellationsArchived();
    getTotalArray();
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAppellationsArchived = async () => {
    try {
      dispatch({ type: 'setLoading', payload: true });
      let params = {
        models: id,
        isArchived: 1,
      };
      const res = await Appellations._get(null, params);
      if (res.data) {
        const archived = res.data;
        const newArr = chunkArray(archived, takeArchived);
        setAppellationsArchivedSorted(newArr);
        dispatch({ type: 'setTotalArchived', payload: archived });
        dispatch({
          type: 'setState',
          payload: {
            archivedTotal: archived.length,
          },
        });
      }
    } catch (err) {
      console.warn(err);
    } finally {
      dispatch({ type: 'setLoading', payload: false });
    }
  };

  const getAppellations = async () => {
    try {
      dispatch({ type: 'setLoading', payload: true });
      let params = {
        models: id,
        take: state.take,
        isArchived: false,
        skip: state.skip,
        category: state.category,
      };
      const res = await Appellations._get(null, params);
      if (res.data) {
        dispatch({ type: 'setTotal', payload: res.total });
        const nonArchived = res.data.filter((el) => el.isArchived === false);
        dispatch({ type: 'setAppellations', payload: nonArchived });
        dispatch({ type: 'setAllAppellations', payload: nonArchived });
        dispatch({
          type: 'setState',
          payload: {
            nonArchivedTotal: nonArchived.length,
          },
        });
      }
    } catch (err) {
      console.warn(err);
    } finally {
      dispatch({ type: 'setLoading', payload: false });
    }
  };

  const getTotalArray = async () => {
    try {
      dispatch({ type: 'setLoading', payload: true });
      let params = {
        models: id,
        isArchived: false,
      };
      const res = await Appellations._get(null, params);
      if (res.data) {
        dispatch({ type: 'setTotalArray', payload: res.data });
      }
    } catch (err) {
      console.warn(err);
    } finally {
      dispatch({ type: 'setLoading', payload: false });
    }
  };

  useEffect(() => {
    if (state.search !== '') {
      const sorted = state.totalArray.filter((el) =>
        el.name.toLowerCase().includes(state.search.toLocaleLowerCase())
      );
      dispatch({ type: 'setAppellations', payload: sorted });
    } else {
      dispatch({ type: 'setAppellations', payload: state.allAppelations });
    }
  }, [state.search]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAppellations();
  }, [state.skip]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAppellations();
  }, [state.category]); // eslint-disable-line react-hooks/exhaustive-deps

  const UpdateIsArchived = async (id, bool) => {
    await Appellations._put(id, { isArchived: bool });
    notification.success(tr.saved);
    setReload(!reload);
  };

  return (
    <Page>
      <Head
        {...props}
        search={state.search}
        dispatch={dispatch}
        setModal={setCreate}
        setArchiveModal={setArchive}
      />
      <ErrorBoundary>
        <Content
          {...props}
          search={state.search}
          create={create}
          setModal={setCreate}
          setArchiveModal={setArchive}
          reload={reload}
          setReload={setReload}
          state={state}
          dispatch={dispatch}
          isOpen={openEditPopUp}
          appellations={state.appellations}
          onOpen={() => {
            setOpenEditPopUp(true);
          }}
          close={() => {
            setOpenEditPopUp(false);
          }}
          updateIsArchived={UpdateIsArchived}
        />
      </ErrorBoundary>
      <ArchiveModal
        {...props}
        isOpen={archive}
        close={() => {
          setArchive(false);
        }}
        appellations={appellationsArchivedSorted}
        reload={reload}
        setReload={setReload}
        state={state}
        dispatch={dispatch}
        searchValue={searchAppellationsArchived}
        setSearchValue={setSearchAppellationsArchived}
        handlePaginate={setActionPagination}
        paginate={paginate}
      />
    </Page>
  );
});

const Margin = styled.div`
  margin-left: 0.5rem;
`;

const spin = keyframes`
from {
  transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SyncProgress = styled.div`
  height: 18px;
  animation: ${spin} 2s linear infinite;
  animation: ${({ synchronized }) => !synchronized && 'none'};
  margin-right: 0.4rem;
  svg {
    fill: #2d2f7b;
  }
`;

const SyncWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;


const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default Appellations;
