import React, { useState, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import Modal from 'components/old/Modal';
import Row from 'components/old/Row';
import _reducer from './import/Reducer';
import Intents from './import/Intents';
import Preview from './import/Preview';
import Models from './import/Models';
import { Head, OnAdd } from './import/Ui';

const Container = styled.div`
  min-height: 300px;
`;

export default (props) => {
  const {
    isOpen,
    close,
    g: { api },
    match: {
      params: { id },
    },
    refetch,
  } = props;
  const [preview, setPreview] = useState(false);
  const [search, setSearch] = useState('');
  const [state, dispatch] = useReducer(_reducer, {
    models: {
      array: [],
      totalArray: [],
      filteredArray: [],
      selected: [],
      skip: 0,
      take: 10,
      total: 0,
      message: '',
    },
    intents: {
      array: [],
      totalArray: [],
      selected: [],
      skip: 0,
      take: 10,
      total: 0,
      message: '',
    },
    entities: {
      intents: [],
      totalArray: [],
      array: [],
      skip: 0,
      take: 10,
      total: 0,
      message: '',
    },
    // values: [],
    loading: true,
    add: false,
  });
  useEffect(() => {
    const _getModels = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await api.Models._get(null, {
        skip: state.models.skip,
        take: state.models.take,
      });
      if (res) {
        dispatch({
          type: 'setModels',
          payload: {
            array: res.data || [],
            total: res.total,
            message: res.message,
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    _getModels();
  }, [state.models.skip, state.models.take, api]);

  useEffect(() => {
    const _getIntents = async () => {
      dispatch({ type: 'setLoading', payload: true });
      const res = await api.Intents._get(null, {
        skip: state.intents.skip,
        take: state.intents.take,
        models: state.models.selected,
      });
      if (res) {
        dispatch({
          type: 'setIntents',
          payload: {
            array: res.data || [],
            total: res.total,
            message: res.message,
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    _getIntents();
  }, [state.intents.skip, state.intents.take, api, state.models.selected]);

  useEffect(() => {
    const _getEntities = async () => {
      dispatch({ type: 'setLoading', payload: true });
      let params = {
        skip: state.entities.skip,
        take: state.entities.take,
      };
      if (state.intents && state.intents.length)
        params = {
          ...params,
          intents: state.intents.join(','),
        };
      const res = await api.Topics._get(null, params);
      if (res) {
        dispatch({
          type: 'setEntities',
          payload: {
            array: res.data || [],
            total: res.total,
            message: res.message,
          },
        });
        dispatch({ type: 'setLoading', payload: false });
      }
    };
    if (state.intents) _getEntities();
  }, [state.entities.skip, state.entities.take, api, state.intents]);

  return (
    <Modal
      isOpen={isOpen}
      close={!state.add ? close : () => {}}
      selector="#root"
    >
      {!preview ? (
        <>
          <OnAdd
            {...props}
            items={state.intents.selected}
            isOpen={state.add}
            close={() => dispatch({ type: 'setAdd', payload: false })}
            loading={state.loading}
            onAdd={() => {
              dispatch({ type: 'setLoading', payload: true });
              state.intents.selected.forEach(
                async (el) => await api.Models._addIntent(id, { intent: el.id })
              );
              dispatch({ type: 'setLoading', payload: false });
              dispatch({ type: 'setIntents', payload: { selected: [] } });
              setTimeout(() => {
                refetch();
                close();
              }, 300);
            }}
          />
          <Container>
            <Head
              {...props}
              dispatch={dispatch}
              setSearch={setSearch}
              state={state}
            />
            <Row align="flex-start">
              <Models
                {...props}
                dispatch={dispatch}
                models={state.models}
                items={state.models.array}
              />
              <Intents
                {...state}
                {...props}
                search={search}
                setSearch={setSearch}
                setPreview={setPreview}
                dispatch={dispatch}
                selectedmodels={state.models.selected}
                intents={state.intents}
                items={state.intents.array}
              />
            </Row>
          </Container>
        </>
      ) : (
        <Preview
          {...props}
          state={state}
          preview={preview}
          setPreview={setPreview}
        />
      )}
    </Modal>
  );
};
