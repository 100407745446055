export const languages = Object.freeze({
  fr: 1,
  en: 2,
});

const fr = {
  Search: 'Rechercher',
  a: 'un',
  scan: 'scanner',
  allFieldAreRequired: 'Tous les champs sont obligatoires',
  access: 'accéder aux modèles',
  trainConfirmation: 'Souhaitez-vous lancer un entrainement ?',
  add: 'ajouter',
  addEntities: 'Assigner un entitie',
  addIntent: 'Assigner un intent',
  addSentence: 'saisir une phrase',
  addSynonym: 'Ajouter un synonyme',
  addValue: 'Ajouter une valeur',
  advance: 'Avancé',
  editAppellation: 'Éditer une appellation',
  appellations: 'MAR',
  appellationDeletion: "Supprimer l'appellation:",
  available: 'disponible',
  average: 'Moyen',
  averageIndication: 'Entre deux',
  addVariation: 'Ajouter un synonyme',
  basique: 'Basique',
  by: 'par',
  cancel: 'annuler',
  category: 'catégorie',
  complete: 'Complet',
  confirm: 'valider',
  connect: 'se connecter',
  create: 'créer',
  createAppellation: 'Créer une appellation',
  createEntities: 'Créer une entitie',
  creationSuccess: 'Crée !',
  deletionSuccess: 'Supprimé !',
  detectIntent: 'Intent détecté',
  detectionOf: 'Detection des',
  detectModel: 'Modèle détecté',
  disconnect: 'se déconnecter',
  edit: 'Modifier',
  email: 'adresse email',
  entitieDeletion: "Voulez-vous supprimer l'entitie ?",
  entities: 'Entities',
  entitiesIntents: 'Entities + Intents',
  error: 'Une erreur est survenue',
  errors: {
    data: 'Une erreur est survenue durant le chargement des données',
    doesNotMatch: 'Les identifiants sont incorrects',
    login: "Une erreur est survenue lors de l'authentification",
    requiredField: 'Il manque des champs obligatoires',
  },
  fast: 'Rapide',
  filter: 'Filtrer',
  flexible: 'Plus flexible',
  group: 'groupe',
  groupName: 'Nom du groupe',
  groupPreview: 'aperçu des values',
  groupValue: 'Value',
  import: 'importer',
  in: 'dans',
  intent: 'intent',
  intentAddTopic: 'Voulez-vous ajouter ces entities ?',
  intentCreation: "Création d'un intent",
  intentCreate: 'Créer un intent',
  intentDeletion: "Voulez-vous supprimer l'intent ?",
  intentEntities: "entities de l'intent",
  intentsAndEntities: 'Intents & Entities',
  intentImport: 'Importer un intent',
  intentLang: "Langue de l'intent",
  intentName: "Nom de l'intent",
  intentSelect: "Sélectionner l'intent de votre choix",
  intentSelectSeveral: 'Sélectionner un ou plusieurs intent',
  intents: 'Intents',
  lemmatization: 'Lemmatisation',
  lessFlexible: 'Moins flexible',
  lessPerformance: 'Moins performant',
  linkToBot: 'Associer à un bot',
  low: 'Lent',
  match: 'Intent matché',
  mn: 'minute',
  model: 'modèle',
  modelAccess: 'Accéder aux modèles',
  modelCreation: "Création d'un modèle",
  modelDeletion: 'Voulez-vous supprimer le modèle ?',
  modelFilter: 'filtrer par modèle',
  modelLang: 'Language du modèle',
  modelName: 'Nom du modèle',
  modelPrivacy: 'Rendre public',
  modelTitle: 'Nom du modèle',
  modelsAddIntents: 'Voulez-vous ajouter ces intents ?',
  name: 'Nom',
  nbDuplicata: 'Nombre de duplicata chatitio (entre 0 et 9999)',
  nlp: 'traitement du langage naturel',
  no: 'non',
  normal: 'Normal',
  options: 'options',
  parameters: 'Paramètres',
  password: 'mot de passe',
  perfomance: 'Plus performant',
  private: 'Privé',
  referenceId: 'référence ID',
  save: 'enregistrer',
  saveSuccess: 'Enregistré !',
  search: 'rechercher',
  select: 'Sélectionner',
  selectEntities: 'Assigner des entities en les surlignant',
  selectLang: 'Sélectionner une langue',
  sentence: 'Phrase',
  state: 'statut',
  synonymization: 'Synonymiser',
  synonyms: 'synonymes',
  synonymsCap: 'Synonymes',
  tag: 'Tagger',
  test: 'Tester',
  testResult: 'Résultat',
  testSentence: "Phrase d'entrée",
  testSpace: 'Espace de test',
  toTrain: 'Entrainer !',
  tokenizer: 'Tokeniser',
  topicsSelect: 'Sélectionner un ou plusieurs entities.',
  topicsSelectSeveral: 'Sélectionner un ou plusieurs entities.',
  train: 'entrainement',
  training: 'entrainement des modèles',
  trainingComplete: 'Entraînement terminé',
  trainingEnd: "Fin de l'entraînement",
  trainingDuplicatParams: "Nombre de duplication par phrase d'entrainement",
  trainingMaxLengthParams: "Maximum de phrases d'entrainement par intent",
  trainingModelsParams: "Nombre de cycle d'entrainement",
  leoRasa: 'Leo-RASA',
  leoMar: 'Leo-MAR',
  trainingRasa: 'Entrainement RASA',
  trainingMar: 'Entrainement MAR',
  trainingPhrase: "phrase d'entraînement",
  trust: 'Confiance',
  userMsg: "Message de l'utilisateur",
  validate: 'valider',
  value: 'valeur',
  yes: 'oui',
  your: 'votre',
  nickname: 'Surnom',
  deprioritizeIntent: 'Déprioriser l\'intent',
  reprioritizeIntent: 'Reprioriser l\'intent',
  deprioritizeIntentOrTopics: 'Déprioriser l’intent ou un / plusieurs group entity(ies)',
  deprioritizeTopic: 'Déprioriser un / des group entity(ies)'
};

const en = {
  email: 'adresse email(en)',
  password: 'mot de passe(en)',
  your: 'votre(en)',
  connect: 'se connecter(en)',
  nickname: 'Nickname',
};

export default {
  fr,
  en,
};
