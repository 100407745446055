import styled from 'styled-components';

const Title = styled.span`
  font-size: 1rem;
  font-weight: 600;
  text-transform: ${(p) => (p.textTransform ? p.textTransform : 'uppercase')};
  color: ${({ theme, color }) => theme['colors'][color]};
  border-bottom: ${({ theme, color, underlined }) =>
    underlined ? `1px solid ${theme['colors'][color]}` : 'none'};
`;

Title.defaultProps = {
  color: 'primary',
};

export default Title;
