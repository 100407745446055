import React, { Component } from 'react';
import Typo from 'components/old/Typo';
import { withGlobal } from 'context//';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const {
      g: { w },
    } = this.props;
    if (this.state.errorInfo) {
      return (
        <div className="has-text-black has-content-centered">
          <div className="flex column flex-start">
            <Typo color="secondary"> {w.errors.data} </Typo>
            {process.env.REACT_APP_DEV === 'true' && (
              <details open style={{ background: 'rgba(206, 17, 38, 0.05)' }}>
                <summary style={{ background: 'rgba(206, 17, 38, 0.15)' }}>
                  {this.state.error && this.state.error.toString()}
                </summary>
                <div>{this.state.errorInfo.componentStack}</div>
              </details>
            )}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withGlobal(ErrorBoundary);
