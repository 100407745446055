import gql from 'graphql-tag';
import { _query } from 'api/graphql/old/client';

class Theme {
  getThemes = async (args) => {
    return await _query(GET_THEMES, args);
  };
}

export default Theme;

const GET_THEMES = gql`
  query getThemes($bot_id: Int) {
    getThemes(bot_id: $bot_id) {
      themes {
        id
        slug
        translations {
          language {
            code
            name
          }
          name
        }
      }
    }
  }
`;
