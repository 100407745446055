import React from 'react';
import Grid from '@material-ui/core/Grid';
import Pagination from 'components/Pagination';
import { BodyProps } from '../Body';

const Footer = (props: BodyProps) => {
  const {
    data: { pagination, pagination_api },
  } = props;

  const onChange = (page_number: number) => {
    pagination_api.page(page_number);
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={10}>
        <Pagination
          activePage={pagination.skip / pagination.take + 1}
          itemsCountPerPage={pagination.take}
          totalItemsCount={pagination.total}
          pageRangeDisplayed={5}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default Footer;
