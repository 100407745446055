import React from 'react';
import Client from 'api/graphql/Client';
import { AppContext } from 'app/App';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import Desktop from 'app/desk/Desktop';
import Signin from 'app/desk/Signin';
import AskmonaStudioLogoWhite from 'assets/ask_mona_studio_logo_white.png';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${({ theme }) => theme.spacing}px;

  > .MuiBox-root {
    max-width: 360px;
    width: 100%;
    background: transparent;
    margin: ${({ theme }) => theme.spacing}px 0px;
    > .MuiPaper-root {
      margin: ${({ theme }) => theme.spacing}px 0px;
      > .MuiCardContent-root {
        > .MuiTypography-root:first-letter {
          text-transform: capitalize;
        }
      }
    }
    > .MuiButtonBase-root {
      width: 100%;
    }
  }
`;

const Image = styled.img`
  max-width: 360px;
  width: 100%;
`;

const Desk = () => {
  const { app } = React.useContext(AppContext);
  const client = new Client(app.apiUrl).client;

  if (app.token && window.location.pathname === '/') {
    window.location.href = '/office';
  }

  return (
    <Page>
      <Image src={AskmonaStudioLogoWhite} alt="ask_mona_studio_logo_white" />
      <Switch>
        <Route exact path="/" component={Desktop} />
        <Route
          path="/signin"
          component={(props: RouteComponentProps) => (
            <Signin {...props} client={client} />
          )}
        />
      </Switch>
    </Page>
  );
};

export default Desk;
